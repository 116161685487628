import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { TIME_FORMATS } from 'utils/i18nFormats';
import Style from './PumpEventTooltip.module.scss';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const WHITELISTED_I18N_KEYS = ['cgm_sensor_change'];

export interface PumpEventTooltipProps {
  point: {
    timestamp: string;
    type: string;
    deviceName: string;
  };
}

const PumpEventTooltip = (props: PumpEventTooltipProps) => {
  const { point } = props;
  const { t } = useTranslation('PumpEventTooltip');
  const date = moment.utc(point.timestamp).format(TIME_FORMATS.MMM_DD_YYYY as unknown as string);
  const time = moment.utc(point.timestamp).format(TIME_FORMATS.H_MM__A as unknown as string);

  return (
    <section className={Style.container}>
      <div className={Style.header}>
        <span className={Style.date}>{date}</span>
      </div>

      <div className={Style.row}>
        <strong>{point.deviceName}</strong> {time}<br />
        {t(point.type)}
      </div>
    </section>
  );
};

export default PumpEventTooltip;
