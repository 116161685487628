import classNames from 'classnames';
import { round } from 'lodash';
import ImageStore from 'utils/ImageStore';
import TimeUtils from 'utils/TimeUtils/TimeUtils';
import { numberFormatterThreeDecimals, localizedPercentage } from 'utils/i18nFormats';
import { translate } from 'bundles/shared/components/WithTranslate/WithTranslate';
import { ReactNode } from 'react';
import DateRangeTooltip from '../DateRangeTooltip/DateRangeTooltip';
import SetSiteChangeTooltip from '../SetSiteChangeTooltip/SetSiteChangeTooltip';
import translateMapper from '../../../../../../shared/helpers/alarms/translationMapper';
import Style from '../Tooltip.scss';

export interface BasalTooltipProps extends WithTranslationFunction {
  point: {
    timestamp: string;
    endTimestamp: string;
    series: {
      userOptions: { name: string };
    }
    y?: number;
    duration?: number;
    unused?: boolean;
    percentage?: number;
    rate?: string | number;
    name?: string;
    pumpName?: string;
    location?: string;
    children?: {
      type: string;
      timestamp: string;
    }[],
  };
}

const BasalDataRow = ({ children }: { children: ReactNode }) => (
  <div className={Style.row}>
    <div className={Style.data}>
      {children}
    </div>
  </div>
);

const BasalTooltip = (props: BasalTooltipProps) => {
  const { t, point } = props;
  const seriesName = point.series.userOptions.name;

  if (seriesName === 'setSiteChange' && point.children && point.children.length > 0) {
    return <SetSiteChangeTooltip items={point.children} pumpName={point.pumpName as string} />;
  }

  const titleForScheduledAndModulated = (unused?: boolean) => {
    if ('basalModulation'.localeCompare(seriesName) === 0 && t('modulatedBasal')) return t('modulatedBasal');

    return unused ? t('unusedBasal') : t('basal');
  };

  return (
    <div className={classNames(Style.Tooltip, Style.BasalTooltip)}>
      <DateRangeTooltip endTimestamp={point.endTimestamp} startTimestamp={point.timestamp} />
      <div className={classNames({ [Style.lineDate]: seriesName === 'temporaryBasal12' })} />

      {seriesName === 'temporaryBasal12' && point.percentage !== null ? (
        <BasalDataRow>
          <img alt="icon" className={Style.asset} src={ImageStore.InsulinTempBasal} />
          <span> {t('tempBasal')}</span>
          <span>{`${localizedPercentage(round(point.percentage as number * 100))}`}</span>
        </BasalDataRow>
      ) : null}

      {seriesName === 'temporaryBasal12' && point.rate !== null ? (
        <BasalDataRow>
          <span>{t('tempBasalRate')}</span>
          <span>{`${numberFormatterThreeDecimals(point.rate)} ${t('unitsPerHour')}`}</span>
        </BasalDataRow>
      ) : null}

      {(['scheduledBasal12', 'unusedScheduledBasal12', 'basalModulation'].includes(seriesName) && point.y !== null) ? (
        <BasalDataRow>
          <span>{titleForScheduledAndModulated(point.unused)}</span>
          <span>{`${numberFormatterThreeDecimals(point.rate)} ${t('unit')}`}</span>
        </BasalDataRow>
      ) : null}

      {seriesName === 'suspendBasal' ? (
        <BasalDataRow>
          <span>{t('pumpSuspended')}</span>
        </BasalDataRow>
      ) : null}

      {seriesName === 'reservoirChange' ? (
        <BasalDataRow>
          <span>{t('reservoirChange')}</span>
        </BasalDataRow>
      ) : null}

      {seriesName === 'setSiteChange' ? (
        <BasalDataRow>
          <span>{t('setSiteChange')}</span>
          {point.location !== null ? <span>{`${point.location}`}</span> : null}
        </BasalDataRow>
      ) : null}

      {seriesName === 'pumpAlarm' ? (
        <BasalDataRow>
          <span>{translateMapper(point.name).shortText || point.name}</span>
        </BasalDataRow>
      ) : null}

      {seriesName === 'pumpAdvisoryAlert' ? (
        <BasalDataRow>
          <span>{point.name}</span>
        </BasalDataRow>
      ) : null}

      {seriesName === 'lgsPlgs' ? (
        <BasalDataRow>
          <div>{t('lgsOrPlgs')}</div>

          <div>
            {`${TimeUtils.formatDuration(TimeUtils.diffTimestamps(point.timestamp, point.endTimestamp, (point.duration as number) < 60), false, false)}`}
          </div>
        </BasalDataRow>
      ) : null}
    </div>
  );
};

export default translate('BasalTooltip')(BasalTooltip);
