import { useRef, useEffect, useState } from 'react';

const IDLE_TIMER_EVENTS: string[] = [
  'load',
  'mousemove',
  'mousedown',
  'click',
  'scroll',
  'keypress',
  'touchstart',
  'touchmove',
  'wheel',
];
export const useIdleTimer = (secondsToTimeout: number) => {
  const [isIdle, setIsIdle] = useState<boolean>(false);
  const timeWhenIdle = useRef<Date>(getIdleTime());

  function getIdleTime(): Date {
    const time: Date = new Date();
    time.setSeconds(time.getSeconds() + secondsToTimeout);
    return time;
  }

  function resetIdle(): void {
    timeWhenIdle.current = getIdleTime();
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (timeWhenIdle.current < new Date() && !isIdle) {
        setIsIdle(true);
      }
    }, 1000);

    IDLE_TIMER_EVENTS.forEach((idleEvent) => {
      window.addEventListener(idleEvent, resetIdle);
    });

    return () => {
      clearInterval(interval);
      IDLE_TIMER_EVENTS.forEach((idleEvent) => {
        window.removeEventListener(idleEvent, resetIdle);
      });
    };
  }, [secondsToTimeout]);

  return {
    isIdle,
  };
};
