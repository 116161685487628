import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogHeader } from '@glooko/common-ui';
import React, { useReducer, useState } from 'react';
import { savePatientDeduplicationThunk } from 'redux/thunks/users';
import { validateMRNsThunk } from '~/redux/thunks/providerGroupSite/providerGroupSite';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import { trackEditDuplicated, trackRemoveDuplicated } from 'services/eventLogging';
import { duplicatePatientsReducer, createInitialState, REDUCER_ACTIONS, PATIENT_ACTIONS,
  selectDeduplicationPayload } from './DuplicatePatientReducer';
import DuplicatePatientItem, { Mode } from './DuplicatePatientItem';
import Styles from './DuplicatePatientFixDialog.scss';
import { useDispatch } from './hooks';

const PAGES = {
  EDIT: 'edit',
  REVIEW: 'review',
};

const DuplicatePatientFixDialog = ({ t, isOpen, duplicatePatients, fetchTableData, handleClose }) => {
  const [state, dispatch] = useReducer(duplicatePatientsReducer, createInitialState(duplicatePatients));
  const [page, setPage] = useState(PAGES.EDIT);
  const [hasAcceptedTerms, setTerms] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [validating, setValidating] = useState(false);
  const { dispatch: reduxDispatch } = useDispatch();

  const onEditAction = (patientId, action) => {
    dispatch({
      type: REDUCER_ACTIONS.EDIT_ACTION,
      patientId,
      action,
    });
  };

  const onUpdatePatientFieldsAction = (patientId, fields) => {
    dispatch({
      type: REDUCER_ACTIONS.EDIT_PATIENT_FIELDS,
      patientId,
      fields,
    });
  };

  const onUpdateEditingAction = (patientId, editing) => {
    dispatch({
      type: REDUCER_ACTIONS.UPDATE_EDITING_STATUS,
      patientId,
      editing,
    });
  };

  const onUpdateAllStatusesAction = (patientsWithNewStatuses) => {
    dispatch({
      type: REDUCER_ACTIONS.UPDATE_ALL_ERROR_STATUS,
      users: patientsWithNewStatuses,
    });
  };

  const patientsWithDeltas = () => (
    Object.values(state.editedPatients).filter(
      ({ action, patient }) => (
        JSON.stringify(state.duplicatePatients[patient.id]) !== JSON.stringify(patient) ||
        action !== PATIENT_ACTIONS.KEEP
      ),
    )
  );

  const patientsWithErrors = () => (
    Object.values(state.editedPatients).filter(({ error }) => error.length > 1)
  );

  const onUndoChangeAction = (patientId, editing) => {
    dispatch({
      type: REDUCER_ACTIONS.UNDO_CHANGE_ACTION,
      patientId,
      editing,
      duplicatePatients,
    });
  };

  const onPatientsValidated = () => {
    onUpdateAllStatusesAction([]);
    setValidating(false);
    setPage(PAGES.REVIEW);
  };

  const onPatientsInvalidated = (patients) => {
    onUpdateAllStatusesAction(patients);
    setValidating(false);
  };

  const onReviewChanges = () => {
    // NOTE: If planning to remove the patient, we send an empty MRN to the validate so that it will not generate a mrn conflict
    const patients = Object.values(patientsWithDeltas())
      .map(({ action, patient }) => ({ userId: patient.id, mrn: action === PATIENT_ACTIONS.KEEP ? patient.mrn : '' }));

    setValidating(true);

    reduxDispatch(validateMRNsThunk(patients, onPatientsValidated, onPatientsInvalidated));
  };

  const renderHeader = () => (
    <span className={Styles.headerText}>{renderTitle()}</span>
  );

  const renderTitle = () => ((page === PAGES.EDIT) ? t('whichDuplicatePatients') : t('reviewChanges'));
  const renderSubHeader = () => (page === PAGES.REVIEW) && (
    <div className={Styles.reviewSubHeader}>
      <div className={Styles.subHeader}>{t('acknowledgement')}</div>
      <div className={Styles.reviewTerms}>
        <Checkbox
          checked={hasAcceptedTerms}
          onChange={() => { setTerms(!hasAcceptedTerms); }}
          dataAttributes={{ testid: 'accept-terms' }}
          label={t('agreement')}
        />
      </div>
      <div className={Styles.subHeader}>{t('summaryOfChanges')}</div>
    </div>
  );

  const renderErrorBanner = () => (
    (patientsWithErrors().length > 0) &&
      <div className={Styles.errorBanner} data-testid="duplicate-patient-content-error">{t('fixErrors')}</div>
  );

  const submitCallback = () => {
    setSubmitting(false);
    fetchTableData();
    handleClose();
  };
  const submitChanges = () => {
    const payload = selectDeduplicationPayload(state);
    trackDuplicateFixEvents();
    setSubmitting(true);
    reduxDispatch(savePatientDeduplicationThunk(payload, submitCallback));
  };

  const deltaFields = (patient) => {
    const originalPatient = state.duplicatePatients[patient.id];
    const fields = [];
    const trackedFields = ['firstName', 'lastName', 'dateOfBirth', 'mrn'];
    trackedFields.forEach((field) => {
      if (patient[field] !== originalPatient[field]) {
        fields.push(field);
      }
    });
    return fields;
  };

  const trackDuplicateFixEvents = () => {
    const users = patientsWithDeltas();
    const removeUserCount = users.filter((user) => user.action === 'REMOVE').length;
    const editedUsers = users.filter((user) => user.action === 'KEEP');
    if (removeUserCount) {
      trackRemoveDuplicated(removeUserCount);
    }
    if (editedUsers.length > 0) {
      const fieldsEdited = editedUsers.reduce((fields, user) => [...new Set([...fields, ...deltaFields(user.patient)])], []);
      trackEditDuplicated(fieldsEdited, editedUsers.length);
    }
  };

  const patientItemMode = (page === PAGES.EDIT) ? Mode.EDIT : Mode.REVIEW;

  const renderContent = () => (
    <>
      {renderSubHeader()}
      {renderErrorBanner()}
      <div className={Styles.patientList}>
      { Object.values(state.editedPatients).map(({ action, patient, error }) => {
        const originalPatient = state.duplicatePatients[patient.id];
        return (
          <DuplicatePatientItem
            key={`${patient.id}`}
            patient={patient}
            originalPatient={originalPatient}
            isEditing={state.editedPatients[patient.id].editing}
            error={error}
            mode={patientItemMode}
            action={action}
            updateAction={onEditAction}
            updatePatientFieldsAction={onUpdatePatientFieldsAction}
            updateEditingAction={onUpdateEditingAction}
          />);
        })}
      </div>
    </>
  );

  const disableUndoChanges =
    Object.keys(state.editedPatients).some((patientId) => state.editedPatients[patientId].editing) ||
    patientsWithDeltas().length === 0 ||
    validating;

  const disableReviewChanges =
      Object.keys(state.editedPatients).some((patientId) => state.editedPatients[patientId].editing) ||
      patientsWithDeltas().length === 0 ||
      patientsWithErrors().length > 0 ||
      validating;

  const renderButtons = () => (
    <>
      {page === PAGES.EDIT && (
        <>
          <Button
            className={Styles.undoButton}
            variation='link'
            onClick={onUndoChangeAction}
            disabled={disableUndoChanges}
            dataAttributes={{ testid: 'undo-all' }}
          >{t('undoAllChanges')}
          </Button>
          <Button
            variation='secondary'
            onClick={handleClose}
            dataAttributes={{ testid: 'duplicates-cancel-edit' }}
          >{t('cancel')}
          </Button>
          <Button
            disabled={disableReviewChanges}
            isLoading={validating}
            onClick={onReviewChanges}
            dataAttributes={{ testid: 'duplicates-review' }}
          >
            {t('reviewChanges')}
          </Button>
        </>
      )}
      {page === PAGES.REVIEW && (
        <>
          <Button
            className={Styles.backButton}
            onClick={() => {
              setTerms(false);
              setPage(PAGES.EDIT);
            }}
            variation='link'
            dataAttributes={{ testid: 'duplicates-back' }}
          >{t('back')}
          </Button>
          <Button
            variation='secondary'
            onClick={handleClose}
            dataAttributes={{ testid: 'duplicates-cancel-review' }}
          >{t('cancel')}
          </Button>
          <Button
            dataAttributes={{ testid: 'duplicates-save' }}
            disabled={!hasAcceptedTerms}
            onClick={submitChanges}
            isLoading={submitting}
          >{t('save')}
          </Button>
        </>
      )}
    </>
  );

  if (isOpen) {
    return (
      <Dialog
        className={Styles.DuplicatePatientFixDialog}
        open={isOpen}
        size='large'
        onClose={handleClose}
      >
        <DialogHeader className={Styles.header}>{renderHeader()}</DialogHeader>
        <DialogContent>{renderContent()}</DialogContent>
        <DialogActions>{renderButtons()}</DialogActions>
      </Dialog>
    );
  }
};

export default translate('DuplicatePatientFix')(DuplicatePatientFixDialog);
