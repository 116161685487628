import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import MainStore from 'redux/store/MainStore';
import PartnerIntegrationsCard from '../components/PartnerIntegrationsCard';

const settingsPartnerIntegrations = document.getElementById('partner-integrations');

const queryClient = new QueryClient();

if (settingsPartnerIntegrations) {
  const root = createRoot(settingsPartnerIntegrations);

  root.render(
    <QueryClientProvider client={queryClient}>
      <Provider store={MainStore}>
        <PartnerIntegrationsCard />
      </Provider>

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>,
  );
}
