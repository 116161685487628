import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { timeframeToSingleDayChunks } from '~/utils/syncTimestamps';
import {
  groupedSeries,
} from '~/bundles/graphs/components/OverviewTabContainer/OverviewGraphHours/OverviewGraphHoursContainer/HoursGraphHelpers';
import { multipleDaysMergedConfig } from '~/bundles/graphs/components/OverviewTabContainer/OverviewGraphHours/OverviewGraphHoursContainer/hoursLegendConfig';
import UserHelper from '~/redux/modules/users/UserHelper';
import PageBreak from './../../PageBreak/PageBreak';
import WeekViewPdfSingleDay from '../WeekViewPdfSingleDay/WeekViewPdfSingleDay';
import Style from './WeekViewPdfContainer.scss';
import OverviewHoursLegend from '~/bundles/shared/components/graphs/GraphsLegend/OverviewHoursLegend';
import {
  hasControlIqData, hasBasalIqData, hasOp5Data, hasMedtronicData, hasCamapsData,
} from '../../../../../services/hasDeviceData';
import { WEEK_VIEW_PDF_MEDIUM, GRAPH_CONFIG } from '~/bundles/shared/components/graphs/hoursGraphConfig';

export const WEEK_VIEW_PDF_GRAPH_NAME = 'WeekViewGraphContainer';
export const WEEK_VIEW_PDF_GRAPH_WIDTH = 1023;
export const HEIGHT_OVERRIDE_FACTOR = 0.35;

const DEFAULT_POSITIONING = {
  maxGraphsPerPage: 3,
  samePageLegendMaxGraphs: 2,
};

export const pageCountCalculator = (numberOfDays, graphConfig) => {
  const {
    maxGraphsPerPage, samePageLegendMaxGraphs,
  } = GRAPH_CONFIG[graphConfig]?.legendPositioning || DEFAULT_POSITIONING;

  let pagesPerWeek = Math.floor(7 / maxGraphsPerPage);
  const pagesPerWeekDaysRemaining = 7 - pagesPerWeek * maxGraphsPerPage;

  pagesPerWeek += pagesPerWeekDaysRemaining > samePageLegendMaxGraphs ? 2 : 1;

  const fullWeeks = Math.floor(numberOfDays / 7);
  let numberOfPages = fullWeeks * pagesPerWeek;
  let remainingDays = numberOfDays - (fullWeeks * 7);

  if (remainingDays === 0) return numberOfPages;

  const remainingDaysFullPages = Math.floor(remainingDays / maxGraphsPerPage);
  numberOfPages += remainingDaysFullPages;
  remainingDays -= (remainingDaysFullPages * maxGraphsPerPage);

  return numberOfPages + (remainingDays > samePageLegendMaxGraphs ? 2 : 1);
};

const mapStateToProps = (state) => {
  const startDate = state.page.startDate;
  const endDate = state.page.endDate;

  const displayUser = UserHelper.displayUser(state);
  const meterUnits = UserHelper.displayUser(state).preference.meterUnits;

  let graphs = [];
  Object.keys(state.graphs).forEach((key) => {
    if (!key.startsWith(WEEK_VIEW_PDF_GRAPH_NAME)) return;
    graphs.push(groupedSeries(state.graphs[key], meterUnits));
  });
  graphs = graphs.reverse();

  return {
    statistics: state.statistics.overall,
    displayUser,
    graphs,
    startDate,
    endDate,
    meterUnits: displayUser.preference.meterUnits,
    hasInsulinData: UserHelper.hasPenDevice(state) || UserHelper.hasManualData(state),
    hasPump: UserHelper.hasPump(state),
    graphConfig: state.graphs.weekViewGraphSize,
  };
};

class WeekViewPdfContainer extends React.Component {
  legend = (index, graphs, systemData) => {
    const legendData = [graphs[index]];

    if (index > 0) {
      let prevIndex = index - 1;

      while (((prevIndex + 1) % 7) !== 0) {
        legendData.push(graphs[prevIndex]);
        prevIndex -= 1;
      }
    }

    const mergedConfig = multipleDaysMergedConfig(legendData);

    return <OverviewHoursLegend
      key={`WeekView_legend_${index}`}
      isWeekView
      isConfigured
      legendConfig={mergedConfig}
      {...systemData}
    />;
  }

  setPageBreakAndLegend = (
    index, graphsCount, systemData, graphs, legendPositioningConfig,
  ) => {
    const {
      maxGraphsPerPage, samePageLegendMaxGraphs,
    } = legendPositioningConfig;
    const graphNumber = index + 1;
    const lastGraph = graphNumber === graphsCount;
    const result = [];
    let graphNumberInGroup;

    if (graphNumber > 7) {
      const fullGroupsGraphsCount = 7 * Math.floor(graphNumber / 7);
      graphNumberInGroup = graphNumber - fullGroupsGraphsCount || 7;
    } else {
      graphNumberInGroup = graphNumber;
    }

    // If page is filled up
    if (graphNumberInGroup % maxGraphsPerPage === 0) {
      // Add a break
      result.push(<PageBreak key={`PageBreak_${index}_full_page`} />);
    }

    // If week group is completed or the last graph was added
    if (graphNumberInGroup === 7 || lastGraph) {
      let graphNumberInPage;

      if (graphNumberInGroup > maxGraphsPerPage) {
        const fullPageGraphsCount =
          maxGraphsPerPage * Math.floor(graphNumberInGroup / maxGraphsPerPage);
        graphNumberInPage = graphNumberInGroup - fullPageGraphsCount || maxGraphsPerPage;
      } else {
        graphNumberInPage = graphNumberInGroup;
      }

      // Add a page break if the legend doesn't fit on the same page
      if (graphNumberInPage > samePageLegendMaxGraphs) {
        result.push(<PageBreak key={`PageBreak_${index}_legend`} />);
      }
      // Add a legend
      result.push(this.legend(index, graphs, systemData));
      // Add a page break if not the last graph
      if (!lastGraph) result.push(<PageBreak key={`PageBreak_${index}_group`} />);
    }

    return result;
  };

  render = () => {
    const {
      statistics, hasInsulinData, hasPump, displayUser,
      startDate, endDate, graphs, meterUnits, graphConfig,
    } = this.props;
    const days = timeframeToSingleDayChunks(startDate, endDate);
    const graphsCount = days.length;
    let systemData = {};
    const legendPositioningConfig =
      GRAPH_CONFIG[graphConfig]?.legendPositioning || DEFAULT_POSITIONING;

    return (
      <div class={Style.WeekViewPdfContainer}>
        {days.reverse().map((timeframe, index) => {
          const graphKey =
            `${WEEK_VIEW_PDF_GRAPH_NAME}_${timeframe.startTimestamp}_${timeframe.endTimestamp}`;

          const stats = statistics[graphKey];

          const controlIqData = hasControlIqData(displayUser, stats);
          const basalIqData = hasBasalIqData(displayUser, stats);
          const omnipod5Data = hasOp5Data(displayUser, stats);
          const medtronicClosedLoopData = hasMedtronicData(displayUser, stats);
          const camapsData = hasCamapsData(stats);

          if (controlIqData) systemData['controlIqData'] = true;
          if (basalIqData) systemData['basalIqData'] = true;
          if (omnipod5Data) systemData['omnipod5Data'] = true;
          if (medtronicClosedLoopData) systemData['medtronicClosedLoopData'] = true;
          if (camapsData) systemData['camapsData'] = true;

          const result = [
            <WeekViewPdfSingleDay
              key={graphKey}
              statistics={stats}
              startTimestamp={timeframe.startTimestamp}
              endTimestamp={timeframe.endTimestamp}
              hasInsulinData={hasInsulinData}
              hasPump={hasPump}
              hasControlIQ={controlIqData}
              hasBasalIqData={basalIqData}
              hasCamapsData={camapsData}
              hasOmnipod5={omnipod5Data}
              hasMedtronicClosedLoopData={medtronicClosedLoopData}
              meterUnits={meterUnits}
              graphConfig={graphConfig}
            />,
            this.setPageBreakAndLegend(
              index, graphsCount, systemData, graphs, legendPositioningConfig,
            ),
          ];

          if (result[1]) {
            result[1].forEach((e) => {
              if (e && e.key.startsWith('WeekView_legend_')) {
                systemData = {};
              }
            });
          }

          return result;
        })}
      </div>
    );
  }
}

WeekViewPdfContainer.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  statistics: PropTypes.object.isRequired,
  displayUser: PropTypes.object.isRequired,
  graphs: PropTypes.array.isRequired,
  hasInsulinData: PropTypes.bool,
  hasPump: PropTypes.bool,
  meterUnits: PropTypes.string,
  graphConfig: PropTypes.string,
};

WeekViewPdfContainer.defaultProps = {
  startDate: moment().toISOString(),
  endDate: moment().toISOString(),
  hasInsulinData: false,
  hasPump: false,
  graphs: [],
  meterUnits: 'mgdl',
  statistics: {},
  displayUser: {},
  graphConfig: WEEK_VIEW_PDF_MEDIUM,
};

export default connect(mapStateToProps, null)(WeekViewPdfContainer);
