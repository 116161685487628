import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import UserHelper from '../../../../../redux/modules/users/UserHelper';
import { trackSelectedAppsDevices } from '../../../../../services/eventLogging';

import image from './iglucose_logo.png';
import Style from '../PartnerIntegrationsCard.scss';

const mapStateToProps = (state: State) => ({
  isPatient: UserHelper.currentUserIsPatient(state),
  userId: UserHelper.displayUser(state).id,
  isInUSA: UserHelper.displayUser(state).countryOfResidence === 'usa',
  isConnected: UserHelper.displayUser(state).iglucoseConnected,
});

const IglucoseButton = (props: ReturnType<typeof mapStateToProps>) => {
  const { isPatient, userId, isInUSA, isConnected } = props;
  const { t } = useTranslation('IglucoseButton');

  if (!isInUSA) {
    return null;
  }

  const onClick = () => {
    trackSelectedAppsDevices('iGlucose Devices');

    const url = new URL(isConnected ? '/iglucose/manage_devices' : '/iglucose/connect', window.location.origin);

    if (!isPatient) {
      url.searchParams.append('patient', String(userId));
    }

    window.location.href = url.toString();
  };

  return (
    <button
      className={Style.button}
      onClick={onClick}
      type="button"
    >
      <div className={Style.buttonContent}>
        <img alt="integration" className={Style.buttonImage} src={image} />

        <div className={Style.buttonName}>
          {isConnected ? t('manage') : t('connect')}
        </div>
      </div>
    </button>
  );
};

export default connect(mapStateToProps)(IglucoseButton);
