import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import deepMerge from 'deepmerge';
import Readings from '~/services/Readings';
import { TYPE_CGM } from '~/bundles/shared/constants/readings';
import Colors from '~/../assets/styles/export/colors';
import Sizes from '~/../assets/styles/export/sizes';
import PageHelper from '~/redux/modules/page/PageHelper';
import { SECONDS_IN_DAY, ONE_DAY } from '~/bundles/shared/constants/time';
import { FETCH_STATUSES } from 'bundles/shared/constants/graphs';
import Tooltips from '~/bundles/shared/components/graphs/tooltips';
import GraphPresenter from '~/bundles/shared/components/graphs/GraphPresenter/GraphPresenter';
import UserHelper from '~/redux/modules/users/UserHelper';
import Style from './BGGraph.scss';

const mapStateToProps = (state, ownProps) => {
  const {
    beforeMealNormalGlucoseMax,
    afterMealNormalGlucoseMax,
    normalGlucoseMin,
    meterUnits,
  } = UserHelper.displayUser(state).preference;

  const showBeforeMaxLine = ownProps.showBeforeMaxLine ?? state.page.readingsType !== TYPE_CGM;

  return {
    showBeforeMaxLine,
    beforeMealMax: Readings.displayValue(
      Readings.denormalizedValue(beforeMealNormalGlucoseMax),
      meterUnits,
    ),
    afterMealMax: Readings.displayValue(
      Readings.denormalizedValue(afterMealNormalGlucoseMax),
      meterUnits,
    ),
    normalMin: Readings.displayValue(
      Readings.denormalizedValue(normalGlucoseMin),
      meterUnits,
    ),
    meterUnits,
    percentilesWidth: PageHelper.currentTimeframe(state).percentilesWidth,
    isPageTimeFrameOneDay: PageHelper.currentTimeFrameLength(state) === ONE_DAY,
  };
};

const BGGraph = (props) => {
  const {
    normalMin,
    afterMealMax,
    beforeMealMax,
    yAxisMax = 400,
    showBeforeMaxLine,
    configOverride = {},
    meterUnits,
    percentilesWidth,
    isPageTimeFrameOneDay,
    ...config
  } = props;

  const xAxisMax = SECONDS_IN_DAY;
  const tooltipFormatter = isPageTimeFrameOneDay ? Tooltips.bloodGlucoseTooltip : Tooltips.scatterTooltip;

  return (
    <div className={Style.BGGraph}>
      <GraphPresenter
        tooltipPositioner={Tooltips.tooltipHoursPositioner}
        tooltipFormatter={tooltipFormatter}
        {...config}
        configOverride={deepMerge({
          reactProps: { xAxisMax, meterUnits },
          chart: {
            paddingTop: Sizes.bgGraphPaddingTopBottom,
            paddingBottom: Sizes.bgGraphPaddingTopBottom,
          },
          plotOptions: {
            series: {
              pointWidth: percentilesWidth,
            },
          },
          yAxis: {
            max: yAxisMax,
            plotBands: [{
              color: Colors.calendarDayBand,
              from: normalMin,
              to: afterMealMax,
              zIndex: 2,
            }],
            plotLines: [
              {
                color: Colors.bgAfterMax,
                width: 1,
                value: afterMealMax,
                zIndex: 2,
              }, {
                color: Colors.bgNormalMin,
                width: 1,
                value: normalMin,
                zIndex: 2,
              },
              ...((showBeforeMaxLine) ? [{
                color: Colors.bgBeforeMax,
                width: 1,
                dashStyle: 'dash',
                value: beforeMealMax,
                zIndex: 2,
              }] : []),
            ],
          },
        }, configOverride)}
      />
    </div>
  );
};

BGGraph.propTypes = {
  series: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchStatus: PropTypes.oneOf(FETCH_STATUSES),
  startTimestamp: PropTypes.number,
  endTimestamp: PropTypes.number,
  windowHeight: PropTypes.number,
  windowWidth: PropTypes.number,
  configOverride: PropTypes.shape({}),
  yAxisMax: PropTypes.number,
  showBeforeMaxLine: PropTypes.bool,
  weekView: PropTypes.bool,
};

export default connect(mapStateToProps)(BGGraph);
