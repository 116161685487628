import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { orderBy, uniqBy, isEqual, isEmpty } from 'lodash';
import { bindActionCreators } from '@reduxjs/toolkit';
import { Button } from '@glooko/common-ui';
import { sendActivationEmail } from '~/redux/thunks/users';
import {
  DOB_SUCCESS, ASSIGN_SUCCESS, ASSIGN_FAIL, LOADING,
  fetchTerminalSyncsThunk, assignToPatientThunk,
  unassignTerminalSyncThunk,
} from '~/redux/thunks/terminalSyncs';
import {
  fetchSuggestedResultsThunk,
} from '~/redux/thunks/providerGroupSite/providerGroupSite';
import { TIME_FORMATS } from '~/utils/i18nFormats';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import ImageStore from '~/utils/ImageStore';
import {
  updateConfirmDobModalVisibility, updateDeviceTypesFilterSelection,
  updateTerminalsFilterSelection, updateFindPatientModalVisibility,
  updateRowSyncData, updateEndTimestampMax, updateTimestamps,
  updatePatientICPADetailsModalVisibility, updateInsulinSelectorVisibility,
  updateMedicationOverrideList, updatePayloadForMedicationSelector,
  updateSelectedOverrideGuid,
} from '~/redux/modules/page/page';
import { updateHideBanner, UPDATE_PATIENT_ICPA_DATA_SUCCESS } from '~/redux/modules/providerGroupSite/providerGroupSite';
import ConfirmDobModal from '~/bundles/shared/components/ConfirmDobModal/ConfirmDobModal.jsx';
import { usersReplacePatient } from '~/redux/modules/users/users';
import {
  trackFilteredTypeSelected,
  trackPatientClicked,
  trackCreateReportClicked,
  trackUnassignClicked,
  trackAssignToOtherClicked,
  trackAssignDevicesRefreshClicked,
} from '~/services/eventLogging';
import FindPatientModal from '~/bundles/poptracker/FindPatientModal/FindPatientModal.jsx';
import { PATH_SUMMARY } from '~/bundles/shared/constants/navigation';
import { updatePdfWizardVisibilityThunk } from '~/redux/thunks/page';
import { getRecentlySyncedUserICPAData, resetPatientICPADetailsStatusInStore } from '~/redux/thunks/patientICPADetails';
import PdfWizard from '~/bundles/shared/components/PdfWizard/PdfWizard.jsx';
// eslint-disable-next-line no-unused-vars
import { patientHasICPARequiredData } from '~/utils/ICPAConfig';
import Spinner from '~/bundles/shared/components/Spinner/Spinner';
import AddEmailBanner from './AddEmailBanner/AddEmailBanner';
import AddEmailDialog from './AddEmailDialog/AddEmailDialog'; // eslint-disable-line
import ButtonAssign from './ButtonAssign/ButtonAssign';
import SelectInsulinType from './SelectInsulinType/SelectInsulinType';
import Style from './RecentTerminalSyncs.scss';
import TerminalSyncSelect from './TerminalSyncSelect';

const pagination = {
  sortInfo: {
    page: 1,
    field: 'syncTimestamp',
    ascending: false,
    localDate: moment().format('YYYY-MM-DD'),
  },
};

const mapStateToProps = (state) => ({
  pagination,
  authCode: state.providerGroupSite.authCode,
  serialNumberCache: state.providerGroupSite.devices.serialNumberCache,
  terminalSyncs: state.providerGroupSite.devices.terminalSyncs,
  medicationOverrideList: state.page.medicationOverrideList,
  selectedOverrideGuid: state.page.selectedOverrideGuid,
  insulinSelectorVisible: state.page.insulinSelectorDisplayed,
  payloadForMedicationSelector: state.page.payloadForMedicationSelector,
  rowIdForMedicationSelector: state.page.rowIdForMedicationSelector,
  confirmDobVisible: state.page.confirmDobModalDisplayed,
  findPatientVisible: state.page.findPatientModalDisplayed,
  terminals: state.page.terminals,
  deviceTypes: state.page.deviceTypes,
  page: state.page,
  assignStatus: state.page.assignStatus,
  patient: state.page.patient,
  kioskSyncPdf: state.providerGroupSite.subscriptionModel.kioskSyncPdf,
  icpaConfig: state.providerGroupSite.icpaConfig,
  selectedPatientICPADataUpdateStatus: state.providerGroupSite.selectedPatientICPADataUpdateStatus,
  recentlySyncedUsersICPAData: state.providerGroupSite.recentlySyncedUsersICPAData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchSuggestedResultsThunk,
    fetchTerminalSyncsThunk,
    assignToPatientThunk,
    unassignTerminalSyncThunk,
    updateSelectedOverrideGuid,
    updateMedicationOverrideList,
    updateInsulinSelectorVisibility,
    updatePayloadForMedicationSelector,
    updateConfirmDobModalVisibility,
    updateDeviceTypesFilterSelection,
    updateTerminalsFilterSelection,
    sendActivationEmail,
    updateFindPatientModalVisibility,
    updateRowSyncData,
    updateHideBanner,
    updatePdfWizardVisibilityThunk,
    usersReplacePatient,
    updateEndTimestampMax,
    updateTimestamps,
    updatePatientICPADetailsModalVisibility,
    resetPatientICPADetailsStatusInStore,
    getRecentlySyncedUserICPAData,
  }, dispatch),
});

export const assignDeviceToPatient = (
  assignToPatientThunkProp, payload, rowId, onDone, onFailure = undefined, modalClosed = undefined,
) => {
  assignToPatientThunkProp(
    payload,
    rowId,
    onDone,
    onFailure,
    modalClosed,
  );
};

class RecentTerminalSyncs extends React.Component {
  static propTypes = {
    actions: PropTypes.shape({
      fetchSuggestedResultsThunk: PropTypes.func,
      fetchTerminalSyncsThunk: PropTypes.func,
      assignToPatientThunk: PropTypes.func,
      unassignTerminalSyncThunk: PropTypes.func,
      updateSelectedOverrideGuid: PropTypes.func,
      updateMedicationOverrideList: PropTypes.func,
      updateInsulinSelectorVisibility: PropTypes.func,
      updatePayloadForMedicationSelector: PropTypes.func,
      updateConfirmDobModalVisibility: PropTypes.func,
      updateTerminalsFilterSelection: PropTypes.func,
      updateDeviceTypesFilterSelection: PropTypes.func,
      sendActivationEmail: PropTypes.func,
      updateFindPatientModalVisibility: PropTypes.func,
      updateRowSyncData: PropTypes.func,
      updateHideBanner: PropTypes.func,
      updatePdfWizardVisibilityThunk: PropTypes.func,
      usersReplacePatient: PropTypes.func,
      updateEndTimestampMax: PropTypes.func,
      updateTimestamps: PropTypes.func,
      updatePatientICPADetailsModalVisibility: PropTypes.func,
      resetPatientICPADetailsStatusInStore: PropTypes.func,
      getRecentlySyncedUserICPAData: PropTypes.func,
    }),
    authCode: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    confirmDobVisible: PropTypes.bool.isRequired,
    findPatientVisible: PropTypes.bool.isRequired,
    terminals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    deviceTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    terminalSyncs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    serialNumberCache: PropTypes.shape({}).isRequired,
    pagination: PropTypes.shape({}).isRequired,
    assignStatus: PropTypes.shape({}).isRequired,
    pdfWizardDisplayed: PropTypes.bool,
    kioskSyncPdf: PropTypes.bool.isRequired,
    icpaConfig: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      dateOfBirth: PropTypes.string.isRequired,
      participantId: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      medicalRecordNumber: PropTypes.string,
      phoneNumber: PropTypes.string.isRequired,
      zipCode: PropTypes.string.isRequired,
      typeOfDiabetes: PropTypes.string.isRequired,
      gender: PropTypes.string.isRequired,
      t1dExchangeIdentifier: PropTypes.string.isRequired,
    }).isRequired,
    selectedPatientICPADataUpdateStatus: PropTypes.string.isRequired,
    recentlySyncedUsersICPAData: PropTypes.shape({
      status: PropTypes.string.isRequired,
      users: PropTypes.object.isRequired,
    }).isRequired,
    medicationOverrideList: PropTypes.object,
    insulinSelectorVisible: PropTypes.bool,
    payloadForMedicationSelector: PropTypes.object,
    rowIdForMedicationSelector: PropTypes.string,
    selectedOverrideGuid: PropTypes.string,
  };

  static defaultProps = {
    actions: {
      fetchSuggestedResultsThunk: () => { },
      fetchTerminalSyncsThunk: () => { },
      assignToPatientThunk: () => { },
      updateSelectedOverrideGuid: () => { },
      unassignTerminalSyncThunk: () => { },
      updateConfirmDobModalVisibility: () => { },
      updateTerminalsFilterSelection: () => { },
      updateDeviceTypesFilterSelection: () => { },
      sendActivationEmail: () => { },
      updateFindPatientModalVisibility: () => { },
      updateRowSyncData: () => { },
      updateHideBanner: () => { },
      updatePdfWizardVisibilityThunk: () => { },
      usersReplacePatient: () => { },
      updateEndTimestampMax: () => { },
      updateTimestamps: () => { },
      updatePatientICPADetailsModalVisibility: () => { },
      resetPatientICPADetailsStatusInStore: () => { },
      getRecentlySyncedUserICPAData: () => { },
    },
    t: () => { },
    confirmDobVisible: false,
    findPatientVisible: false,
    terminals: [],
    deviceTypes: [],
    terminalSyncs: [],
    serialNumberCache: {},
    pdfWizardDisplayed: false,
    medicationOverrideList: null,
    insulinSelectorVisible: false,
    payloadForMedicationSelector: null,
    rowIdForMedicationSelector: null,
    selectedOverrideGuid: null,
  };

  static saveFilterSelections(data, type) {
    localStorage.setItem(type, JSON.stringify(data));
  }

  static checkIfSyncFailedPreviously(rowId) {
    const rowStatus = JSON.parse(localStorage.getItem('rowIdStatus') || '[]');
    if (rowStatus.length > 0) {
      const shouldFade = rowStatus.filter((status) => status.rowId === rowId)[0];
      if (shouldFade) {
        return shouldFade.status === ASSIGN_FAIL;
      }
    }
    return false;
  }

  static getTerminalName(item) {
    return item.terminalName || item.terminalSerialNumber;
  }

  static wasSyncYesterday(syncTimestamp) {
    const mostRecentLocalMidnightInUnixTime = new Date().setHours(0, 0, 0, 0);
    const timeDifferenceFromUTC = new Date().getTimezoneOffset() * 60 * 1000;
    const syncLocalUnixTimestamp = new Date(syncTimestamp).getTime() + timeDifferenceFromUTC;

    return mostRecentLocalMidnightInUnixTime > syncLocalUnixTimestamp;
  }

  constructor(props) {
    super(props);
    this.state = {
      AddEmailDialog: {
        dialogVisible: false,
        glookoCode: null,
        rowId: null,
      },
      rowStatuses: [],
      rowSortOrder: 'desc',
      updatePatientDataView: false,
      existingUserDetails: {},
      alreadyAssignedPatient: false,
      updatePatientDataFormForConfirmDobFlow: false,
      dataMatchedWithICPAConfigAfterConfirmDOB: false,
    };
    this.showDialog = this.showDialog.bind(this);
    this.hideAddEmailDialog = this.hideAddEmailDialog.bind(this);
    this.changeEmailAndInvite = this.changeEmailAndInvite.bind(this);
    this.setPatientSelectedDob = this.setPatientSelectedDob.bind(this);
    this.setStateSelectedRowPatient = this.setStateSelectedRowPatient.bind(this);
    this.selectFilterOptions = this.selectFilterOptions.bind(this);
    this.assignIfSelectedDobValid = this.assignIfSelectedDobValid.bind(this);
    this.dobDatepickerData = this.dobDatepickerData.bind(this);
    this.parseDeviceType = this.parseDeviceType.bind(this);
    this.confirmDobEvent = this.confirmDobEvent.bind(this);
    this.showFindPatientEvent = this.showFindPatientEvent.bind(this);
    this.saveRowStatus = this.saveRowStatus.bind(this);
    this.updateRowStatus = this.updateRowStatus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.hideFindPatientEvent = this.hideFindPatientEvent.bind(this);
    this.fetchTerminalSyncsHandler = this.fetchTerminalSyncsHandler.bind(this);
    this.unassignTerminalSync = this.unassignTerminalSync.bind(this);
    this.showConfirmDobModal = this.showConfirmDobModal.bind(this);
    this.hideConfirmDobModal = this.hideConfirmDobModal.bind(this);
    this.updateTerminalsFilter = this.updateTerminalsFilter.bind(this);
    this.updateDeviceTypesFilter = this.updateDeviceTypesFilter.bind(this);
    this.sortRowsBySyncTimestamp = this.sortRowsBySyncTimestamp.bind(this);
    this.showUpdatePatientModalAfterDOB = this.showUpdatePatientModalAfterDOB.bind(this);
    this.assignmentAfterICPADataUpdate = this.assignmentAfterICPADataUpdate.bind(this);
    this.fetchRecentlySyncedUsersICPAData = this.fetchRecentlySyncedUsersICPAData.bind(this);
    this.resetKeysInState = this.resetKeysInState.bind(this);
    this.handleOnCancelClick = this.handleOnCancelClick.bind(this);
    this.handleRefreshList = this.handleRefreshList.bind(this);
  }

  componentDidMount() {
    this.fetchTerminalSyncsHandler();
  }

  componentDidUpdate(prevProps) {
    const {
      dataMatchedWithICPAConfigAfterConfirmDOB,
      updatePatientDataFormForConfirmDobFlow,
    } = this.state;

    const patientICPADataUpdated =
      this.props.selectedPatientICPADataUpdateStatus === UPDATE_PATIENT_ICPA_DATA_SUCCESS;
    this.updateRowStatus(prevProps);
    this.selectFilterOptions(prevProps);

    if (
      (updatePatientDataFormForConfirmDobFlow && patientICPADataUpdated) ||
      dataMatchedWithICPAConfigAfterConfirmDOB
    ) {
      this.assignIfSelectedDobValid();
    }
    this.assignmentAfterICPADataUpdate(patientICPADataUpdated);
    this.fetchRecentlySyncedUsersICPAData(prevProps);
  }

  setPatientSelectedDob(dob) {
    this.setState({
      dob,
    });
    this.showUpdatePatientModalAfterDOB(dob);
  }

  setStateSelectedRowPatient(dataset) {
    const { terminalSyncInfo } = dataset.device;
    const patient = {
      id: dataset.id,
      firstName: dataset.firstName,
      lastName: dataset.lastName,
      glookoCode: dataset.glookoCode,
      guid: dataset.guid,
      rowId: dataset.rowId,
    };
    const terminal = {
      syncTimestamp: terminalSyncInfo.syncTimestamp,
      deviceSerialNumber: terminalSyncInfo.deviceSerialNumber,
      deviceType: terminalSyncInfo.deviceType,
    };

    this.setState({
      patient,
      terminal,
    });
  }

  setMedicationOverrideList = (medicationOverrideList) => {
    this.props.actions.updateMedicationOverrideList(medicationOverrideList);
  };

  setSelectedOverrideGuid = (selectedOverrideGuid) => {
    this.props.actions.updateSelectedOverrideGuid(selectedOverrideGuid);
  };

  setPayloadForMedicationSelector = (payload, rowId) => {
    this.props.actions.updatePayloadForMedicationSelector(payload, rowId);
  }

  setDisplayInsulinSelector = (value) => {
    this.props.actions.updateInsulinSelectorVisibility(value);
  }

  getFilterSelectedItems(type) {
    const { terminals, deviceTypes } = this.props;
    switch (type) {
      case 'terminals':
        return terminals.map((e) => e.label).join(', ');
      case 'deviceTypes':
        return deviceTypes.map((e) => e.value).join(', ');
      default:
        return 'none';
    }
  }

  getRowStyle(selectedRowId, showBanner, item) {
    if (this.checkIfRowStatusIs(selectedRowId, LOADING)) {
      return Style.deviceRowFaded;
    }
    if (this.checkIfRowStatusIs(selectedRowId, ASSIGN_SUCCESS)) {
      return Style.deviceRowAssigned;
    } else if (item.syncProcess === 'failed' || item.failureCode) {
      return Style.deviceRowBorder;
    } else if (item.syncProcess === 'succeeded') {
      return Style.deviceRowAssigned;
    }
    return Style.deviceRow;
  }

  assignmentAfterICPADataUpdate(patientICPADataUpdated) {
    const { glookoCode, guid, dob, rowId, id } = this.state.existingUserDetails;
    if (patientICPADataUpdated && this.state.alreadyAssignedPatient) {
      this.overrideMedicationAndAssignDevice(
        {
          patient: glookoCode,
          sync: guid,
          dateOfBirth: moment.utc(dob).format('YYYY-MM-DD'),
        },
        rowId,
      );
      this.props.actions.updateFindPatientModalVisibility(false);
      this.props.actions.getRecentlySyncedUserICPAData(id);
      // update the ICPA data in store
      this.resetKeysInState();
    }
  }

  fetchRecentlySyncedUsersICPAData(prevProps) {
    const { terminalSyncs,
      serialNumberCache: { patientsMatchingDevices },
      recentlySyncedUsersICPAData: { status } } = this.props;
    const patientMatchingDevicesPropsAreNotEqual = !isEqual(
      patientsMatchingDevices,
      prevProps.serialNumberCache.patientsMatchingDevices,
    );

    if (patientMatchingDevicesPropsAreNotEqual && status === '') {
      const recentlySyncedPatientsWithDevice = [...new Set(
        terminalSyncs.map((elem) => {
          const matchingPatient = this.filterMatchingPatients(elem);
          return matchingPatient ? matchingPatient.id : null;
        }).filter((elem) => elem != null),
      )];
      recentlySyncedPatientsWithDevice.forEach((id) => this.props.actions.getRecentlySyncedUserICPAData(id),
      );
    }
  }

  resetKeysInState() {
    this.setState({
      dob: null,
      updatePatientDataView: false,
      existingUserDetails: {},
      alreadyAssignedPatient: false,
      updatePatientDataFormForConfirmDobFlow: false,
      dataMatchedWithICPAConfigAfterConfirmDOB: false,
      displayInsulinSelector: false,
    });
  }

  showUpdatePatientModalAfterDOB(dob) {
    const {
      icpaConfig,
      recentlySyncedUsersICPAData: { users },
    } = this.props;
    const { patient } = this.state;
    if (
      !isEmpty(patient) && !isEmpty(users[patient.id]) &&
      !patientHasICPARequiredData(icpaConfig, users[patient.id])
    ) {
      this.setState({
        updatePatientDataView: true,
        existingUserDetails: { ...users[patient.id], dobConfirmation: dob },
        updatePatientDataFormForConfirmDobFlow: true,
      });
      this.props.actions.updateFindPatientModalVisibility(true);
    } else {
      this.setState({ dataMatchedWithICPAConfigAfterConfirmDOB: true });
    }
  }

  preparePDfWizardForPatient(patient) {
    let endDate;
    const { kioskSyncPdf } = this.props;
    const { lastSyncTimestamp, kiosk } = patient.lastSyncTimestamps;

    if (kioskSyncPdf) {
      endDate = kiosk === '-' ? moment.utc('1970-01-01').toISOString() : kiosk;
    } else if (lastSyncTimestamp === '-') {
      endDate = moment.utc().toISOString();
    } else {
      endDate = lastSyncTimestamp;
    }

    const startDate = moment.utc(endDate).subtract(13, 'days').toISOString();

    this.props.actions.usersReplacePatient(patient);
    this.props.actions.updateEndTimestampMax(endDate, endDate);
    this.props.actions.updateTimestamps(startDate, endDate);
  }

  createReportVisibility(value, patientData, device) {
    if (value) {
      trackCreateReportClicked(device);
    }
    this.preparePDfWizardForPatient(patientData);
    this.props.actions.updatePdfWizardVisibilityThunk(value);
  }

  hasAssignmentFailed(selectedRowId, syncProcess) {
    if (this.checkIfRowStatusIs(selectedRowId, ASSIGN_FAIL) ||
      syncProcess === 'failed') {
      return true;
    }
    return RecentTerminalSyncs.checkIfSyncFailedPreviously(selectedRowId);
  }

  selectFilterOptions(prevProps) {
    const { terminalSyncs, t, deviceTypes } = this.props;
    if (terminalSyncs !== prevProps.terminalSyncs) {
      if (deviceTypes.length < 1) {
        if (localStorage.getItem('devices')) {
          const devicesStored = JSON.parse(localStorage.getItem('devices'));
          this.updateDeviceTypesFilter(devicesStored);
        } else {
          const device = [{
            value: t('cgm'),
            label: t('cgm'),
          }, {
            value: t('meter'),
            label: t('meter'),
          }, {
            value: t('pump'),
            label: t('pump'),
          }, {
            value: t('insulinPen'),
            label: t('insulinPen'),
          }];
          this.updateDeviceTypesFilter(device);
        }
      }
      // Items unchecked before Refresh should remain unchecked
      let itemsPrevUnchecked = [];

      if (localStorage.getItem('terminalSyncs')) {
        const prevTerminals = JSON.parse(localStorage.getItem('terminalSyncs')).map((item) => ({
          label: RecentTerminalSyncs.getTerminalName(item.terminalSyncInfo),
          value: item.terminalSyncInfo.terminalSerialNumber,
        }));
        itemsPrevUnchecked = uniqBy(prevTerminals, 'value');
      }

      if (localStorage.getItem('terminals')) {
        const terminalsStored = JSON.parse(localStorage.getItem('terminals'));
        itemsPrevUnchecked = itemsPrevUnchecked.filter((obj) => !terminalsStored.some((obj2) => obj.value === obj2.value));
      }

      // New terminals that get added to the PGS should be checked
      const itemsSelected = [];

      terminalSyncs.map((item) => {
        const label = RecentTerminalSyncs.getTerminalName(item.terminalSyncInfo);
        const value = item.terminalSyncInfo.terminalSerialNumber;
        itemsSelected.push({
          label,
          value,
        });
        return null;
      });

      if (itemsPrevUnchecked.length === 0) {
        this.updateTerminalsFilter(uniqBy(itemsSelected, 'value'));
      } else {
        const itemsSelectedSavePrevState = uniqBy(itemsSelected, 'value').filter((obj) => !itemsPrevUnchecked.some((obj2) => obj.value === obj2.value));
        if (itemsSelectedSavePrevState.length > 0) {
          this.updateTerminalsFilter(uniqBy(itemsSelectedSavePrevState, 'value'));
        }
      }
    }
  }

  assignIfSelectedDobValid() {
    const { patient, dob } = this.state;

    if (patient) {
      if (this.checkIfRowStatusIs(patient.rowId, DOB_SUCCESS) && dob) {
        this.overrideMedicationAndAssignDevice({
          patient: patient.glookoCode,
          sync: patient.guid,
          dateOfBirth: dob,
        }, patient.rowId);

        this.props.actions.updateFindPatientModalVisibility(false);
        this.resetKeysInState();
      }
    }
  }

  dobDatepickerData(dob) {
    this.setState({
      dob,
    });
  }

  parseDeviceType(type) {
    const { t } = this.props;
    switch (type) {
      case 'bg_meter':
        return t('meter');
      case 'cgm_device':
        return t('cgm');
      case 'pump':
        return t('pump');
      case 'insulin_pen':
        return t('insulinPen');
      default:
        return null;
    }
  }

  confirmDobEvent(dataset) {
    const { icpaConfig, recentlySyncedUsersICPAData: { users } } = this.props;
    const rowId = dataset.rowId;

    this.setMedicationOverrideList(dataset.medicationOverrideList);
    this.setSelectedOverrideGuid(dataset.selectedOverrideGuid);

    if (!this.checkIfRowStatusIs(rowId, ASSIGN_FAIL) &&
      !this.checkIfRowStatusIs(rowId, LOADING) &&
      !RecentTerminalSyncs.checkIfSyncFailedPreviously(rowId)) {
      if (dataset.dob) {
        if (!patientHasICPARequiredData(icpaConfig, users[dataset.id])) {
          this.setState({
            updatePatientDataView: true,
            existingUserDetails: { ...users[dataset.id], ...dataset },
            alreadyAssignedPatient: true,
          });

          this.props.actions.updateFindPatientModalVisibility(true);
        } else {
          this.overrideMedicationAndAssignDevice({
            patient: dataset.glookoCode,
            sync: dataset.guid,
            dateOfBirth: moment.utc(dataset.dob).format('YYYY-MM-DD'),
            medicationOverrideList: dataset.medicationOverrideList,
          }, rowId);
        }
      } else {
        this.setStateSelectedRowPatient(dataset);
        this.showConfirmDobModal();
      }
    }
  }

  showFindPatientEvent(dataset) {
    const rowId = dataset.rowId;

    this.setMedicationOverrideList(dataset.medicationOverrideList);
    this.setSelectedOverrideGuid(dataset.selectedOverrideGuid);

    if (!this.checkIfRowStatusIs(rowId, ASSIGN_FAIL) &&
      !this.checkIfRowStatusIs(rowId, LOADING) &&
      !RecentTerminalSyncs.checkIfSyncFailedPreviously(rowId)) {
      const data = {
        guid: dataset.guid,
        rowId,
      };
      trackAssignToOtherClicked(dataset.device, dataset.prvPatientGlookoCode);
      this.props.actions.updateFindPatientModalVisibility(true);
      this.props.actions.updateRowSyncData(data);
    }
  }

  // works only when update modal is opened after confirm dob or already assigned patient
  handleOnCancelClick(e) {
    if (e === undefined) return;
    const {
      updatePatientDataFormForConfirmDobFlow,
      alreadyAssignedPatient,
    } = this.state;
    const headerXButtonClicked =
      e.currentTarget.className === 'FindPatientHeaderPresenter_btnClose';
    const cancelButtonClicked =
      [...e.target.classList].indexOf('FindPatientFooterPresenter_buttonMinWidth') > -1;
    if (updatePatientDataFormForConfirmDobFlow || alreadyAssignedPatient) {
      if (headerXButtonClicked || cancelButtonClicked) {
        this.resetKeysInState();
      }
    }
  }

  hideFindPatientEvent(e) {
    this.props.actions.updateFindPatientModalVisibility(false);
    this.handleOnCancelClick(e);
  }

  handleRefreshList() {
    trackAssignDevicesRefreshClicked();
    this.fetchTerminalSyncsHandler(false);
  }

  fetchTerminalSyncsHandler(refreshed = true) {
    if (!refreshed) {
      this.props.actions.fetchSuggestedResultsThunk();
    }
    return this.props.actions.fetchTerminalSyncsThunk(this.props.pagination, refreshed);
  }

  overrideMedicationAndAssignDevice = (params, rowId) => {
    if (this.props.medicationOverrideList) {
      this.setPayloadForMedicationSelector(params, rowId);
      this.setDisplayInsulinSelector(true);
    } else if (params.medicationOverrideList) {
      this.setMedicationOverrideList(params.medicationOverrideList);
      this.setPayloadForMedicationSelector(params, rowId);
      this.setDisplayInsulinSelector(true);
    } else {
      assignDeviceToPatient(this.props.actions.assignToPatientThunk, params, rowId,
        () => this.fetchTerminalSyncsHandler(false));
    }
  }

  unassignTerminalSync(dataset) {
    trackUnassignClicked(dataset.device);
    this.props.actions.unassignTerminalSyncThunk(
      { sync: dataset.params.guid },
      dataset.params.rowId,
      () => this.fetchTerminalSyncsHandler(false),
    );
  }

  showConfirmDobModal() {
    this.props.actions.updateConfirmDobModalVisibility(true);
  }

  hideConfirmDobModal() {
    this.props.actions.updateConfirmDobModalVisibility(false);
  }

  updateTerminalsFilter(selection) {
    this.props.actions.updateTerminalsFilterSelection(selection);
  }

  updateDeviceTypesFilter(selection) {
    this.props.actions.updateDeviceTypesFilterSelection(selection);
  }

  saveRowStatus(rowId) {
    const rowStatuses = this.state.rowStatuses;
    const errorCode = rowStatuses.filter((status) => status.rowId === rowId)[0].statusCode;
    const rowStatus = JSON.parse(localStorage.getItem('rowIdStatus') || '[]');
    rowStatus.push({ rowId, status: ASSIGN_FAIL, statusCode: errorCode });
    localStorage.setItem('rowIdStatus', JSON.stringify(uniqBy(rowStatus, 'rowId')));
  }

  updateRowStatus(prevProps) {
    const { assignStatus } = this.props;

    if (assignStatus !== prevProps.assignStatus) {
      const statusArray = this.state.rowStatuses;
      const newStatusArray = [];
      statusArray.map((item) => {
        if (item.rowId !== assignStatus.rowId) {
          newStatusArray.push({
            status: item.status,
            rowId: item.rowId,
            statusCode: item.statusCode,
          });
        }
        return null;
      });
      newStatusArray.push(assignStatus);

      this.setState({
        rowStatuses: newStatusArray,
      });
    }
  }

  rowShouldRender(serial, type) {
    return this.isChecked(serial, 'terminals') &&
      this.isChecked(this.parseDeviceType(type), 'deviceTypes');
  }

  rowIsLoading(selectedRowId) {
    const rowStatuses = this.state.rowStatuses;
    if (rowStatuses.length > 0) {
      return rowStatuses.some((row) => {
        if (row.rowId === selectedRowId) {
          return row.status === 'LOADING';
        }
        return null;
      });
    }
    return null;
  }

  checkIfRowStatusIs(id, status) {
    const rowStatuses = this.state.rowStatuses;
    if (rowStatuses.length > 0) {
      return rowStatuses.some((row) => {
        if (row.rowId === id) {
          return row.status === status;
        }
        return false;
      });
    }
    return false;
  }

  isDeviceAssigned(rowId, syncProcess) {
    return this.checkIfRowStatusIs(rowId, ASSIGN_SUCCESS) || syncProcess === 'succeeded';
  }

  filterMatchingPatients(device) {
    const { serialNumberCache } = this.props;
    const patients = serialNumberCache.patientsMatchingDevices;
    const devices = serialNumberCache.devicesOfPatients;
    const currentDevice = device.terminalSyncInfo.deviceSerialNumber;
    const userIds = new Set(
      Object.values(devices).filter((d) => d.serialNumber === currentDevice).map((d) => d.userId),
    );
    const getDeviceLastSyncTimestampForPatient = (patientId) => Object.values(devices).find(
      (d) => d.userId === patientId && d.serialNumber === currentDevice).lastSyncTimestamp;

    const filteredPatients = Object
      .values(patients)
      .filter((patient) => userIds.has(patient.id))
      .map((patient) => ({
        id: patient.id,
        firstName: patient.firstName,
        lastName: patient.lastName,
        fullName: `${patient.firstName} ${patient.lastName}`,
        dateOfBirth: patient.dateOfBirth,
        guid: device.guid,
        glookoCode: patient.glookoCode,
        authorizedSites: patient.authorizedSiteIds,
        lastSyncTimestamp: moment.utc(getDeviceLastSyncTimestampForPatient(patient.id)),
      }));

    return orderBy(filteredPatients, ['lastSyncTimestamp'], ['desc'])[0];
  }

  handleChange(options, type) {
    const { terminalSyncs } = this.props;

    const selection = options.map((option) => ({ value: option.value, label: option.label }));

    if (type === 'terminals') {
      trackFilteredTypeSelected('terminal', []);
      RecentTerminalSyncs.saveFilterSelections(terminalSyncs, 'terminalSyncs');
      RecentTerminalSyncs.saveFilterSelections(selection, 'terminals');
      this.updateTerminalsFilter(selection);
    } else {
      trackFilteredTypeSelected('device_type', selection.map((s) => s.value));
      RecentTerminalSyncs.saveFilterSelections(selection, 'devices');
      this.updateDeviceTypesFilter(selection);
    }
  }

  populateFilterOptions(type) {
    const { terminalSyncs, t } = this.props;
    if (terminalSyncs) {
      if (type === 'terminals') {
        return uniqBy(terminalSyncs.map((sync) => {
          const item = sync.terminalSyncInfo.terminalSerialNumber;
          return {
            filter: type,
            value: item,
            label: RecentTerminalSyncs.getTerminalName(sync.terminalSyncInfo),
            className: this.isChecked(item, type) ? Style.checked : Style.unchecked,
          };
        }), 'value');
      }
      if (type === 'deviceTypes') {
        return [{
          className: this.isChecked(t('cgm'), 'deviceTypes') ? Style.checked : Style.unchecked,
          filter: 'deviceTypes',
          label: t('cgm'),
          value: t('cgm'),
        }, {
          className: this.isChecked(t('meter'), 'deviceTypes') ? Style.checked : Style.unchecked,
          filter: 'deviceTypes',
          label: t('meter'),
          value: t('meter'),
        }, {
          className: this.isChecked(t('pump'), 'deviceTypes') ? Style.checked : Style.unchecked,
          filter: 'deviceTypes',
          label: t('pump'),
          value: t('pump'),
        }, {
          className: this.isChecked(t('insulinPen'), 'deviceTypes') ? Style.checked :
            Style.unchecked,
          filter: 'deviceTypes',
          label: t('insulinPen'),
          value: t('insulinPen'),
        }];
      }
    }
    return null;
  }

  sortRowsBySyncTimestamp() {
    const order = this.state.rowSortOrder;
    this.setState({
      rowSortOrder: order === 'desc' ? 'asc' : 'desc',
    });
  }

  isChecked(item, type) {
    const { terminals, deviceTypes } = this.props;
    switch (type) {
      case 'terminals':
        return terminals.some((i) => i.value === item);
      case 'deviceTypes':
        return deviceTypes.some((i) => i.value === item);
      default:
        return 'none';
    }
  }

  showDialog(patientGlookoCode, currentRowId) {
    this.setState({
      AddEmailDialog: {
        dialogVisible: true,
        glookoCode: patientGlookoCode,
        rowId: currentRowId,
      },
    });
  }

  hideAddEmailDialog() {
    this.setState({
      AddEmailDialog: {
        dialogVisible: false,
        glookoCode: null,
        rowId: null,
      },
    });
  }

  sendInvite() {
    this.props.actions.sendActivationEmail(this.state.AddEmailDialog.glookoCode);
  }

  changeEmailAndInvite() {
    this.sendInvite();
    this.fetchTerminalSyncsHandler(false);
    this.hideAddEmailDialog();
  }

  sortByLastSyncTimestamp(terminalSyncs) {
    const order = this.state.rowSortOrder;
    return orderBy(terminalSyncs, (obj) => obj.terminalSyncInfo.syncTimestamp, order);
  }

  formatSyncTimestamp(syncTimestamp) {
    const { t } = this.props;
    const formattedTimestamp = moment.utc(syncTimestamp).format(TIME_FORMATS.H_MM__A);
    return RecentTerminalSyncs.wasSyncYesterday(syncTimestamp) ?
      t('yesterdaySync', { timestamp: formattedTimestamp }) : formattedTimestamp;
  }

  isUnexpectedServerError(errorCode) {
    const numericErrorCode = parseInt(errorCode, 10);

    return numericErrorCode > 499 && numericErrorCode < 600;
  }

  renderPatientAssignmentColumn(device, rowId) {
    const { t } = this.props;
    const patientFiltered = this.filterMatchingPatients(device);
    const syncProcess = device.syncProcess;
    const buttonParamsAssignAndUnassign = {
      guid: device.guid,
      rowId,
      prvPatientGlookoCode: patientFiltered ? patientFiltered.glookoCode : null,
      device,
      medicationOverrideList: device.terminalSyncInfo.medicationOverrideList,
      selectedOverrideGuid: device.terminalSyncInfo.selectedOverrideGuid,
    };
    const rowError = this.hasAssignmentFailed(rowId, device.syncProcess);

    if (syncProcess === 'succeeded') {
      const assignedPatient = device.assignedPatient.user;
      return this.renderAssignedButtonState(buttonParamsAssignAndUnassign, device, assignedPatient);
    } else if (!patientFiltered) {
      return this.renderNewDeviceButtonState(buttonParamsAssignAndUnassign, rowId, rowError);
    }

    const dob = patientFiltered.dateOfBirth;
    const patientDob = dob ? moment.utc(dob).format(TIME_FORMATS.MM_DD_YYYY) : t('confirmDob');
    const buttonParamsAssignTo = {
      id: patientFiltered.id,
      firstName: patientFiltered.firstName,
      lastName: patientFiltered.lastName,
      glookoCode: patientFiltered.glookoCode,
      guid: device.guid,
      medicationOverrideList: device.terminalSyncInfo.medicationOverrideList,
      selectedOverrideGuid: device.terminalSyncInfo.selectedOverrideGuid,
      dob,
      rowId,
      device,
    };

    return (
      <td
        class={classNames({
          [Style.colAssignment]: true,
          [Style.error]: device.failureCode || device.syncProcess === 'failed' ||
            !device.deviceSupportedInPgsJurisdiction,
        })}
      >
        <div class={Style.btnContainerAssignTo}>
          <ButtonAssign
            disabled={this.checkIfRowStatusIs(rowId, ASSIGN_FAIL) || syncProcess === 'failed' ||
              !device.deviceSupportedInPgsJurisdiction}
            onClickCallback={this.confirmDobEvent}
            params={buttonParamsAssignTo}
            title={`${t('assignTo')} ${patientFiltered.fullName}`}
          />
        </div>
        <div class={Style.btnContainerAssignOther}>
          <ButtonAssign
            disabled={this.checkIfRowStatusIs(rowId, ASSIGN_FAIL) || syncProcess === 'failed' ||
              !device.deviceSupportedInPgsJurisdiction}
            onClickCallback={this.showFindPatientEvent}
            params={buttonParamsAssignAndUnassign}
            title={t('assignToOther')}
          />
        </div>
        <div class={Style.dob}>{patientDob}</div>
      </td>
    );
  }

  renderPipe(t) {
    return (
      <span>
        &nbsp;{t('pipe')}&nbsp;
      </span>
    );
  }

  renderAssignedButtonState(params, device, assignedPatient) {
    const { t } = this.props;
    const assignedPatientDob =
      moment.utc(assignedPatient.dateOfBirth).format(TIME_FORMATS.MM_DD_YYYY);
    const path = `${PATH_SUMMARY}?patient=${assignedPatient.id}`;
    const isWebUpload = device.paramsInfo &&
      device.paramsInfo.syncEvent &&
      device.paramsInfo.syncEvent.deviceInformation &&
      device.paramsInfo.syncEvent.deviceInformation.applicationType &&
      device.paramsInfo.syncEvent.deviceInformation.applicationType === 'web';

    return (
      <td
        class={classNames({
          [Style.colAssignment]: true,
          [Style.error]: false,
        })}
      >
        <div class={Style.btnContainerAssignTo}>
          <a
            class={Style.patientLink}
            href={path}
            onClick={() => trackPatientClicked(device)}
          >
            {assignedPatient.firstName} {assignedPatient.lastName}
          </a>
        </div>
        <div class={Style.btnContainerAssigned}>
          {this.renderPdfButton(assignedPatient, device)}
          {!isWebUpload && this.renderPipe(t)}
          {!isWebUpload && <ButtonAssign
            disabled={this.checkIfRowStatusIs(params.rowId, ASSIGN_FAIL) ||
              !device.deviceSupportedInPgsJurisdiction}
            onClickCallback={this.unassignTerminalSync}
            params={{
              device,
              params,
            }}
            title={t('unassign')}
          />}
        </div>
        <div class={Style.dob}>{assignedPatientDob}</div>
        <PdfWizard
          visible={this.props.pdfWizardDisplayed}
          visibilityCallback={this.createReportVisibility}
        />
      </td>
    );
  }

  renderDeviceNotSupportedInPgsJurisdiction() {
    const { t } = this.props;
    return (
      <tr
        class={Style.statusRow}
        key="rowMessage"
      >
        <td class={Style.statusMessage} colSpan="3">
          {t('assignFailNotInRegion')}
        </td>
      </tr>
    );
  }

  renderNewDeviceButtonState(params, rowId, rowError) {
    const { t } = this.props;
    const { device } = params;

    return (
      <td
        class={classNames({
          [Style.colAssignment]: true,
          [Style.error]: device.failureCode || device.syncProcess === 'failed' ||
            !device.deviceSupportedInPgsJurisdiction || rowError,
        })}
      >
        <div class={Style.newDevice}>{t('newDevice')}</div>
        <div class={Style.btnContainerAssign}>
          <ButtonAssign
            disabled={this.checkIfRowStatusIs(rowId, ASSIGN_FAIL) ||
              !device.deviceSupportedInPgsJurisdiction || rowError}
            onClickCallback={this.showFindPatientEvent}
            params={params}
            title={t('assign')}
          />
        </div>
      </td>
    );
  }

  renderStatusMessage(item) {
    const { t } = this.props;

    if (!item.deviceSupportedInPgsJurisdiction) {
      return [this.renderDeviceNotSupportedInPgsJurisdiction()];
    }

    if (item.failureCode || item.syncProcess === 'failed') {
      const errorCode = item.failureCode ? item.failureCode : '1';
      const isUnexpected = this.isUnexpectedServerError(errorCode);
      return [
        <tr
          class={Style.statusRow}
          key="rowMessage"
        >
          <td class={Style.statusMessage} colSpan="3">
            {isUnexpected && t('unexpectedFail', { error: errorCode })}
            {!isUnexpected && t('assignFail', { error: errorCode })}
          </td>
        </tr>,
      ];
    }
    return null;
  }

  renderStatusIcon(selectedRowId, syncProcess) {
    if (this.checkIfRowStatusIs(selectedRowId, LOADING)) {
      return [
        <div
          key="spinner"
        >
          <div class={Style.spinnerContainer}>
            <Spinner style={{ backgroundSize: '30px' }} />
          </div>
        </div>,
      ];
    }
    if (this.isDeviceAssigned(selectedRowId, syncProcess)) {
      return [
        <div
          key="checkmark"
        >
          <div class={Style.spinnerContainer}>
            <img class={Style.checkmarkSuccess} src={ImageStore.assignmentSuccess} alt="X" />
          </div>
        </div>,
      ];
    }
    return null;
  }

  renderPdfButton(patientData, device) {
    const { t } = this.props;
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    /* eslint-disable react/jsx-no-bind */
    return (
      <div
        class={Style.profileCreatePdf}
        onClick={this.createReportVisibility.bind(this, true, patientData, device)}
      >
        {t('createReport')}
      </div>
    );
    /* eslint-enable react/jsx-no-bind */
    /* eslint-enable jsx-a11y/no-static-element-interactions */
  }

  renderTerminalSyncRows(terminalSyncs, name, index) {
    const { t } = this.props;

    return (
      terminalSyncs.map((item) => {
        const rowId = `${item.id}`;
        const syncProcess = item.syncProcess;
        const errorBanner = item.failureCode || syncProcess === 'failed' ||
          !item.deviceSupportedInPgsJurisdiction;
        const emailBanner = item.assignedPatient && item.assignedPatient.user &&
          item.assignedPatient.user.fakeEmail && item.hideBanner === undefined;

        return [
          <tr key={`${name}_${rowId}`} class={this.getRowStyle(rowId, emailBanner, item)}>
            <td class={Style.colDeviceType}>
              <div class={Style.rowBorder} />
              <div
                class={classNames({
                  [Style.rowBorder]: true,
                  [Style.email]: emailBanner,
                  [Style.error]: errorBanner,
                })}
              />
              {this.renderStatusIcon(rowId, syncProcess)}
              <div
                class={classNames({
                  [Style.deviceContainer]: true,
                  [Style.error]: errorBanner,
                })}
              >
                <div class={Style.deviceName}>
                  {item.terminalSyncInfo.deviceType}
                </div>
                <div class={Style.deviceInfoContainer}>
                  <div class={Style.deviceType}>
                    {this.parseDeviceType(item.terminalSyncInfo.sourceType)}
                  </div>
                  <div class={Style.dividerPipe}>{t('pipe')}</div>
                  <div class={Style.deviceSerial}>
                    {item.terminalSyncInfo.deviceSerialNumber}
                  </div>
                </div>
              </div>
            </td>
            <td
              class={classNames({
                [Style.colLastSync]: true,
                [Style.error]: errorBanner,
              })}
            >
              <div class={Style.syncTimestamp}>
                {this.formatSyncTimestamp(item.terminalSyncInfo.syncTimestamp)}
              </div>
              <div class={Style.terminalSerial}>
                {RecentTerminalSyncs.getTerminalName(item.terminalSyncInfo)}
              </div>
            </td>
            {this.renderPatientAssignmentColumn(item, rowId)}
          </tr>,
          emailBanner ? <AddEmailBanner
            onClick={this.showDialog}
            key={rowId}
            glookoCode={item.assignedPatient.user.glookoCode}
            rowId={rowId}
            terminalSync={item}
            hideBanner={this.props.actions.updateHideBanner}
          /> : null,
          this.renderStatusMessage(item),
        ];
      })
    );
  }

  renderEmptyState(display, unassigned) {
    const { t } = this.props;
    const emptyStateMessage = unassigned ? t('unassignedEmptyState') : t('assignedEmptyState');

    if (display) {
      return (
        <tr class={Style.emptyStateContainer}>
          <td colSpan="3" class={Style.emptyStateCell}>
            <p class={Style.emptyStateText}>{emptyStateMessage}</p>
          </td>
        </tr>
      );
    }
    return null;
  }

  render() {
    const {
      confirmDobVisible, t, findPatientVisible, terminalSyncs, authCode, insulinSelectorVisible,
      payloadForMedicationSelector, rowIdForMedicationSelector,
    } = this.props;

    const { dialogVisible, glookoCode, rowId } = this.state.AddEmailDialog;
    const terminalSyncsSorted = this.sortByLastSyncTimestamp(terminalSyncs);
    const displayableTerminalSyncs = terminalSyncsSorted.filter((ts) => {
      const { terminalSerialNumber, sourceType } = ts.terminalSyncInfo;
      return this.rowShouldRender(terminalSerialNumber, sourceType);
    });
    const pendingTerminalSyncs = displayableTerminalSyncs.filter((ts) => ts.originalSection === 'unassigned' ||
      (!ts.originalSection && !ts.failureCode && ts.syncProcess === 'pending'),
    );
    const assignedTerminalSyncs = displayableTerminalSyncs.filter((ts) => (ts.originalSection === 'recentlyAssigned' ||
      (!ts.originalSection && (ts.failureCode || ts.syncProcess !== 'pending'))) &&
      (ts.syncProcess !== 'succeeded' || (ts.assignedPatient && ts.assignedPatient.user)),
    );
    const isSortAscending = this.state.rowSortOrder === 'asc';
    const displayUnassignedEmptyState = pendingTerminalSyncs.length === 0;
    const displayAssignedEmptyState = assignedTerminalSyncs.length === 0;

    return (
      <div class={Style.RecentTerminalSyncs}>
        <SelectInsulinType
          visible={insulinSelectorVisible}
          onClickCallback={assignDeviceToPatient}
          payload={payloadForMedicationSelector}
          rowId={rowIdForMedicationSelector}
          findPatientVisible={findPatientVisible}
          assignToPatientThunkProp={this.props.actions.assignToPatientThunk}
          updateSerialNumberCache={this.fetchTerminalSyncsHandler}
          selectedOverrideGuid={this.props.selectedOverrideGuid}
          medicationOverrideList={this.props.medicationOverrideList}
          setMedicationOverrideList={this.setMedicationOverrideList}
          setDisplayInsulinSelector={this.setDisplayInsulinSelector}
          setPayloadForMedicationSelector={this.setPayloadForMedicationSelector}
          getRecentlySyncedUserICPAData={this.props.actions.getRecentlySyncedUserICPAData}
        />
        <ConfirmDobModal
          visible={confirmDobVisible}
          visibilityCallback={this.hideConfirmDobModal}
          patient={this.state.patient}
          terminal={this.state.terminal}
          updatePatientDob={this.setPatientSelectedDob}
          setMedicationOverrideList={this.setMedicationOverrideList}
        />
        <AddEmailDialog
          key="AddEmailDialog"
          visible={dialogVisible}
          authCode={authCode}
          glookoCode={glookoCode}
          rowId={rowId}
          onCancel={this.hideAddEmailDialog}
          onInvite={this.changeEmailAndInvite}
        />
        <FindPatientModal
          updateSerialNumberCache={this.fetchTerminalSyncsHandler}
          visible={findPatientVisible}
          visibilityCallback={this.hideFindPatientEvent}
          updatePatientDataView={this.state.updatePatientDataView}
          existingUserDetails={this.state.existingUserDetails}
          assignDeviceToPatient={assignDeviceToPatient}
          medicationOverrideList={this.props.medicationOverrideList}
          setMedicationOverrideList={this.setMedicationOverrideList}
          setDisplayInsulinSelector={this.setDisplayInsulinSelector}
          setPayloadForMedicationSelector={this.setPayloadForMedicationSelector}
        />
        <table class={Style.filterTable}>
          <tbody>
            <tr>
              <td />
              <td>
                <span class={Style.filterTerminalsTitle}>{t('terminal')}</span>
              </td>
              <td>
                <span class={Style.filterDevicesTitle}>{t('deviceType')}</span>
              </td>
            </tr>
            <tr>
              <td>
                <div class={Style.lblFilter}>{t('filterBy')}</div>
              </td>
              <td>
                <div className={Style.selectContainer}>
                  <TerminalSyncSelect
                    defaultValue={this.props.terminals}
                    handleChange={this.handleChange}
                    options={this.populateFilterOptions('terminals')}
                    placeholder={this.getFilterSelectedItems('terminals')}
                    noResultsText={t('noResultsText')}
                    type='terminals'
                  />
                </div>
              </td>
              <td>
                <div className={Style.selectContainer}>
                  <TerminalSyncSelect
                    defaultValue={this.props.deviceTypes}
                    handleChange={this.handleChange}
                    placeholder={this.getFilterSelectedItems('deviceTypes')}
                    options={this.populateFilterOptions('deviceTypes')}
                    noResultsText={t('noResultsText')}
                    type='devices'
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <span
          class={Style.btnRefresh}
        >
          <Button
            dataAttributes={{ testid: 'terminal-syncs-refresh' }}
            onClick={() => this.handleRefreshList()}
            variation="link"
          >
            {t('refreshList')}
          </Button>
        </span>
        {/* eslint-enable jsx-a11y/no-static-element-interactions */}
        <table class={Style.deviceTable}>
          <thead>
            <tr>
              <th class={Style.headDeviceType}>{t('deviceType')}</th>
              <th class={Style.headLastSync}>
                <button
                  class={Style.btnSortLastSync}
                  onClick={this.sortRowsBySyncTimestamp}
                >
                  <div class={Style.lastSyncText}>{t('lastSync')}</div>
                  <div class={classNames({
                    [Style.sortChevron]: true,
                    [Style.ascending]: isSortAscending,
                  })}
                  />
                </button>
              </th>
              <th class={Style.headAssignment}>{t('assignment')}</th>
            </tr>
          </thead>
          <tbody>
            <tr class={Style.deviceRowSyncStatus}>
              <td class={Style.deviceRowSection} colSpan="3">{t('unassigned')}</td>
            </tr>
            {this.renderEmptyState(displayUnassignedEmptyState, true)}
            {this.renderTerminalSyncRows(pendingTerminalSyncs, 'unassigned')}
            <tr class={Style.deviceRowSyncStatus}>
              <td class={Style.deviceRowSection} colSpan="3">{t('recentlyAssigned')}</td>
            </tr>
            {this.renderEmptyState(displayAssignedEmptyState, false)}
            {this.renderTerminalSyncRows(assignedTerminalSyncs, 'assigned')}
          </tbody>
        </table>
      </div>
    );
  }
}

export const TranslatedRecentTerminalSyncs = translate('RecentTerminalSyncs')(RecentTerminalSyncs);
export default connect(mapStateToProps, mapDispatchToProps)(TranslatedRecentTerminalSyncs);
