import { Button, Dialog, DialogActions, DialogContent, DialogHeader, useDialogShow } from '@glooko/common-ui';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import UserHelper from '../../../../../redux/modules/users/UserHelper';
import { trackSelectedAppsDevices } from '../../../../../services/eventLogging';
import { useEversenseDisconnect } from './useEversenseDisconnect';
import Style from '../PartnerIntegrationsCard.scss';
import image from './eversense-connect-logo.png';
import logo from './eversense-logo.png';

const mapStateToProps = (state: State) => ({
  isPatient: UserHelper.currentUserIsPatient(state),
  isInUSA: UserHelper.currentUserCountry(state) === 'usa',
  isConnected: UserHelper.currentUser(state).eversenseConnected,
});

const EversenseButton = (props: ReturnType<typeof mapStateToProps>) => {
  const { isPatient, isInUSA, isConnected } = props;
  const { t } = useTranslation('EversenseButton');

  const { openDialog, isOpen, closeDialog } = useDialogShow();

  const disconnectMutation = useEversenseDisconnect();

  if (!(isPatient && isInUSA)) {
    return null;
  }

  const onButtonClick = () => {
    trackSelectedAppsDevices('Eversense Account');

    if (isConnected) {
      openDialog();
    } else {
      window.open('/api/v2/partners/eversense/authenticate');
    }
  };

  const onSuccessButtonClick = () => {
    window.location.href = '/settings';
  };

  const onDialogClose = () => {
    disconnectMutation.reset();
    closeDialog();
  };

  const disconnectAccount = () => {
    disconnectMutation.mutate();
  };

  return (
    <>
      <button
        className={Style.button}
        onClick={onButtonClick}
        type="button"
      >
        <div className={Style.buttonContent}>
          <img alt="integration" className={Style.buttonImage} src={image} />

          <div className={Style.buttonName}>
            {isConnected ? (
              <>{t('account')} <strong>{t('connected')}</strong></>
            ) : t('connect')}
          </div>
        </div>
      </button>

      <Dialog onClose={onDialogClose} open={isOpen}>
        <DialogHeader>
          <img alt="Eversense" className={Style.eversenseLogo} src={logo} />
        </DialogHeader>

        <DialogContent>
          {disconnectMutation.isIdle ? (
            <>
              {t('connectedToEversenseAccount')}
              <br />
              <br />
              {t('doYouWantToDisconnect')}
            </>
          ) : null}

          {disconnectMutation.isError ? t('couldNotDisconnect') : null}
          {disconnectMutation.isSuccess ? t('noLongerConnected') : null}
        </DialogContent>

        <DialogActions>
          {disconnectMutation.isIdle ? (
            <>
              <Button
                disabled={disconnectMutation.isLoading}
                onClick={onDialogClose}
                variation="secondary"
              >
                {t('cancel')}
              </Button>

              <Button
                disabled={disconnectMutation.isLoading}
                onClick={disconnectAccount}
              >
                {t('disconnect')}
              </Button>
            </>
          ) : null}

          {disconnectMutation.isError ? (
            <>
              <Button
                disabled={disconnectMutation.isLoading}
                onClick={onDialogClose}
                variation="secondary"
              >
                {t('cancel')}
              </Button>

              <Button
                disabled={disconnectMutation.isLoading}
                onClick={disconnectAccount}
              >
                {t('tryAgain')}
              </Button>
            </>
          ) : null}

          {disconnectMutation.isSuccess ? (
            <Button onClick={onSuccessButtonClick}>{t('ok')}</Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default connect(mapStateToProps)(EversenseButton);
