import disallowUndefinedProperties from '~/utils/disallowUndefined';

import AlertRed from '~/../assets/images/alert-red.png';
import PenAlarm from '~/../assets/images/pen-alarm.svg';
import GlucoseBeforeMeal from '~/../assets/images/glucose-before-meal.svg';
import GlucoseAfterMeal from '~/../assets/images/glucose-after-meal.svg';
import GlucoseAboveRange from '~/../assets/images/glucose-above-range.svg';
import GlucoseInTargetRange from '~/../assets/images/glucose-in-target-range.svg';
import GlucoseBelowRange from '~/../assets/images/glucose-below-range.svg';
import GlucoseBgReadings from '~/../assets/images/glucose-bg-readings.svg';
import GlucosePumpBg from '~/../assets/images/glucose-pump-bg.svg';
import GlucoseCalibrations from '~/../assets/images/glucose-calibrations.svg';
import GlucoseCgmReadings from '~/../assets/images/glucose-cgm-readings.svg';
import CgmMonday from '~/../assets/images/cgm-monday.svg';
import CgmTuesday from '~/../assets/images/cgm-tuesday.svg';
import CgmWednesday from '~/../assets/images/cgm-wednesday.svg';
import CgmThursday from '~/../assets/images/cgm-thursday.svg';
import CgmFriday from '~/../assets/images/cgm-friday.svg';
import CgmSaturday from '~/../assets/images/cgm-saturday.svg';
import CgmSunday from '~/../assets/images/cgm-sunday.svg';
import Carbs from '~/../assets/images/carbs.svg';
import GlucoseAbove400Pump from '~/../assets/images/glucose-above-400-pump.svg';
import GlucoseAbove400 from '~/../assets/images/glucose-above-400.svg';
import InsulinManual from '~/../assets/images/insulin-manual-insulin.svg';
import InsulinBolus from '~/../assets/images/insulin-bolus.svg';
import InsulinBolusSuggested from '~/../assets/images/insulin-bolus-suggested.svg';
import InsulinExtBolus from '~/../assets/images/insulin-ext-bolus.svg';
import InsulinAutomaticBolus from '~/../assets/images/insulin-automatic-bolus.svg';
import InsulinCustom from '~/../assets/images/insulin-custom.svg';
import InsulinScheduledBasal from '~/../assets/images/insulin-scheduled-basal.svg';
import InsulinUnusedScheduledBasal from '~/../assets/images/insulin-unused-scheduled-basal.svg';
import InsulinBasalModulation from '~/../assets/images/insulin-ciq-basal-modulation.svg';
import InsulinTempBasal from '~/../assets/images/insulin-temp-basal.svg';
import InsulinSuspend from '~/../assets/images/insulin-suspend.svg';
import InsulinCalculatedBolus from '~/../assets/images/insulin-calculated.svg';
import LgsPlgs from '~/../assets/images/lgs-plgs.svg';
import SetChange from '~/../assets/images/set-change.svg';
import GlookoLogoWhite from '~/../assets/images/glooko-logo-white.png';
import GlookoLogoBlue from '~/../assets/images/glooko-logo-blue.svg';
import GlucoseCgmCalculated from '~/../assets/images/glucose-cgm-calculated.png';
import GlucoseCgmCalculatedDots from '~/../assets/images/glucose-cgm-calculated-dots.svg';
import GlucoseMarkerHighNormal from '~/../assets/images/glucose-marker-high-normal.svg';
import GlucoseMarkerHighManual from '~/../assets/images/glucose-marker-high-manual.svg';
import GlucoseAverage from '~/../assets/images/glucose-average.svg';
import GlucosePercentiles1090 from '~/../assets/images/glucose-percentiles-1090.png';
import GlucosePercentiles2575 from '~/../assets/images/glucose-percentiles-2575.png';
import Percentile1090 from '~/../assets/images/percentile-1090.svg';
import Percentile2575 from '~/../assets/images/percentile-2575.svg';
import Median from '~/../assets/images/median.svg';
import LowestHighest from '~/../assets/images/lowest-highest.svg';
import InsulinBasal from '~/../assets/images/insulin-basal.svg';
import InsulinPremixed from '~/../assets/images/insulin-premixed.svg';
import OtherCarbs from '~/../assets/images/other-carbs.svg';
import CarbsMultiple from '~/../assets/images/carbs-multiple.svg';
import Exercise from '~/../assets/images/other-exercise.svg';
import GlucoseCgmAbove from '~/../assets/images/glucose-cgm-above.png';
import GlucoseCgmTarget from '~/../assets/images/glucose-cgm-target.png';
import GlucoseCgmBelow from '~/../assets/images/glucose-cgm-below.png';
import GlucoseAboveRangeC from '~/../assets/images/glucose-above-range-c.svg';
import GlucoseInTargetRangeC from '~/../assets/images/glucose-in-target-range-c.svg';
import GlucoseBelowRangeC from '~/../assets/images/glucose-below-range-c.svg';
import RightChevron from '~/../assets/images/rightChevron.svg';
import LeftChevron from '~/../assets/images/leftChevron.svg';
import appleHealthIcon from '~/../assets/images/exerciseIcons/appleHealthIcon.png';
import fitbitIcon from '~/../assets/images/exerciseIcons/fitbitIcon.png';
import glookoIcon from '~/../assets/images/exerciseIcons/glookoIcon.png';
import ihealthIcon from '~/../assets/images/exerciseIcons/ihealthIcon.png';
import jawboneUpIcon from '~/../assets/images/exerciseIcons/jawboneIcon.png';
import movesIcon from '~/../assets/images/exerciseIcons/movesIcon.png';
import runkeeperIcon from '~/../assets/images/exerciseIcons/runkeeperIcon.png';
import stravaIcon from '~/../assets/images/exerciseIcons/stravaIcon.png';
import withingsIcon from '~/../assets/images/exerciseIcons/withingsIcon.png';
import withingsNokiaIcon from '~/../assets/images/exerciseIcons/withingsNokiaIcon.png';
import checkmarkAppsBlue from '~/../assets/images/checkmarkAppsBlue.png';
import checkmarkAppsGrey from '~/../assets/images/checkmarkAppsGrey.png';
import pumpAlarm from '~/../assets/images/pump-alarm.svg';
import pumpAdvisoryAlert from '~/../assets/images/pump-advisory-alert.svg';
import canulaRefil from '~/../assets/images/canula-refil.svg';
import interruptedMarker from '~/../assets/images/interrupt_marker.svg';
import overrideAboveBolusMarker from '~/../assets/images/marker_above.svg';
import overrideBelowBolusMarker from '~/../assets/images/marker_below.svg';
import createPDFIcon from '~/../assets/images/create-pdf-icon.svg';
import InsulinOverride from '~/../assets/images/insulin-override.svg';
import PumpAlarms from '~/../assets/images/insulin-pump-alarms.png';
import ReserviorFill from '~/../assets/images/insulin-reservior-fill.png';
import InsulinSuspended from '~/../assets/images/insulin-suspended.svg';
import pumpAutomaticMode from '~/../assets/images/pump-automatic-mode.svg';
import pumpSleepMode from '~/../assets/images/pump-sleep-mode.svg';
import pumpManualMode from '~/../assets/images/pump-manual-mode.svg';
import pumpOp5LimitedMode from '~/../assets/images/pump-op5-limited-mode.svg';
import pumpOp5HypoProtectMode from '~/../assets/images/pump-op5-hypoprotect-mode.svg';
import pumpAutomaticLegend from '~/../assets/images/pump-automatic-legend.svg';
import pumpSleepLegend from '~/../assets/images/pump-sleep-legend.svg';
import pumpExerciseLegend from '~/../assets/images/pump-exercise-legend.svg';
import pumpManualLegend from '~/../assets/images/pump-manual-legend.svg';
import pumpHypoProtectLegend from '~/../assets/images/pump-hypoprotect-legend.svg';
import pumpLimitedLegend from '~/../assets/images/pump-limited-legend.svg';
import basalDeliveryAutomated from '~/../assets/images/basal-delivery-automated.svg';
import basalDeliveryAutomatedMax from '~/../assets/images/basal-delivery-automated-max.svg';
import basalDeliveryAutomatedPause from '~/../assets/images/basal-delivery-automated-pause.svg';
import helpIcon from '~/../assets/images/help-icon.svg';
import helpCircleIcon from '~/../assets/images/help-circle-icon.svg';
import moreInfo from '~/../assets/images/more-info.svg';
import legendHelp from '~/../assets/images/legend-help.svg';
import deviceOffsetClock from '~/../assets/images/device-offset-clock.svg';
import deviceOffsetClockSettings from '~/../assets/images/device-offset-clock-settings.svg';
import BeforeMealApple from '~/../assets/images/beforemeal.png';
import AfterMealApple from '~/../assets/images/aftermeal.png';
import ArrowRight from '~/../assets/images/arrow-right.png';
import xClose from '~/../assets/images/x-close.svg';
import omnipodLogoNew from '~/../assets/images/omnipod-logo-new.png';
import xIconWhite from '~/../assets/images/x-icon-white.svg';
import omnipodMobile from '~/../assets/images/omnipod-mobile.jpg';
import preloader from '~/../assets/images/preloader.gif';
import checkmark from '~/../assets/images/checkmark.svg';
import checkmarkSuccess from '~/../assets/images/checkmark-success.svg';
import syncIcon from '~/../assets/images/sync-icon.svg';
import syncIconBlue from '~/../assets/images/sync-icon-blue.svg';
import pdfBadEmoji from '~/../assets/images/patterns/pdf-bad-emoji.png';
import pdfGoodEmoji from '~/../assets/images/patterns/pdf-good-emoji.png';
import pdfNeutralEmoji from '~/../assets/images/patterns/pdf-neutral-emoji.png';
import pdfGreatEmoji from '~/../assets/images/patterns/pdf-great-emoji.png';
import pdfEmptyEmoji from '~/../assets/images/patterns/pdf-empty-emoji.png';
import badEmoji from '~/../assets/images/patterns/bad-emoji.png';
import bestDayEmpty from '~/../assets/images/patterns/best-day-empty.png';
import bestDay from '~/../assets/images/patterns/best-day.png';
import blueBackArrow from '~/../assets/images/blue_back_arrow.png';
import dotSelectedView from '~/../assets/images/patterns/dot-selected-view.png';
import dotUnselectedView from '~/../assets/images/patterns/dot-unselected-view.png';
import emptyEmoji from '~/../assets/images/patterns/empty-emoji.png';
import goodEmoji from '~/../assets/images/patterns/good-emoji.png';
import greatEmoji from '~/../assets/images/patterns/great-emoji.png';
import leftArrowSelected from '~/../assets/images/patterns/left-arrow-selected.png';
import leftArrowUnselected from '~/../assets/images/patterns/left-arrow-unselected.png';
import neutralEmoji from '~/../assets/images/patterns/neutral-emoji.png';
import rightArrowSelected from '~/../assets/images/patterns/right-arrow-selected.png';
import rightArrowUnselected from '~/../assets/images/patterns/right-arrow-unselected.png';
import TickMarkGreen from '~/../assets/images/tick-mark-green.png';
import todHyperEmpty from '~/../assets/images/patterns/tod-hyper-empty.png';
import todHyper from '~/../assets/images/patterns/tod-hyper.png';
import todHypoEmpty from '~/../assets/images/patterns/tod-hypo-empty.png';
import todHypo from '~/../assets/images/patterns/tod-hypo.png';
import PencilIcon from '~/../assets/images/pencil-icon.png';
import SuspendIcon from '~/../assets/images/suspend-icon.png';
import ResumeIcon from '~/../assets/images/resume-icon.png';
import poweredByDreamed from '~/../assets/images/AdvisorPro-Logo.svg';
import shareIcon from '~/../assets/images/share-icon.png';
import academyIllustration from '~/../assets/images/academy-illustration.png';
import academyLogo from '~/../assets/images/academy-wordmark.png';
import uploadIcon from '~/../assets/images/upload-icon.png';
import exportToCSV from '~/../assets/images/export-to-csv.svg';
import camapsEaseOffLegend from '~/../assets/images/camaps-ease-off-legend.svg';
import camapsBoostLegend from '~/../assets/images/camaps-boost-legend.svg';
import camapsAutoAttemptingLegend from '~/../assets/images/camaps-auto-attempting-legend.svg';
import ClockRewind from '~/../assets/images/clock-rewind.svg';
import infoGray from '~/../assets/images/info-gray.svg';
import warningGray from '~/../assets/images/warning-gray.svg';
import clipboard from '~/../assets/images/clipboard.svg';
import clipboardDisabled from '~/../assets/images/clipboard-disabled.svg';
import userShieldCircle from '~/../assets/images/user-shield-circle.svg';
import checkCircleSuccess from '~/../assets/images/check-circle-success.svg';
import statusIndicatorSuccess from '~/../assets/images/status-indicator-success.svg';
import proConnectCodeQuestion from '~/../assets/images/proconnect-code-question.svg';
import lock from '~/../assets/images/lock.png';
import cgmChangeEvent from '~/../assets/images/cgm-change-event.svg';

import camapsEaseOff from '~/../assets/images/camaps-ease-off.svg';
import camapsBoost from '~/../assets/images/camaps-boost.svg';

import invalidCircle from '~/../assets/images/invalid-circle.png';

// omnipod
import omnipodDevice from '~/../assets/images/omnipod/omnipod-device.png';
import dashDevice from '~/../assets/images/omnipod/dash-device.png';
import cloudConnect from '~/../assets/images/cloud-connect.svg';
import cloudConnected from '~/../assets/images/cloud-connected.svg';
// pdfWizard
import add from '~/../assets/images/pdfwizard/add.svg';
import calendar from '~/../assets/images/pdfwizard/calendar.svg';
import calendarSmall from '~/../assets/images/pdfwizard/calendar-small.svg';
import calendarStack from '~/../assets/images/pdfwizard/calendar-stack.svg';
import checkmarkPdf from '~/../assets/images/pdfwizard/checkmark.svg';
import connectedCheckmark from '~/../assets/images/connected_checkmark.png';
import clear from '~/../assets/images/pdfwizard/clear.svg';
import close from '~/../assets/images/pdfwizard/close.svg';
import dailyOverview from '~/../assets/images/pdfwizard/daily-overview.svg';
import dailyOverviewStack from '~/../assets/images/pdfwizard/daily-overview-stack.svg';
import weekView from '~/../assets/images/pdfwizard/week-view.png';
import weekViewStack from '~/../assets/images/pdfwizard/week-view-stack.svg';
import deselected1 from '~/../assets/images/pdfwizard/deselected-1.svg';
import deselected2 from '~/../assets/images/pdfwizard/deselected-2.svg';
import devices from '~/../assets/images/pdfwizard/devices.svg';
import devicesStack from '~/../assets/images/pdfwizard/devices-stack.svg';
import edit from '~/../assets/images/pdfwizard/edit.svg';
import editBlue from '~/../assets/images/pdfwizard/edit-blue.svg';
import hovering from '~/../assets/images/pdfwizard/hovering.svg';
import insights from '~/../assets/images/pdfwizard/insights.svg';
import insightsStack from '~/../assets/images/pdfwizard/insights-stack.svg';
import logbook from '~/../assets/images/pdfwizard/logbook.svg';
import logbookStack from '~/../assets/images/pdfwizard/logbook-stack.svg';
import overlay from '~/../assets/images/pdfwizard/overlay.svg';
import overlayStack from '~/../assets/images/pdfwizard/overlay-stack.svg';
import overview from '~/../assets/images/pdfwizard/overview.svg';
import overviewStack from '~/../assets/images/pdfwizard/overview-stack.svg';
import primary from '~/../assets/images/pdfwizard/primary.svg';
import secondary from '~/../assets/images/pdfwizard/secondary.svg';
import selected from '~/../assets/images/pdfwizard/selected.svg';
import selected1 from '~/../assets/images/pdfwizard/selected-1.svg';
import selected2 from '~/../assets/images/pdfwizard/selected-2.svg';
import stack from '~/../assets/images/pdfwizard/stack.svg';
import stackSelected from '~/../assets/images/pdfwizard/stack-selected.svg';
import summary from '~/../assets/images/pdfwizard/summary.svg';
import summaryStack from '~/../assets/images/pdfwizard/summary-stack.svg';
// summaryHistory
import oral from '~/../assets/images/history-medication-pill.svg';
import premixed from '~/../assets/images/history-medication-insulin-mixed.svg';
import custom from '~/../assets/images/history-medication-insulin-custom.svg';
import bolus from '~/../assets/images/history-medication-insulin-bolus.svg';
import basal from '~/../assets/images/history-medication-insulin-basal.svg';
import incomplete from '~/../assets/images/history-medication-insulin-incomplete.svg';
import primedBasal from '~/../assets/images/history-medication-insulin-primed-basal.svg';
import primedBolus from '~/../assets/images/history-medication-insulin-primed-bolus.svg';
import bolusCalculation from '~/../assets/images/history-bolus-calculation.svg';
import food from '~/../assets/images/history-food.svg';
import exercise from '~/../assets/images/history-exercise.svg';
import bolusItem from '~/../assets/images/history-bolus.svg';
import note from '~/../assets/images/history-notes.svg';
import readingAbove from '~/../assets/images/history-reading-above.svg';
import readingBelow from '~/../assets/images/history-reading-below.svg';
import readingInRange from '~/../assets/images/history-reading-inrange.svg';
import readingManual from '~/../assets/images/history-reading-manual.svg';
import biometric from '~/../assets/images/history-bloodpressure.svg';
import weight from '~/../assets/images/history-weight.svg';
import mealBefore from '~/../assets/images/history-meal-before.svg';
import mealAfter from '~/../assets/images/history-meal-after.svg';
import mealNone from '~/../assets/images/history-meal-none.svg';
import dobModalClose from '~/../assets/images/assignDevices/gray.svg';
import xCloseRow from '~/../assets/images/assignDevices/gray-row.svg';
import assignmentSuccess from '~/../assets/images/assignDevices/check.svg';
import radioSelected from '~/../assets/images/radio_selected.svg';
import HistoryAlert from '~/../assets/images/history-alert.svg';
import manualBloodPressure from '~/../assets/images/history-manual-bloodpressure.svg';
import historyA1c from '~/../assets/images/history-a1c.svg';
import Ketones from '~/../assets/images/history-ketones.svg';
import BreathKetones from '~/../assets/images/history-breath-ketones.svg';

// search
import searchIcon from '~/../assets/images/search.svg';
import dropdownArrow from '~/../assets/images/dropdownArrow.svg';
import menuArrow from '~/../assets/images/arrow-down-light.svg';
import clearButton from '~/../assets/images/clear.svg';
// poptracker
import plusIcon from '~/../assets/images/popTracker/plus-icon.svg';
import downGrayArrow from '~/../assets/images/popTracker/down-arrow-gray.svg';
import downDarkArrow from '~/../assets/images/popTracker/down-arrow-dark.svg';
import upGrayArrow from '~/../assets/images/popTracker/up-arrow-gray.svg';
import upDarkArrow from '~/../assets/images/popTracker/up-arrow-dark.svg';
import tagsEdit from '~/../assets/images/popTracker/edit.svg';
import tagsPlus from '~/../assets/images/popTracker/tagsPlus.svg';
import houseUpload from '~/../assets/images/popTracker/house-upload.svg';
import clinicUpload from '~/../assets/images/popTracker/clinic-upload.svg';

// sync
import syncLinkArrowActive from '~/../assets/images/sync-link-arrow-blue.svg';
import syncLinkArrowHover from '~/../assets/images/sync-link-arrow-grey.svg';
import appIconAll from '~/../assets/images/app-icon-all.png';
import glookoUploader from '~/../assets/images/glooko-uploader.png';
import omnipodUploader from '~/../assets/images/omnipod-icon.svg';
import syncIllustration from '~/../assets/images/sync-illustration.png';

// general
import sectionExpandArrow from '~/../assets/images/filter_down_arrow.svg';
import sectionCollapseArrow from '~/../assets/images/filter_up_arrow.svg';

// Apps
import mellitusHealthLogo from '~/../assets/images/appLogos/mellitus_health_logo.svg';

// Activation
import personalizedAnalysis from '~/../assets/images/activationPage/personalized-analysis.svg';
import stayConnected from '~/../assets/images/activationPage/stay-connected.svg';
import easilyTrackUSImage from '~/../assets/images/activationPage/easily-track.svg';
import easilyTrackNonUSImage from '~/../assets/images/activationPage/easily-track-nonUS.svg';
import desktopScreenIcon from '~/../assets/images/activationPage/desktop_screen_icon.svg';
import appStoreIcon from '~/../assets/images/activationPage/app-store-icon.png';
import googlePlayStoreIcon from '~/../assets/images/activationPage/google-play-icon.png';
import settingMoreInfo from '~/../assets/images/setting-more-info.svg';
import scanCode from '~/../assets/images/activationPage/scan-code.png';
import downloadApp from '~/../assets/images/activationPage/download-app.png';
import startUsingGlooko from '~/../assets/images/activationPage/start-using-glooko.png';

// Partner Integrations
import integrationLogoAbbot from '~/../assets/images/integration-logo-abbot.png';
import integrationLogoAbbotCsv from '~/../assets/images/integration-logo-abbot-csv.png';
import integrationLogoGlucomenDay from '~/../assets/images/integration-logo-glucomen-day.png';
import integrationLogoYpsomedGlobal from '~/../assets/images/integration-logo-ypsomed-global.svg';
import integrationLogoYpsomedCan from '~/../assets/images/integration-logo-ypsomed-can.svg';
import integrationLogoLifescan from '~/../assets/images/integration-logo-lifescan.svg';
import integrationSpinner from '~/../assets/images/integration-spinner.svg';
import integrationTickSuccess from '~/../assets/images/integration-tick-success.svg';
import integrationTickFailure from '~/../assets/images/integration-tick-failure.svg';
import closeButton from '~/../assets/images/close-icon-large.png';
import integrationLogoMedtronic from '~/../assets/images/integration-logo-medtronic.png';

// login
import mobilePhone from '~/../assets/images/iphone.svg';
import emailNotification from '~/../assets/images/mail-notification.svg';

// insulet
import ipgPerson from '~/../assets/images/ipg_person.svg';
import ipgBackArrow from '~/../assets/images/back-arrow-white.svg';

// pregnancy
import pregnancyIcon from '~/../assets/images/pregnancy-icon.svg';

// BloodPressure
import book from '~/../assets/images/book.png';

// Manual check-in
import manualCheckIn from '~/../assets/images/manual-checkin.svg';

// Hedia
import hediaLogo from '~/../assets/images/hedia_logo.svg';

// icons
import CaretRight from '~/../assets/images/icons/caret-right.svg';
import pumpExerciseMode from '~/../assets/images/pump-exercise-mode.svg';
import checked from '~/../assets/images/icons/checked.svg';
import unChecked from '~/../assets/images/icons/unchecked.svg';

// Partners
import source_apple from '~/../assets/images/history-source-apple.svg';
import source_fitbit from '~/../assets/images/history-source-fitbit.svg';
import source_garmin from '~/../assets/images/history-source-garmin.svg';
import source_movesapp from '~/../assets/images/history-source-moves.svg';
import source_runkeeper from '~/../assets/images/history-source-runkeeper.svg';
import source_strava from '~/../assets/images/history-source-strava.svg';
import source_withings from '~/../assets/images/history-source-withings.svg';
import source_dexcom_cloud from '~/../assets/images/history-source-dexcom-cloud.png';
import source_mylife_cloud from '~/../assets/images/history-source-mylife-cloud.png';
import source_mylife_cloud_can from '~/../assets/images/history-source-mylife-cloud-can.png';

// settings (profile)
import glookoAb from '~/../assets/images/glooko-ab.png';
import glookoFactory from '~/../assets/images/glooko-factory.png';
import glookoGlobal from '~/../assets/images/glooko-global.png';
import glookoIfu from '~/../assets/images/glooko-ifu-Icon.png';
import glookoInc from '~/../assets/images/glooko-inc.png';
import glookoLot from '~/../assets/images/glooko-lot.png';
import glookoMd from '~/../assets/images/glooko-md.png';
import glookoMed from '~/../assets/images/glooko-med.png';
import glookoRef from '~/../assets/images/glooko-ref.png';
import glookoUdi from '~/../assets/images/glooko-udi.png';
import iso from '~/../assets/images/iso.png';

const source_healthgraph = source_runkeeper;

const Images = {
  AlertRed,
  PenAlarm,
  academyIllustration,
  academyLogo,
  GlucoseBeforeMeal,
  GlucoseAfterMeal,
  GlucoseAboveRange,
  GlucoseInTargetRange,
  GlucoseBelowRange,
  GlucoseAboveRangeC,
  GlucoseInTargetRangeC,
  GlucoseBelowRangeC,
  GlucoseBgReadings,
  GlucoseCgmReadings,
  CgmMonday,
  CgmTuesday,
  CgmWednesday,
  CgmThursday,
  CgmFriday,
  CgmSaturday,
  CgmSunday,
  GlucosePumpBg,
  GlucoseCalibrations,
  Carbs,
  deviceOffsetClock,
  deviceOffsetClockSettings,
  GlucoseAbove400Pump,
  GlucoseAbove400,
  InsulinManual,
  InsulinBolus,
  InsulinBolusSuggested,
  InsulinExtBolus,
  InsulinAutomaticBolus,
  InsulinScheduledBasal,
  InsulinUnusedScheduledBasal,
  InsulinBasalModulation,
  InsulinCustom,
  InsulinTempBasal,
  InsulinSuspend,
  InsulinCalculatedBolus,
  Ketones,
  BreathKetones,
  LgsPlgs,
  InsulinOverride,
  PumpAlarms,
  ReserviorFill,
  pumpAutomaticMode,
  pumpSleepMode,
  pumpExerciseMode,
  pumpManualMode,
  pumpOp5LimitedMode,
  pumpOp5HypoProtectMode,
  pumpAutomaticLegend,
  pumpSleepLegend,
  pumpExerciseLegend,
  pumpManualLegend,
  pumpHypoProtectLegend,
  pumpLimitedLegend,
  basalDeliveryAutomated,
  basalDeliveryAutomatedMax,
  basalDeliveryAutomatedPause,
  helpIcon,
  helpCircleIcon,
  legendHelp,
  moreInfo,
  InsulinSuspended,
  SetChange,
  GlookoLogoBlue,
  GlookoLogoWhite,
  GlucoseCgmCalculated,
  GlucoseCgmCalculatedDots,
  GlucoseMarkerHighNormal,
  GlucoseMarkerHighManual,
  GlucoseAverage,
  GlucosePercentiles2575,
  GlucosePercentiles1090,
  Percentile1090,
  Percentile2575,
  Median,
  LowestHighest,
  GlucoseCgmAbove,
  GlucoseCgmTarget,
  GlucoseCgmBelow,
  InsulinBasal,
  InsulinPremixed,
  OtherCarbs,
  CarbsMultiple,
  Exercise,
  ArrowRight,
  TickMarkGreen,
  poweredByDreamed,
  shareIcon,
  radioSelected,
  uploadIcon,
  infoGray,
  warningGray,
  clipboard,
  clipboardDisabled,
  userShieldCircle,
  checkCircleSuccess,
  statusIndicatorSuccess,
  proConnectCodeQuestion,
  lock,
  cgmChangeEvent,
  // navigation
  RightChevron,
  LeftChevron,
  blueBackArrow,
  checkmarkAppsBlue,
  checkmarkAppsGrey,
  connectedCheckmark,
  createPDFIcon,
  PencilIcon,
  SuspendIcon,
  ResumeIcon,
  // exerciseIcons
  appleHealthIcon,
  fitbitIcon,
  glookoIcon,
  ihealthIcon,
  jawboneUpIcon,
  movesIcon,
  runkeeperIcon,
  stravaIcon,
  withingsIcon,
  withingsNokiaIcon,
  // calendar graph icons
  pumpAlarm,
  pumpAdvisoryAlert,
  canulaRefil,
  interruptedMarker,
  overrideAboveBolusMarker,
  overrideBelowBolusMarker,
  // tooltips
  BeforeMealApple,
  AfterMealApple,
  // modals
  xClose,
  omnipodLogoNew,
  xIconWhite,
  omnipodMobile,
  preloader,
  checkmark,
  checkmarkSuccess,
  syncIcon,
  syncIconBlue,
  // patterns
  pdfBadEmoji,
  pdfGoodEmoji,
  pdfNeutralEmoji,
  pdfGreatEmoji,
  pdfEmptyEmoji,
  badEmoji,
  bestDay,
  bestDayEmpty,
  dotSelectedView,
  dotUnselectedView,
  emptyEmoji,
  goodEmoji,
  greatEmoji,
  leftArrowSelected,
  leftArrowUnselected,
  neutralEmoji,
  rightArrowSelected,
  rightArrowUnselected,
  todHyper,
  todHyperEmpty,
  todHypo,
  todHypoEmpty,
  // pdfwizard
  add,
  calendar,
  calendarSmall,
  calendarStack,
  checkmarkPdf,
  clear,
  close,
  dailyOverview,
  dailyOverviewStack,
  weekView,
  weekViewStack,
  deselected1,
  deselected2,
  devices,
  devicesStack,
  edit,
  editBlue,
  hovering,
  insights,
  insightsStack,
  logbook,
  logbookStack,
  overlay,
  overlayStack,
  overview,
  overviewStack,
  primary,
  secondary,
  selected,
  selected1,
  selected2,
  stack,
  stackSelected,
  summary,
  summaryStack,
  ClockRewind,
  // omnipod
  omnipodDevice,
  dashDevice,
  cloudConnect,
  cloudConnected,
  // summaryHistory
  oral,
  bolus,
  basal,
  incomplete,
  primedBasal,
  primedBolus,
  bolusCalculation,
  premixed,
  custom,
  food,
  exercise,
  note,
  bolusItem,
  readingAbove,
  readingBelow,
  readingInRange,
  readingManual,
  biometric,
  weight,
  mealBefore,
  mealAfter,
  mealNone,
  manualBloodPressure,
  historyA1c,
  HistoryAlert,
  source_apple,
  source_fitbit,
  source_garmin,
  source_movesapp,
  source_runkeeper,
  source_healthgraph,
  source_strava,
  source_withings,
  source_dexcom_cloud,
  source_mylife_cloud,
  source_mylife_cloud_can,

  // assignDevices
  dobModalClose,
  assignmentSuccess,
  xCloseRow,
  // search
  searchIcon,
  dropdownArrow,
  menuArrow,
  clearButton,
  // popTracker
  plusIcon,
  downGrayArrow,
  downDarkArrow,
  upGrayArrow,
  upDarkArrow,
  tagsEdit,
  houseUpload,
  clinicUpload,
  // sync
  appIconAll,
  glookoUploader,
  omnipodUploader,
  syncLinkArrowActive,
  syncLinkArrowHover,
  syncIllustration,
  // general
  sectionCollapseArrow,
  sectionExpandArrow,
  // Apps Logos
  mellitusHealthLogo,
  tagsPlus,

  // Activation
  personalizedAnalysis,
  stayConnected,
  easilyTrackUSImage,
  easilyTrackNonUSImage,
  desktopScreenIcon,
  appStoreIcon,
  googlePlayStoreIcon,
  settingMoreInfo,
  scanCode,
  downloadApp,
  startUsingGlooko,

  // Partner Integrations
  integrationLogoAbbot,
  integrationLogoAbbotCsv,
  integrationLogoGlucomenDay,
  integrationLogoYpsomedGlobal,
  integrationLogoYpsomedCan,
  integrationLogoLifescan,
  integrationSpinner,
  integrationTickSuccess,
  integrationTickFailure,
  closeButton,
  integrationLogoMedtronic,

  // login
  mobilePhone,
  emailNotification,
  // Export To CSV
  exportToCSV,

  // insulet
  ipgPerson,
  ipgBackArrow,

  // pregnancy
  pregnancyIcon,

  camapsEaseOffLegend,
  camapsBoostLegend,
  camapsAutoAttemptingLegend,
  camapsEaseOff,
  camapsBoost,

  // bloodpressure
  book,
  invalidCircle,

  manualCheckIn,

  // hedia
  hediaLogo,

  // icons
  CaretRight,
  checked,
  unChecked,

  // settings (profile)
  glookoAb,
  glookoFactory,
  glookoGlobal,
  glookoIfu,
  glookoInc,
  glookoLot,
  glookoMd,
  glookoMed,
  glookoRef,
  glookoUdi,
  iso,
};

export default disallowUndefinedProperties(Images);
const ImagesAll = Images;
export {
  ImagesAll,
};
