import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { map, last, find } from 'lodash';
import classNames from 'classnames';
import { updateTimeframeThunk, pageChangeThunk } from '~/redux/thunks/page';
import { ONE_DAY, ONE_WEEK, NINETY_DAYS, ALL_TIMEFRAMES, NINETY_DAYS_BREADCRUMBS } from '~/bundles/shared/constants/time';
import { PAGE_TO_PAGES_INDEX, PAGE_CALENDAR } from '~/bundles/shared/constants/pages';
import GraphsHelper from '~/redux/modules/graphs/GraphsHelper';
import { translate } from 'bundles/shared/components/WithTranslate/WithTranslate';
import OverviewBreadcrumbsItem from './OverviewBreadcrumbsItem';
import Style from './OverviewBreadcrumbs.scss';

const mapStateToProps = (state) => ({
  availableTimeFrames: state.page.availableTimeFrames,
  timeFrameIndex: state.page.timeFrameIndex,
  calendarTimeFrameReturnEnabled: state.page.calendarTimeFrameReturn.enabled,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { updateTimeframeThunk, pageChangeThunk }, dispatch,
  ),
});

@translate('OverviewBreadcrumbs')
class OverviewBreadcrumbs extends React.Component {
  constructor(props) {
    super(props);
    this.generateItems = this.generateItems.bind(this);
    this.restoreCalendarPage = this.restoreCalendarPage.bind(this);
  }

  restoreCalendarPage() {
    this.props.actions.pageChangeThunk(PAGE_TO_PAGES_INDEX[PAGE_CALENDAR], false);
  }

  generateList() {
    const { timeFrameIndex, availableTimeFrames } = this.props;
    const selectedTimeframe = availableTimeFrames[timeFrameIndex];

    switch (selectedTimeframe.value) {
      case ONE_DAY: {
        return [
          find(ALL_TIMEFRAMES, (frame) => frame.value === NINETY_DAYS),
          find(ALL_TIMEFRAMES, (frame) => frame.value === ONE_WEEK),
          find(ALL_TIMEFRAMES, (frame) => frame.value === ONE_DAY),
        ];
      }
      case ONE_WEEK: {
        return [
          find(ALL_TIMEFRAMES, (frame) => frame.value === NINETY_DAYS),
          find(ALL_TIMEFRAMES, (frame) => frame.value === ONE_WEEK),
        ];
      }
      case NINETY_DAYS: {
        return [{ value: NINETY_DAYS_BREADCRUMBS }];
      }
      default: {
        return [selectedTimeframe];
      }
    }
  }

  generateItems() {
    const visibleTimeframes = this.generateList();
    const current = last(visibleTimeframes);

    return (
      map(visibleTimeframes,
        (timeFrame) => (
          <OverviewBreadcrumbsItem
            key={timeFrame.value}
            label={GraphsHelper.retrieveLabel(timeFrame.value)}
            lastItem={timeFrame === current}
            clickable={timeFrame !== current}
            id={timeFrame.value}
            {
            ...(
              timeFrame !== current ? {
                onClick: this.props.actions.updateTimeframeThunk,
              } : {}
            )
            }
          />
        ),
      )
    );
  }

  generateCalendarLink() {
    const { t } = this.props;
    return (
      <OverviewBreadcrumbsItem
        id={-1}
        label={t('calendar')}
        onClick={this.restoreCalendarPage}
        lastItem
        clickable
      />
    );
  }

  render() {
    const { calendarTimeFrameReturnEnabled } = this.props;
    const singleItem = this.generateList().length === 1;

    return (
      <div
        className={classNames({
          [Style.OverviewBreadcrumbs]: true,
          [Style.OverviewBreadcrumbs__single]: singleItem,
        })}
      >
        {calendarTimeFrameReturnEnabled ? this.generateCalendarLink() : this.generateItems()}
      </div>
    );
  }
}

OverviewBreadcrumbs.propTypes = {
  availableTimeFrames: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
  })).isRequired,
  timeFrameIndex: PropTypes.number.isRequired,
  actions: PropTypes.shape({
    updateTimeframeThunk: PropTypes.func.isRequired,
    pageChangeThunk: PropTypes.func.isRequired,
  }).isRequired,
  calendarTimeFrameReturnEnabled: PropTypes.bool.isRequired,
};

OverviewBreadcrumbs.defaultProps = {
  calendarTimeFrameReturnEnabled: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(OverviewBreadcrumbs);
