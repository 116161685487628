import appboy from '@braze/web-sdk';
import * as ENV from '~/bundles/shared/constants/env';
import envConfig from '../../config/GlobalEnvConfig';

const Braze = () => ({
  init: () => {
    const nonceMeta = document.querySelector('meta[name="csp-nonce"]');
    const nonce = nonceMeta ? nonceMeta.content : '';

    if (!envConfig.brazeWebSDKApiKey || !envConfig.brazeWebSDKApiKey.length) {
      return null;
    }
    // initialize the SDK
    appboy.initialize(envConfig.brazeWebSDKApiKey, {
      baseUrl: envConfig.brazeWebSDKUrl, // https://www.braze.com/docs/api/basics/#endpoints
      allowUserSuppliedJavascript: true,
      enableLogging: ENV.debug(),
      contentSecurityNonce: nonce,
    });

    // optionally show all In-App Messages without custom handling
    appboy.display.automaticallyShowNewInAppMessages();

    // start (or continue) a session
    appboy.openSession();

    return appboy;
  },
});

export default Braze;
