import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { updatePdfWizardTimeframeThunk } from '~/redux/thunks/page';
import UserHelper from '~/redux/modules/users/UserHelper';
import DateRangeSelect from '../DateRangeSelect/DateRangeSelect';

const mapStateToProps = (state) => ({
  timeFrameIndex: state.page.timeFrameIndexPdfWizard,
  endDate: UserHelper.lastSyncTimestamp(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ updateTimeframe: updatePdfWizardTimeframeThunk }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DateRangeSelect);
