import React from 'react';
import PropTypes from 'prop-types';
import { range } from 'lodash';
import deepMerge from 'deepmerge';
import classNames from 'classnames';
import { FETCH_STATUS_SUCCESSFUL, FETCH_STATUS_FAILED, FETCH_STATUS_NOT_CALLED } from '~/bundles/shared/constants/graphs';
import { SECONDS_IN_HOUR } from 'bundles/shared/constants/time';
import Readings from '~/services/Readings'; import Colors from '~/../assets/styles/export/colors';
import BGGraph from '~/bundles/graphs/components/Graphs/BGGraph/BGGraph';
import ExerciseGraph from '~/bundles/graphs/components/Graphs/ExerciseGraph/ExerciseGraph';
import BolusGraph from '~/bundles/graphs/components/Graphs/BolusGraph/BolusGraph';
import BasalGraph from '~/bundles/graphs/components/Graphs/BasalGraph/BasalGraph';
import PumpModesGraph from '~/bundles/graphs/components/Graphs/PumpModesGraph/PumpModesGraph';
import BasalBarGraph from '~/bundles/graphs/components/Graphs/BasalBarGraph/BasalBarGraph';
import CarbsGraph from '~/bundles/graphs/components/Graphs/CarbsGraph/CarbsGraph';
import WithLoadingState from '~/bundles/shared/components/WithLoadingState/WithLoadingState';
import OverviewAxisAbove from '~/bundles/graphs/components/OverviewTabContainer/OverviewGraph/OverviewAxisAbove/OverviewAxisAbove';
import Style from './OverviewGraphHoursPresenter.scss';
import { GRAPH_CONFIG } from '~/bundles/shared/components/graphs/hoursGraphConfig';

const THREE_HOURS = SECONDS_IN_HOUR * 3;

export const OverviewGraphHoursPresenter = (props) => {
  const {
    heightOverrideFactor,
    weekView,
    weekViewWeb,
    meterUnits,
    updateSeries,
    startTimestamp,
    endTimestamp,
    dataFetched: fetchStatus,
    graphsWindowWidth: windowWidth,
    dataLabelsEnabled,
    exerciseSeries,
    exerciseAxisEnd,
    hasExercise,
    hasPump,
    basalAxisEndIndex,
    graphConfig,
  } = props;

  const configOverride = {
    reactProps: { weekView, meterUnits },
    xAxis: {
      plotLines: range(
        Math.floor((props.startTimestamp / THREE_HOURS) * THREE_HOURS) + THREE_HOURS,
        (Math.ceil(props.endTimestamp / THREE_HOURS) * THREE_HOURS) + 0.1,
        THREE_HOURS,
      ).map((elem) => ({
        color: Colors.borderGrey,
        width: 1,
        dashStyle: 'solid',
        value: elem,
        zIndex: 2,
      })),
    },
    tooltip: {
      useHTML: true,
      followPointer: false,
      borderColor: Colors.backgroundGreyTransparent13,
      backgroundColor: Colors.white,
      padding: 0,
      zIndex: 1,
    },
  };
  const commonProps = {
    startTimestamp,
    endTimestamp,
    fetchStatus,
    windowWidth,
    dataLabelsEnabled,
    configOverride,
  };

  const graphConfigOpts = GRAPH_CONFIG[graphConfig];
  const heightConfig = graphConfigOpts?.heights;
  const pdf = graphConfigOpts?.pdf;

  return (
    <div className={classNames(
      Style.OverviewGraphHoursPresenter,
      {
        [Style.weekView]: weekView,
        [Style.weekViewWeb]: weekViewWeb,
      })}
    >
      <div
        className={classNames(
          Style.borderBottom, { [Style.weekView]: weekView },
        )}
      >
        <OverviewAxisAbove weekView={weekView} pdf={pdf} height={heightConfig?.header} />
      </div>

      <div className={Style.borderBottom}>
        <BGGraph
          {...commonProps}
          series={props.bgGraphSeries}
          windowHeight={heightConfig ? heightConfig.glucose : 240 - (500 * heightOverrideFactor)}
          yAxisMax={props.bgGraphAxisEndIndex}
          showBeforeMaxLine
          weekView={weekView}
        />
      </div>

      <div className={Style.borderBottom}>
        <CarbsGraph
          {...commonProps}
          series={props.carbsSeries}
          windowHeight={heightConfig ? heightConfig.carbs : 50 - (80 * heightOverrideFactor)}
          weekView={weekView}
          graphConfig={graphConfig}
        />
      </div>

      <div className={Style.borderBottom}>
        <BolusGraph
          {...commonProps}
          series={(!hasPump && props.hasInsulinData) ? props.insulinManualSeries : props.insulinManualAndPumpSeries}
          windowHeight={heightConfig ? heightConfig.insulin : 72 - (80 * heightOverrideFactor)}
          yAxisMax={props.bolusAxisEndIndex}
        />
      </div>

      {hasPump && Number.isInteger(basalAxisEndIndex) && (
        <div className={Style.borderBottom}>
          <BasalGraph
            {...commonProps}
            series={props.basalSeries}
            windowHeight={heightConfig ? heightConfig.basal : 72 - (80 * heightOverrideFactor)}
            yAxisMax={basalAxisEndIndex}
            updateSeries={updateSeries}
            weekView={weekView}
          />
        </div>
      )}

      {props.hasControlIQ && (
        <div className={Style.borderBottom}>
          <PumpModesGraph
            {...commonProps}
            series={props.pumpModeSeries}
            windowHeight={heightConfig ? heightConfig.system : 39}
            yAxisMax={1.02}
            updateSeries={updateSeries}
          />
        </div>
      )}

      {props.hasBasalIqData && (
        <div className={Style.borderBottom}>
          <PumpModesGraph
            {...commonProps}
            series={props.pumpModeBasaliqSeries}
            windowHeight={heightConfig ? heightConfig.system : 39}
            yAxisMax={1.02}
            updateSeries={updateSeries}
          />
        </div>
      )}

      {props.hasMedtronicClosedLoopData && (
        <div className={Style.borderBottom}>
          <PumpModesGraph
            {...commonProps}
            series={props.pumpModeGenericSeries}
            windowHeight={heightConfig ? heightConfig.system : 39}
            yAxisMax={1.02}
            updateSeries={updateSeries}
          />
        </div>
      )}

      {props.hasOmnipod5 && (
        <div className={Style.borderBottom}>
          <PumpModesGraph
            {...commonProps}
            series={props.pumpModeOp5Series}
            windowHeight={heightConfig ? heightConfig.system : 39}
            yAxisMax={1.02}
            updateSeries={updateSeries}
          />
        </div>
      )}

      {props.hasOmnipod5 && (
        <div className={Style.borderBottom}>
          <BasalBarGraph
            {...commonProps}
            series={props.basalBarSeries}
            windowHeight={heightConfig ? heightConfig.system : 20 - Math.round(10 * heightOverrideFactor)}
            yAxisMax={1}
            updateSeries={updateSeries}
          />
        </div>
      )}

      {hasExercise ? (
        <div className={Style.borderBottom}>
          <ExerciseGraph
            {...commonProps}
            series={exerciseSeries}
            windowHeight={72 - (100 * heightOverrideFactor)}
            yAxisMax={exerciseAxisEnd}
            fetchStatus={props.exerciseFetchStatus}
            configOverride={
              deepMerge.all([{}, configOverride, {
                plotOptions: {
                  series: {
                    pointWidth: 10,
                  },
                },
                reactProps: { overviewLastGraph: true },
              }])
            }
          />
        </div>
      ) : null}

      {props.hasCamapsData && (
        <div className={Style.borderBottom}>
          <PumpModesGraph
            {...commonProps}
            series={props.pumpModeCamApsSeries}
            windowHeight={heightConfig ? heightConfig.system : 39}
            yAxisMax={1.02}
            updateSeries={updateSeries}
          />
        </div>
      )}
    </div>
  );
};

OverviewGraphHoursPresenter.propTypes = {
  startTimestamp: PropTypes.number.isRequired,
  endTimestamp: PropTypes.number.isRequired,
  meterUnits: PropTypes.string.isRequired,
  graphsWindowWidth: PropTypes.number.isRequired,
  bgGraphSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  bolusSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  basalSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  pumpModeSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  pumpModeBasaliqSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  pumpModeOp5Series: PropTypes.arrayOf(PropTypes.object).isRequired,
  pumpModeCamApsSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  pumpModeGenericSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  basalBarSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  carbsSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  exerciseSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  bgGraphAxisEndIndex: PropTypes.number.isRequired,
  bolusAxisEndIndex: PropTypes.number.isRequired,
  exerciseAxisEnd: PropTypes.number,
  dataFetched: PropTypes.string.isRequired,
  exerciseFetchStatus: PropTypes.string,
  updateSeries: PropTypes.func.isRequired,
  chartOverride: PropTypes.object,
  heightOverrideFactor: PropTypes.number,
  weekView: PropTypes.bool,
  weekViewWeb: PropTypes.bool,
  hasOmnipod5: PropTypes.bool,
  hasMedtronicClosedLoopData: PropTypes.bool,
  hasBasalIqData: PropTypes.bool,
  hasCamapsData: PropTypes.bool,
  hasExercise: PropTypes.bool.isRequired,
  dataLabelsEnabled: PropTypes.bool,
  graphConfig: PropTypes.string,
};

OverviewGraphHoursPresenter.defaultProps = {
  meterUnits: Readings.MGDL,
  dataFetched: FETCH_STATUS_NOT_CALLED,
  exerciseFetchStatus: FETCH_STATUS_NOT_CALLED,
  bolusAxisEndIndex: 10,
  bgGraphAxisEndIndex: 400,
  updateSeries: () => null,
  chartOverride: {},
  heightOverrideFactor: 0,
  weekView: false,
  weekViewWeb: false,
  hasOmnipod5: false,
  hasMedtronicClosedLoopData: false,
  hasBasalIqData: false,
  hasCamapsData: false,
};

export default WithLoadingState(
  OverviewGraphHoursPresenter,
  (props) => {
    const dataFetched = props.dataFetched === FETCH_STATUS_SUCCESSFUL || props.dataFetched === FETCH_STATUS_FAILED;
    const exerciseFetchStatus = props.exerciseFetchStatus === FETCH_STATUS_SUCCESSFUL || props.exerciseFetchStatus === FETCH_STATUS_FAILED;

    return props.hasExercise ? !(dataFetched && exerciseFetchStatus) : !dataFetched;
  },
);
