import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { updateDialogVisibility } from '~/redux/modules/omnipod/omnipod';
import ProviderGroupSiteHelper from '~/redux/modules/providerGroupSite/ProviderGroupSiteHelper';
import { OMNIPOD_WEB_UPLOAD_FEATURE } from '~/bundles/shared/constants/providerGroupSite';
import IpgRestrictedPresenter from './IpgRestrictedPresenter';

const mapStateToProps = (state) => (
  {
    ipgNoInsuletPump: state.providerGroupSite.subscriptionModel.ipgNoInsuletPump,
    gssNoDevice: state.providerGroupSite.subscriptionModel.gssNoDevice,
    omnipodWebUploadEnabled: ProviderGroupSiteHelper.hasSubscriptionFeature(
      state, OMNIPOD_WEB_UPLOAD_FEATURE,
    ),
    isShowingOmnipodUpload: state.omnipod.dialogVisible,
  }
);

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { updateDialogVisibility },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(IpgRestrictedPresenter);
