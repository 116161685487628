import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import UserHelper from '../../../../../redux/modules/users/UserHelper';

import Styles from './LinkToDevicesTab.scss';

const mapStateToProps = (state: State) => ({
  isPatient: UserHelper.currentUserIsPatient(state),
});

const LinkToDevicesTab = (props: ReturnType<typeof mapStateToProps>) => {
  const { isPatient } = props;
  const { t } = useTranslation('LinkToDevicesTab');

  if (!isPatient) {
    return null;
  }

  return (
    <p>
      <Trans
        components={{
          deviceslink: <a className={Styles.linkToDevicesTab} href="/devices" />,
        }}
        i18nKey="text"
        t={t}
      />
    </p>
  );
};

export default connect(mapStateToProps)(LinkToDevicesTab);
