import { Dialog, DialogContent } from '@glooko/common-ui';
import { useSelector } from 'react-redux';
import UserHelper from 'redux/modules/users/UserHelper';
import { clearPopInsightsFiltersFromLocalStorage } from 'utils/localStorageUtils';
import { useEffect } from 'react';
import { useIdleTimer } from './hooks';
import WithTranslate from '../WithTranslate/WithTranslate';
import Styles from './IdleTimerPopover.scss';

const IdleTimerPopover = (props: WithTranslationFunction) => {
  const { t } = props;
  const isPatient = useSelector(UserHelper.currentUserIsPatient);
  const minutesTillIdle = isPatient ? 20 : 60;
  const { isIdle } = useIdleTimer(minutesTillIdle * 60);

  useEffect(() => {
    if (isIdle) {
      clearPopInsightsFiltersFromLocalStorage();
    }
  }, [isIdle]);

  return (isIdle) ? (
    <div className={Styles.blurAll}>
      <div className={Styles.overlay}>
        <Dialog className={Styles.idleDialog} open showCloseButton={false}>
          <DialogContent>
            <div className={Styles.content}>
              <div>{t('message')}</div>

              <div className={Styles.close}>
                <a className={Styles.link} href="/">{t('close')}</a>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  ) : null;
};

export default WithTranslate('IdleTimerPopover')(IdleTimerPopover);
