import React, { Component } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Button, Dialog, DialogActions, DialogContent, DialogHeader } from '@glooko/common-ui';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import ReasonForChange from '~/bundles/shared/components/ReasonForChange/ReasonForChange.jsx';
import { EMAIL_REGEX } from '~/bundles/shared/constants/regex';
import { updatePatientEmailForInviteThunk } from '~/redux/thunks/users';

import styles from './NewPatientModal.scss';

const mapStateToProps = (state) => ({
  isReasonForChange: state.providerGroupSite.reasonForChange,
  reasonForChangeRequired: state.providerGroupSite.icpaConfig.reasonForChange === 'required',
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ updatePatientEmailForInviteThunk }, dispatch),
});

class NewPatientModal extends Component {
  static propTypes = {
    patient: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    isInviteModalOpen: PropTypes.bool.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    onInviteClick: PropTypes.func.isRequired,
    newEmailIsValid: PropTypes.bool.isRequired,
    newEmailInUse: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      updatePatientEmailForInviteThunk: PropTypes.func.isRequired,
    }).isRequired,
    isReasonForChange: PropTypes.bool,
    reasonForChangeRequired: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    isReasonForChange: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      newEmailIsValid: this.props.newEmailIsValid,
      newEmailInUse: this.props.newEmailInUse,
      newEmail: this.props.patient.patientEmail,
      isReasonModalOpen: false,
      reasonForUpdate: '',
    };

    this.onEmailInputChange = this.onEmailInputChange.bind(this);
    this.onClickInvite = this.onClickInvite.bind(this);
  }

  onEmailInputChange(event) {
    this.setState({
      newEmail: event.target.value,
      newEmailIsValid: true,
      newEmailInUse: false,
    });
  }

  onClickInvite() {
    this.emailValidation(this.state.newEmail);
    if (this.state.newEmailIsValid && EMAIL_REGEX.test(this.state.newEmail)) {
      if (this.props.isReasonForChange &&
        this.props.patient.patientEmail !== this.state.newEmail) {
        this.setState({ isReasonModalOpen: true });
      } else {
        this.updatePatientEmailWithReason();
      }
    } else {
      this.setState({
        newEmail: this.state.newEmail,
        newEmailIsValid: false,
        newEmailInUse: false,
      });
    }
  }

  emailValidation(email) {
    this.setState({
      newEmailIsValid: EMAIL_REGEX.test(email),
      newEmailInUse: false,
    });
  }

  updatePatientEmailWithReason = () => {
    const { isReasonForChange } = this.props;
    const { reasonForUpdate } = this.state;
    const parms = {
      patient: this.props.patient.patientId,
      email: this.state.newEmail,
      ...(isReasonForChange && { reasonForChange: reasonForUpdate }),
    };
    this.props.actions.updatePatientEmailForInviteThunk(parms)
      .then(() => {
        this.props.onInviteClick(this.state.newEmail);
        this.setState({ isReasonModalOpen: false });
      })
      .catch((response) => {
        if (response.message === 'email_already_defined') {
          this.setState({
            newEmailIsValid: true,
            newEmailInUse: true,
            isReasonModalOpen: false,
          });
        }
      });
  }

  handleCloseReasonModal = () => {
    this.setState({ isReasonModalOpen: false });
  }

  handleReasonChange = (event) => {
    this.setState({ reasonForUpdate: event.target.value });
  }

  renderReasonForChangeModal = () => {
    const { t } = this.props;
    const { isReasonModalOpen } = this.state;
    const { isReasonForChange, reasonForChangeRequired } = this.props;
    if (isReasonForChange) {
      return (
        <ReasonForChange
          isReasonModalOpen={isReasonModalOpen}
          reasonForChangeRequired={reasonForChangeRequired}
          updatePatientWithReason={this.updatePatientEmailWithReason}
          handleCloseReasonModal={this.handleCloseReasonModal}
          handleReasonChange={this.handleReasonChange}
          changedDataFields={[{ id: 'email', label: t('email') }]}
        />
      );
    }
    return null;
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <Dialog size="medium" open={this.props.isInviteModalOpen} onClose={() => this.props.onCancelClick()} dataAttributes={{ testid: 'new-patient' }}>
          <DialogHeader dataAttributes={{ testid: 'new-patient' }}><h2>{t('sendInvite')}</h2></DialogHeader>
          <DialogContent dataAttributes={{ testid: 'new-patient' }}>
            <div className={styles.modal_modal}>
              <div className={styles.modal_body}>
                <p> {t('confirmPatientEmail')} </p>
                <div id="new-patient-email">
                  <label
                    htmlFor="patientEmailID"
                    className={classnames({
                      [styles.warning]: !this.state.newEmailIsValid,
                    })}
                  >
                    {t('patientEmail')}
                  </label>
                  <input
                    id="patientEmailID"
                    type="email"
                    className={classnames({
                      [styles.warningBorder]: !this.state.newEmailIsValid ||
                        this.state.newEmailInUse,
                    })}
                    value={this.state.newEmail}
                    onChange={(event) => { this.onEmailInputChange(event); }}
                  />
                  <label
                    htmlFor="patientEmailID"
                    className={classnames({
                      [styles.show]: !this.state.newEmailIsValid,
                      [styles.error_email]: true,
                    })}
                  > {t('InvalidEmail')}
                  </label>
                  <label
                    htmlFor="patientEmailID"
                    className={classnames({
                      [styles.show]: this.state.newEmailInUse,
                      [styles.error_email]: true,
                    })}
                  > {t('alreadyPresent')}
                  </label>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions dataAttributes={{ testid: 'new-patient' }}>
            <Button variation="secondary" onClick={() => this.props.onCancelClick()} dataAttributes={{ testid: 'new-patient-cancel' }}>{t('cancel')}</Button>
            <Button onClick={() => this.onClickInvite()} dataAttributes={{ testid: 'new-patient-invite' }}>{t('invite')}</Button>
          </DialogActions>
        </Dialog>
        {this.renderReasonForChangeModal()}
      </div>
    );
  }
}

const connectedInviteModal = connect(mapStateToProps, mapDispatchToProps)(NewPatientModal);
const translatedAddEmailDialog = translate('NewPatientModal')(connectedInviteModal);

export default (translatedAddEmailDialog);
