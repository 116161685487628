import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Images from 'utils/ImageStore';
import { useReferenceDeviceList } from 'bundles/shared/hooks/useReferenceDeviceList';
import { usePapaParse } from 'react-papaparse';
import ButtonStyle from '../../../../settings/components/PartnerIntegrationsCard/PartnerIntegrationsCard.scss';
import FileUploaderPopup from '../../FileUploaderPopup/FileUploaderPopup';
import UserHelper from '../../../../../redux/modules/users/UserHelper';
import { FileInfo } from '../../FileUploaderPopup/FileUploaderPopupConfirm';
import Style from './AbbottCSVButton.scss';
import { trackSelectedAppsDevices } from '../../../../../services/eventLogging';

export const useFileParser = async (file: File, setData: (data: FileInfo) => void) => {
  const { readString } = usePapaParse();

  readString<string[]>(await file.text(), {
    header: false,
    preview: 2,
    complete: (result) => {
      const isExportedByPatient = result.data[1].length > 2;
      setData({
        name: isExportedByPatient ? result.data[0][4] : result.data[1][0],
      });
    },
  });
};

export interface AbbottCSVButtonProps {
  children?: never;
  type?: 'button' | 'link';
}

const mapStateToProps = (state: State) => ({
  countryOfResidence: UserHelper.displayUser(state).countryOfResidence,
  hasAbbottCsv: UserHelper.displayUser(state).hasAbbottCsv,
  isPatient: UserHelper.currentUserIsPatient(state),
});

const AbbottCSVButton = (props: AbbottCSVButtonProps & ReturnType<typeof mapStateToProps>) => {
  const {
    countryOfResidence,
    hasAbbottCsv,
    isPatient,
    type = 'button',
  } = props;

  const [showPopup, setShowPopup] = useState(false);

  const { t } = useTranslation('AbbottCSVButton');

  const { data, error } = useReferenceDeviceList();

  const openPopup = () => {
    trackSelectedAppsDevices('Abbott Data');
    setShowPopup(true);
  };

  const closePopup = () => setShowPopup(false);

  const device = data?.devices.find((device) => device.id === 'ABBOTT_LIBREVIEW_CSV');
  const showButton = device?.supportedCountries.includes(countryOfResidence);

  if (!showButton || error || type === 'link' && !hasAbbottCsv) {
    return null;
  }

  const patientSupportArticleId = '4531230605843';
  const hcpSupportArticleId = '35407885568915';

  return (
    <>
      {showPopup ? (
        <FileUploaderPopup
          acceptedExtensions={['.csv']}
          closePopup={closePopup}
          extractorName="LibreviewCSV"
          fileParser={useFileParser}
          providerName="LibreView"
          supportArticleId={isPatient ? patientSupportArticleId : hcpSupportArticleId}
        />
      ) : null}

      {type === 'button' ? (
        <button
          className={ButtonStyle.button}
          onClick={openPopup}
          type="button"
        >
          <div className={ButtonStyle.buttonContent}>
            <img alt="integration" className={ButtonStyle.buttonImage} src={Images.integrationLogoAbbotCsv} />

            <div className={ButtonStyle.buttonName}>
              <span>{t('uploadAbbottDataButton')}</span>
            </div>
          </div>
        </button>
      ) : null}

      {type === 'link' ? (
        <div>
          <button
            className={Style.abbottCsvUpload}
            onClick={openPopup}
            type="button"
          >
            <img alt="Abbott CSV upload" src={Images.uploadIcon} />
            {t('uploadAbbottDataButton')}
          </button>
        </div>
      ) : null}
    </>
  );
};

export default connect<ReturnType<typeof mapStateToProps>, unknown, AbbottCSVButtonProps, State>(mapStateToProps)(AbbottCSVButton);
