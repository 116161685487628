/* eslint-disable react/jsx-handler-names */
import { Button, Dialog, DialogActions, DialogContent, DialogHeader, useDialogShow } from '@glooko/common-ui';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import UserHelper from '../../../../../redux/modules/users/UserHelper';
import { trackSelectedAppsDevices } from '../../../../../services/eventLogging';

import image from './insulet-dash-icon.png';
import Style from '../PartnerIntegrationsCard.scss';

const mapStateToProps = (state: State) => ({
  isPatient: UserHelper.currentUserIsPatient(state),
  isInUSA: UserHelper.currentUserCountry(state) === 'usa',
  isConnected: UserHelper.currentUser(state).insuletDashCloudConnected,
  isConnectionPending: UserHelper.currentUser(state).insuletConnectionPending,
});

const InsuletDashCloudButton = (props: ReturnType<typeof mapStateToProps>) => {
  const { isPatient, isInUSA, isConnected, isConnectionPending } = props;
  const { t } = useTranslation('InsuletDashCloudButton');

  const pendingDialog = useDialogShow();
  const connectedDialog = useDialogShow();

  if (!(isPatient && isInUSA)) {
    return null;
  }

  const onConnectButtonClick = () => {
    trackSelectedAppsDevices('DASH™ Cloud');
    window.location.href = '/integrations/insulet/get_started';
  };

  const onPendingButtonClick = () => {
    trackSelectedAppsDevices('DASH™ Cloud');
    pendingDialog.openDialog();
  };

  const onConnectedClick = () => {
    trackSelectedAppsDevices('DASH™ Cloud');
    connectedDialog.openDialog();
  };

  const onDisconnectClick = () => {
    window.location.href = 'https://support.glooko.com/hc/en-us/articles/360006571758';
  };

  return (
    <>
      {!isConnectionPending && !isConnected ? (
        <button
          className={Style.button}
          onClick={onConnectButtonClick}
          type="button"
        >
          <div className={Style.buttonContent}>
            <img alt="DASH™ Cloud" className={Style.buttonImage} src={image} />

            <div className={Style.buttonName}>
              {t('connectToDashCloud')}
            </div>
          </div>
        </button>
      ) : null}

      {isConnectionPending ? (
        <button
          className={Style.button}
          onClick={onPendingButtonClick}
          type="button"
        >
          <div className={Style.buttonContent}>
            <img alt="DASH™ Cloud" className={Style.buttonImage} src={image} />

            <div className={Style.buttonName}>
              {t('dashCloud')} <strong>{t('pending')}</strong>
            </div>
          </div>
        </button>
      ) : null}

      {isConnected ? (
        <button
          className={Style.button}
          onClick={onConnectedClick}
          type="button"
        >
          <div className={Style.buttonContent}>
            <img alt="DASH™ Cloud" className={Style.buttonImage} src={image} />

            <div className={Style.buttonName}>
              {t('dashCloud')} <strong>{t('connected')}</strong>
            </div>
          </div>
        </button>
      ) : null}

      <Dialog onClose={pendingDialog.closeDialog} open={pendingDialog.isOpen}>
        <DialogHeader><h2>{t('connectionPending')}</h2></DialogHeader>
        <DialogContent>{t('omnipodIsConnecting')}</DialogContent>

        <DialogActions>
          <Button onClick={pendingDialog.closeDialog}>{t('ok')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog onClose={connectedDialog.closeDialog} open={connectedDialog.isOpen}>
        <DialogHeader><h2>{t('connectedToDashCloud')}</h2></DialogHeader>
        <DialogContent>{t('glookoIsConnected')}</DialogContent>

        <DialogActions>
          <Button onClick={connectedDialog.closeDialog} variation="secondary">{t('cancel')}</Button>
          <Button onClick={onDisconnectClick}>{t('disconnect')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default connect(mapStateToProps)(InsuletDashCloudButton);
