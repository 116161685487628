// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".PumpEventTooltip-module_container{-webkit-box-sizing:border-box;box-sizing:border-box;background:var(--surface-backgroundPrimary)}.PumpEventTooltip-module_header{padding:16px;border-bottom:1px solid var(--border-primary)}.PumpEventTooltip-module_date{display:inline-block;width:100%;font-weight:700;color:var(--text-primary);font-size:12px;text-align:start}.PumpEventTooltip-module_row{color:var(--text-secondary);padding:16px}", "",{"version":3,"sources":["PumpEventTooltip.module.scss"],"names":[],"mappings":"AAAA,mCACE,6BAAA,CAAA,qBAAA,CACA,2CAAA,CAGF,gCACE,YAAA,CACA,6CAAA,CAGF,8BACE,oBAAA,CACA,UAAA,CACA,eAAA,CACA,yBAAA,CACA,cAAA,CACA,gBAAA,CAGF,6BACE,2BAAA,CACA,YAAA","file":"PumpEventTooltip.module.scss","sourcesContent":[".container {\n  box-sizing: border-box;\n  background: var(--surface-backgroundPrimary);\n}\n\n.header {\n  padding: 16px;\n  border-bottom: 1px solid var(--border-primary);\n}\n\n.date {\n  display: inline-block;\n  width: 100%;\n  font-weight: 700;\n  color: var(--text-primary);\n  font-size: 12px;\n  text-align: start;\n}\n\n.row {\n  color: var(--text-secondary);\n  padding: 16px;\n}\n"]}]);
// Exports
exports.locals = {
	"container": "PumpEventTooltip-module_container",
	"header": "PumpEventTooltip-module_header",
	"date": "PumpEventTooltip-module_date",
	"row": "PumpEventTooltip-module_row"
};
module.exports = exports;
