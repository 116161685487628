import { ToggleSwitch } from '@glooko/common-ui';
import Style from './ConsentToggle.scss';

interface ConsentToggleProps {
  agreement: string;
  hasAgreed: boolean;
  note?: string;
  title: string;
  onChange(choice: boolean): void;
}

const ConsentToggle = (props: ConsentToggleProps) => {
  const { agreement, hasAgreed, title, note, onChange } = props;

  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const choiceValue = e.target.checked;
    onChange(choiceValue);
  };

  return (
    <div className={Style.ConsentToggle}>
      <div className={Style.toggleBar}>
        <div className={Style.toggleDescription}>
          <div className={Style.title}>{title}</div>

          {note !== undefined &&
            <div className={Style.note}>{note}</div>}
        </div>

        <div className={Style.toggleContainer}>
          <ToggleSwitch
            checked={hasAgreed}
            dataAttributes={{ testid: `${agreement}-consent-toggle` }}
            onChange={handleToggle}
          />
        </div>
      </div>
    </div>
  );
};

export default ConsentToggle;
