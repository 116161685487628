import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18next from '~/utils/I18n/I18n';
import { range, map, last, reverse } from 'lodash';
import Sizes from '~/../assets/styles/export/sizes';
import { numberFormatterOneDecimal } from '~/utils/i18nFormats';
import TimeUtils from '~/utils/TimeUtils/TimeUtils';
import Style from './AxisPresenter.scss';

const AxisPresenter = (props) => {
  const {
    startIndex,
    endIndex,
    tickStep,
    windowHeight,
    windowWidth,
    additionalPaddingTop,
    weekView,
    carbsWeekView,
    title,
    subtitle,
    children,
    hideAxis,
    showAxisOverflow,
    unit,
  } = props;

  const formatTick = (tick) => {
    if (tick !== 0 && tick % 500 === 0) {
      return `${tick / 1000}k`;
    }
    return numberFormatterOneDecimal(tick);
  };

  const renderAxis = () => {
    const tickPositions = range(startIndex, endIndex + 0.1, tickStep);
    const numberOfTicks = tickPositions.length;
    const pxPerTick = windowHeight / (endIndex - startIndex);
    const itemHeight = 10; // 18 with 8 negative bottom margin
    const lastTickPosition = last(tickPositions);
    const axisPaddingTop = Math.max((pxPerTick * (endIndex - lastTickPosition)) -
      (itemHeight / 2), 0);
    const paddingTop = axisPaddingTop + additionalPaddingTop;
    const paddingBottom = (
      (windowHeight - paddingTop - (Sizes.bgGraphPaddingTopBottom / 2)) -
      (numberOfTicks * itemHeight)
    ) / (numberOfTicks - 1);
    const ticks = map(reverse(tickPositions), (tick, index) => (
      <div key={`${tick}_${index}`} style={{ itemHeight, paddingBottom }}>
        {unit === 'duration' ? TimeUtils.formatDuration(tick, true) : formatTick(tick)}
      </div>),
    );

    return (<div className={Style.AxisPresenter_axis_container} style={{ paddingTop }}>{ticks}</div>);
  };

  const style = {
    height: windowHeight - Sizes.axisPresenterBorder - Sizes.axisPresenterPadding,
    marginRight: (-1) * windowWidth,
  };

  return (
    <div
      className={classNames(Style.AxisPresenter, {
        [Style.weekViewAxis]: weekView,
      })}
      style={{ width: windowWidth, height: windowHeight }}
    >
      <div
        className={classNames(Style.AxisPresenter_title, {
          [Style.weekView]: weekView,
        })}
        style={style}
      >
        <div className={classNames(Style.title, {
          [Style.frenchActive]: i18next.language === 'fr-CA',
          [Style.portugueseActive]: i18next.language === 'pt-PT',
          [Style.carbsWeek]: carbsWeekView,
        })}
        >
          {title}
        </div>
        <div className={classNames(Style.subtitle, {
          [Style.weekViewSubtitle]: weekView,
          [Style.carbsWeek]: carbsWeekView,
        })}
        >
          {subtitle}
        </div>
        {children}
      </div>
      <div className={classNames(Style.AxisPresenter_axis, {
        [Style.hiddenAxis]: hideAxis,
        [Style.showOverflow]: showAxisOverflow,
      })}
      >
        {hideAxis ? '' : renderAxis()}
      </div>
    </div>
  );
};

AxisPresenter.propTypes = {
  startIndex: PropTypes.number.isRequired,
  endIndex: PropTypes.number.isRequired,
  tickStep: PropTypes.number.isRequired,
  windowWidth: PropTypes.number.isRequired,
  windowHeight: PropTypes.number,
  title: PropTypes.string.isRequired,
  hideAxis: PropTypes.bool,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  additionalPaddingTop: PropTypes.number.isRequired,
  unit: PropTypes.string,
  weekView: PropTypes.bool,
  carbsWeekView: PropTypes.bool,
  showAxisOverflow: PropTypes.bool,
};

AxisPresenter.defaultProps = {
  windowHeight: 150,
  children: [],
  subtitle: '',
  hideAxis: false,
  startIndex: 0,
  endIndex: 10,
  tickStep: 1,
  additionalPaddingTop: 0,
  unit: '',
  weekView: false,
  carbsWeekView: false,
  showAxisOverflow: false,
};

export default AxisPresenter;
