import ExecuteApiCall from './apiClient';

export function postOfAgeConsentUpdate(consents) {
   return ExecuteApiCall('post', 'api/v2/consents/of_age_consent_update', consents);
}

export function getGlookoConsents(patient) {
   return ExecuteApiCall('get', 'api/v3/consents', { patient });
}

export function updateGlookoConsent(patient, agreement, choice) {
   return ExecuteApiCall('post', 'api/v3/consents', { patient, agreement, choice });
}
