import { createLogger } from 'redux-logger';
import { configureStore } from '@reduxjs/toolkit';
import combinedReducers from 'redux/modules/combinedReducers';
import graphSelectionsListener from '../listeners/graphSelectionsListener';

if (!window.glookoStore) {
  window.glookoStore = configureStore({
    reducer: combinedReducers,
    // eslint-disable-next-line no-process-env
    middleware: (getDefaultMiddleware) => (process.env.NODE_ENV === 'development' ? getDefaultMiddleware().concat(createLogger()) : getDefaultMiddleware()),
    // eslint-disable-next-line no-process-env
    devTools: process.env.NODE_ENV === 'development',
  });

  window.glookoStore.subscribe(() => graphSelectionsListener(window.glookoStore.getState.bind(window.glookoStore)));
}

export default window.glookoStore;
