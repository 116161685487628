import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { updatePdfWizardVisibility } from '~/redux/modules/page/page';
import PdfWizard from './PdfWizard';

const mapStateToProps = (state) => ({
  visible: state.page.pdfWizardDisplayed,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { updatePdfWizardVisibility }, dispatch,
  ),
});

class PopTrackerPdfWizard extends React.Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    visible: false,
  };

  hidePdfWizard = () => {
    this.props.actions.updatePdfWizardVisibility(false);
  }

  render = () => {
    if (!this.props.visible) {
      return null;
    }
    return (
      <PdfWizard
        visible={this.props.visible}
        visibilityCallback={this.hidePdfWizard}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopTrackerPdfWizard);
