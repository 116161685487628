import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import { Alert } from '@glooko/common-ui';
import { useTranslation } from 'react-i18next';
import { fetchDevicesAndSettingsThunk } from '../../../../../redux/thunks/deviceSettings';
import colors from '../../../../../../assets/styles/export/colors';

const RecallBannerContainer = ({ shouldFetch = true, showBanner, actions }) => {
  const { t } = useTranslation('RecallBannerContainer');

  useEffect(() => {
    if (shouldFetch) actions.fetchDevicesAndSettingsThunk();
  }, []);

  if (!showBanner) return null;

  const alertProps = {
    style: {
      border: `1px solid ${colors.borderGrey}`,
      backgroundColor: colors.white,
      marginTop: '0px',
      marginBottom: '28px',
    },
    severity: 'error',
    title: t('title'),
  };

  return <Alert {...alertProps} dataAttributes={{ testid: 'recall-banner' }}>{t('description')}</Alert>;
};

const mapStateToProps = (state) => {
  const startDate = state.page.startDate;
  const devices = state.devicesAndSettings.devices;

  const showBanner = !!devices.find(
    (device) => device.referenceDeviceId === 'NOVO_NORDISK_DIALOQ' &&
      device.recalledTimestamp >= startDate,
  );

  return { showBanner };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetchDevicesAndSettingsThunk,
    },
    dispatch,
  ),
});

RecallBannerContainer.propTypes = {
  shouldFetch: PropTypes.bool,
  actions: PropTypes.shape({
    fetchDevicesAndSettingsThunk: PropTypes.func.isRequired,
  }).isRequired,
};

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(RecallBannerContainer);
export default ConnectedComponent;
