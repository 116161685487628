export const bgGraphSeriesName = [
  'bgLow', 'bgHigh', 'bgNormal',
  'bgLowManual', 'bgHighManual', 'bgNormalManual',
  'bgAbove400', 'bgAbove400Manual', 'bgTargets',
  'cgmLow', 'cgmHigh', 'cgmNormal',
  'cgmCalibrationLow', 'cgmCalibrationNormal', 'cgmCalibrationHigh',
  'cgmSensorChange',
];

export const bgGraphSeriesNameProcessed = [
  'bgLow', 'bgHigh', 'bgNormal',
  'bgLowManual', 'bgHighManual', 'bgNormalManual',
  'bgAbove400', 'bgAbove400Manual', 'bgTargets12',
  'cgmLow', 'cgmHigh', 'cgmNormal',
  'cgmCalibrationLow', 'cgmCalibrationNormal', 'cgmCalibrationHigh',
  'cgmSensorChange',
];

export const bolusSeriesName = [
  'deliveredBolus', 'extendedBolusStep', 'injectionBolus', 'gkInsulin',
  'interruptedBolus', 'overrideBelowBolus', 'overrideAboveBolus',
  'suggestedBolus', 'automaticBolus', 'calculatedBolus',
];

export const basalSeriesName = [
  'scheduledBasal', 'basalModulation', 'temporaryBasal', 'suspendBasal',
  'reservoirChange', 'setSiteChange', 'pumpAlarm', 'pumpAdvisoryAlert', 'lgsPlgs',
  'basalLabels', 'unusedScheduledBasal',
];

export const basalSeriesNameProcessed = [
  'scheduledBasal12', 'basalModulation', 'temporaryBasal12', 'suspendBasal',
  'reservoirChange', 'setSiteChange', 'pumpAlarm', 'pumpAdvisoryAlert', 'lgsPlgs',
  'basalLabels12', 'unusedScheduledBasal12',
];

export const pumpModeSeriesName = [
  'pumpControliqAutomaticMode', 'pumpControliqExerciseMode', 'pumpControliqSleepMode',
  'pumpControliqManualMode',
];

export const pumpModeSeriesNameProcessed = [
  'pumpControliqAutomaticMode', 'pumpControliqExerciseMode', 'pumpControliqSleepMode',
  'pumpControliqManualMode',
];

export const pumpModeOp5SeriesName = [
  'pumpOp5ManualMode', 'pumpOp5AutomaticMode', 'pumpOp5LimitedMode', 'pumpOp5HypoprotectMode',
];

export const pumpModeOp5SeriesNameProcessed = [
  'pumpOp5ManualMode', 'pumpOp5AutomaticMode', 'pumpOp5LimitedMode', 'pumpOp5HypoprotectMode',
];

export const pumpModeGenericSeriesName = [
  'pumpGenericAutomaticMode', 'pumpGenericManualMode',
];

export const pumpModeGenericSeriesNameProcessed = [
  'pumpGenericAutomaticMode', 'pumpGenericManualMode',
];

export const pumpModeBasaliqSeriesName = [
  'pumpBasaliqAutomaticMode', 'pumpBasaliqManualMode',
];

export const pumpModeBasaliqSeriesNameProcessed = [
  'pumpBasaliqAutomaticMode', 'pumpBasaliqManualMode',
];

export const basalBarSeriesName = [
  'basalBarAutomated', 'basalBarAutomatedMax', 'basalBarAutomatedSuspend',
];

export const basalBarSeriesNameProcessed = [
  'basalBarAutomated', 'basalBarAutomatedMax', 'basalBarAutomatedSuspend',
];

export const carbsSeriesName = ['carbAll'];

export const carbsSeriesNameProcessed = ['carbAll12'];

export const exerciseHoursSeriesNameProcessed = ['exerciseColumnHours'];

export const bolusSeriesNameProcessed = [
  'deliveredBolus12', 'extendedBolusStep12', 'injectionBolus12', 'gkInsulin12',
  'interruptedBolus12', 'overrideBelowBolus12', 'overrideAboveBolus12',
  'suggestedBolus12', 'automaticBolus12', 'calculatedBolus12',
];

export const insulinSeriesName = [
  'deliveredBolus', 'extendedBolusStep', 'injectionBolus', 'interruptedBolus',
  'overrideBelowBolus', 'overrideAboveBolus', 'suggestedBolus',
  'gkInsulin', 'gkInsulinBolus', 'gkInsulinBasal', 'gkInsulinPremixed', 'gkInsulinOther',
];

export const insulinPumpSeriesNameProcessed = [
  'deliveredBolus12', 'extendedBolusStep12', 'injectionBolus12', 'interruptedBolus12',
  'overrideBelowBolus12', 'overrideAboveBolus12', 'suggestedBolus12', 'automaticBolus12',
];

export const insulinManualSeriesNameProcessed = [
  'gkInsulinBolus12', 'gkInsulinBasal12', 'gkInsulinPremixed12', 'gkInsulinOther12',
  'calculatedBolus12',
];

export const insulinManualAndPumpSeriesNameProcessed = [
  ...insulinPumpSeriesNameProcessed,
  ...insulinManualSeriesNameProcessed,
];

export const pumpModeCamApsSeriesName = [
  'pumpCamapsAutomaticMode',
  'pumpCamapsManualMode',
  'pumpCamapsBoostMode',
  'pumpCamapsEaseOffMode',
  'pumpCamapsNoCgmMode',
  'pumpCamapsNoPumpConnectivityMode',
  'pumpCamapsPumpDeliverySuspendedMode',
  'pumpCamapsExtendedBolusNotAllowedMode',
  'pumpCamapsDailyTotalInsulinExceededMode',
  'pumpCamapsBluetoothTurnedOffMode',
  'pumpCamapsUnableToProceedMode',
  'pumpCamapsDepoweredMode',
];

export const pumpModeCamApsSeriesNameProcessed = [
  'pumpCamapsAutomaticMode',
  'pumpCamapsManualMode',
  'pumpCamapsBoostMode',
  'pumpCamapsEaseOffMode',
  'pumpCamapsNoCgmMode',
  'pumpCamapsNoPumpConnectivityMode',
  'pumpCamapsPumpDeliverySuspendedMode',
  'pumpCamapsExtendedBolusNotAllowedMode',
  'pumpCamapsDailyTotalInsulinExceededMode',
  'pumpCamapsBluetoothTurnedOffMode',
  'pumpCamapsUnableToProceedMode',
  'pumpCamapsDepoweredMode',
];

export const allPumpModesSeriesName = [
  ...pumpModeSeriesName,
  ...pumpModeOp5SeriesName,
  ...pumpModeGenericSeriesName,
  ...pumpModeBasaliqSeriesName,
  ...pumpModeCamApsSeriesName,
];
