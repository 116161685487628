/* eslint-disable lines-between-class-members */
class EnvConfig {
  constructor() {
    if (EnvConfig.instance) {
      throw new Error('New instance cannot be created!');
    }
    EnvConfig.instance = this;
  }

  popInsightsUrl = window.env['popInsightsUrl'];
  apiUrl = window.env['apiUrl'];
  auditApiUrl = window.env['auditApiUrl'];
  gladUrl = window.env['gladUrl'];
  regionalJoinUrl = window.env['regionalJoinUrl'];
  glookoUploaderOsxUrl = window.env['glookoUploaderOsxUrl'];
  mellitusHealthUrl = window.env['mellitusHealthUrl'];
  academyUrl = window.env['academyUrl'];
  popInsightsApiUrl = window.env['popInsightsApiUrl'];
  brazeWebSDKUrl = window.env['brazeWebSDKUrl'];
  brazeWebSDKApiKey = window.env['brazeWebSDKApiKey'];
  graphsBetaUrl = window.env['graphsBetaUrl'];
}

const envConfig = new EnvConfig();
export default envConfig;
