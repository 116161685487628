import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { fetchUserEncryptedData } from '~/redux/thunks/users';
import AcademyPresenter from '../AcademyPresenter/AcademyPresenter';

function mapStateToProps(state) {
  const { userType, id, userEncryptedData } = state.users.currentUsers.currentUser;
  return {
    userType,
    userId: id,
    userEncryptedData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchUserEncryptedData,
  }, dispatch);
}

const AcademyContainer = (props) => {
  const { userId, userType, userEncryptedData, fetchUserEncryptedData } = props;

  useEffect(() => {
    fetchUserEncryptedData({ professional: userId });

    const interval = setInterval(() => {
      fetchUserEncryptedData({ professional: userId });
    }, 300000);
    return () => clearInterval(interval);
  }, [userId]);

  return (
    <AcademyPresenter
      userType={userType}
      userEncryptedData={userEncryptedData}
    />
  );
};

AcademyContainer.propTypes = {
  userType: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userEncryptedData: PropTypes.shape({
    message: PropTypes.string.isRequired,
    nonce: PropTypes.string.isRequired,
  }).isRequired,
  fetchUserEncryptedData: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AcademyContainer);
