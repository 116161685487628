import { Card } from '@glooko/common-ui';
import { useTranslation } from 'react-i18next';
import EversenseButton from './EversenseButton/EversenseButton';
import AbbottCSVButton from '../../../shared/components/AbbottCsv/AbbottCSVButton/AbbottCSVButton';
import GlookoUploaderButton from './GlookoUploaderButton/GlookoUploaderButton';
import IglucoseButton from './IglucoseButton/IglucoseButton';
import InsuletDashCloudButton from './InsuletDashCloudButton/InsuletDashCloudButton';
import LinkToDevicesTab from './LinkToDevicesTab/LinkToDevicesTab';
import MedtronicCsvButton from './MedtronicCsvButton/MedtronicCsvButton';
import OmnipodPdmButton from './OmnipodPdmButton/OmnipodPdmButton';

import Style from './PartnerIntegrationsCard.scss';

const PartnerIntegrationsCard = () => {
  const { t } = useTranslation('PartnerIntegrationsCard');

  return (
    <Card title={t('appsAndDevices')}>
      <div className={Style.integrationButtons}>
        <GlookoUploaderButton />
        <OmnipodPdmButton />
        <InsuletDashCloudButton />
        <IglucoseButton />
        <EversenseButton />
        <AbbottCSVButton />
        <MedtronicCsvButton />
      </div>

      <LinkToDevicesTab />
    </Card>
  );
};

export default PartnerIntegrationsCard;
