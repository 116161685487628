import React from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { keyFromParams, cleanUp } from '~/redux/modules/graphs/graphs';
import {
  graphsFetchOverviewThunk,
  graphsFetchExerciseOverviewThunk,
  graphFetchAverageAndPercentilesThunk,
  graphsUpdateOverviewDaysLegendConfigThunk,
} from '~/redux/thunks/graphs';
import GraphsHelper from '~/redux/modules/graphs/GraphsHelper';
import PageHelper from '~/redux/modules/page/PageHelper';
import Readings from '~/services/Readings';
import {
  CALENDAR_ONE_DAY_WIDTH_WEB,
  CALENDAR_AXIS_WIDTH_WEB,
  CALENDAR_AXIS_WIDTH_PDF,
  CALENDAR_ONE_DAY_WIDTH_PDF,
} from '~/bundles/shared/constants/pages/calendar';
import { GRAPH_NAME_EXERCISE } from '~/bundles/shared/constants/exercise';
import {
  FETCH_STATUS_NOT_CALLED,
  FETCH_STATUS_SUCCESSFUL,
  FETCH_STATUS_FAILED,
  FETCH_STATUSES,
} from '~/bundles/shared/constants/graphs';
import UserHelper from '~/redux/modules/users/UserHelper';
import {
  bgGraphSeriesName,
  bgGraphAverageName,
  bgGraphPercentilesName,
  cgmGraphSeriesName,
  cgmGraphAverageName,
  cgmGraphPercentilesName,
  insulinSeriesName,
  insulinTotalsName,
  carbsSeriesName,
  exerciseSeriesNameProcessed,
  exerciseSeriesNamePdfProcessed,
  stepsSeriesNameProcessed,
  stepsSeriesNamePdfProcessed,
} from '../../../../../bundles/shared/constants/overviewDaysSeries';
import {
  overviewDaysGraphSeries,
  overviewDaysLegendSeries,
  retrieveFetchStatusAndSeries,
} from '../../../../../bundles/shared/components/graphs/series/overviewDaysSeries';
import OverviewGraphPresenter from '../OverviewGraphPresenter/OverviewGraphPresenter';
import overviewLegendConfig from './overviewLegendConfig';

export const GRAPH_NAME = 'Overview';
export const GRAPH_NAME_AVERAGE = 'OverviewAverage';

const mapStateToProps = (state, ownProps) => {
  const { startTimestamp, endTimestamp } = ownProps;
  const { inPDF } = state.pdf;
  const meterUnits = UserHelper.displayUser(state).preference.meterUnits;
  const { status: fetchStatus, series } = retrieveFetchStatusAndSeries(
    state, startTimestamp, endTimestamp, ownProps.graphTypeName,
  );
  const { status: fetchStatusExercise, series: seriesExercise } = retrieveFetchStatusAndSeries(
    state, startTimestamp, endTimestamp, GRAPH_NAME_EXERCISE,
  );
  const { status: fetchStatusAverage, series: seriesAverage } = retrieveFetchStatusAndSeries(
    state, startTimestamp, endTimestamp, ownProps.graphTypeNameAverage,
  );

  const { average, percentiles } = state.graphs;

  const timeFrameConfig = PageHelper.currentTimeframe(state);
  const oneDayWidth = inPDF ? CALENDAR_ONE_DAY_WIDTH_PDF : CALENDAR_ONE_DAY_WIDTH_WEB;
  const axisWidth = inPDF ? CALENDAR_AXIS_WIDTH_PDF : CALENDAR_AXIS_WIDTH_WEB;

  const exerciseSeriesNamesForFilter = inPDF ? exerciseSeriesNamePdfProcessed : exerciseSeriesNameProcessed;
  const stepsSeriesNamesForFilter = inPDF ? stepsSeriesNamePdfProcessed : stepsSeriesNameProcessed;

  const hasInsulinPump = GraphsHelper.showInsulinPumpGraphOnOverview(
    state, startTimestamp, endTimestamp,
  );

  const hasInsulinManual = GraphsHelper.showInsulinManualGraphOnOverview(
    state, startTimestamp, endTimestamp,
  );

  const seriesFinishedFetching = [FETCH_STATUS_SUCCESSFUL, FETCH_STATUS_FAILED];
  const hasSeries = (seriesToCheck, seriesName) => GraphsHelper.hasNonZeroDataInSeries(seriesToCheck, seriesName);
  const hasSteps = hasSeries(seriesExercise, stepsSeriesNamesForFilter);
  const hasExercise = hasSeries(seriesExercise, exerciseSeriesNamesForFilter);

  const legendConfigured = ownProps.configured || state.graphs.overviewGraphDaysLegendConfig.configured;
  let legendConfig = ownProps.legendConfig || state.graphs.overviewGraphDaysLegendConfig.data;
  if (
    seriesFinishedFetching.includes(fetchStatus) &&
    seriesFinishedFetching.includes(fetchStatusExercise) &&
    seriesFinishedFetching.includes(fetchStatusAverage) &&
    !legendConfigured
  ) {
    legendConfig = overviewLegendConfig(overviewDaysLegendSeries(
      series, seriesExercise, seriesAverage, inPDF,
    ));
  }

  return {
    meterUnits,
    hasExercise,
    hasSteps,
    timeFrameConfig,
    ...overviewDaysGraphSeries(
      series, seriesExercise, seriesAverage, percentiles, average, inPDF,
    ),
    fetchStatus,
    hasInsulinManual,
    hasInsulinPump,
    fetchStatusExercise,
    fetchStatusAverage,
    readingsType: state.page.readingsType,
    average,
    percentiles,
    oneDayWidth,
    axisWidth,
    inPDF,
    legendConfigured,
    legendConfig,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    graphsFetchOverviewThunk,
    graphsFetchExerciseOverviewThunk,
    graphsUpdateOverviewDaysLegendConfigThunk,
    graphFetchAverageAndPercentilesThunk,
    cleanUp,
  }, dispatch),
});

@connect(mapStateToProps, mapDispatchToProps)
export default class OverviewGraphContainer extends React.Component {
  constructor(props) {
    super(props);
    this.fetchSeries = this.fetchSeries.bind(this);
    this.fetchSeriesExercise = this.fetchSeriesExercise.bind(this);
    this.fetchAverageAndPercentiles = this.fetchAverageAndPercentiles.bind(this);
  }

  componentDidMount() {
    if (this.props.fetchStatus === FETCH_STATUS_NOT_CALLED) {
      this.fetchSeries(this.props);
    }
    if (this.props.fetchStatusExercise === FETCH_STATUS_NOT_CALLED) {
      this.fetchSeriesExercise(this.props);
    }
    if (this.props.fetchStatusAverage === FETCH_STATUS_NOT_CALLED) {
      this.fetchAverageAndPercentiles(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentKey = keyFromParams({
      startTimestamp: this.props.startTimestamp,
      endTimestamp: this.props.endTimestamp,
      id: this.props.graphTypeName,
    });

    const newKey = keyFromParams({
      startTimestamp: nextProps.startTimestamp,
      endTimestamp: nextProps.endTimestamp,
      id: nextProps.graphTypeName,
    });

    const seriesFinishedFetching = [FETCH_STATUS_SUCCESSFUL, FETCH_STATUS_FAILED];
    if (
      seriesFinishedFetching.includes(nextProps.fetchStatus) &&
      seriesFinishedFetching.includes(nextProps.fetchStatusExercise) &&
      seriesFinishedFetching.includes(nextProps.fetchStatusAverage) &&
      !nextProps.legendConfigured
    ) {
      nextProps.actions.graphsUpdateOverviewDaysLegendConfigThunk(nextProps.legendConfig);
    }

    if (currentKey !== newKey) {
      this.cleanup(this.props);
      this.fetchSeries(nextProps);
    }

    const currentKeyExercise = keyFromParams({
      startTimestamp: this.props.startTimestamp,
      endTimestamp: this.props.endTimestamp,
      id: GRAPH_NAME_EXERCISE,
    });

    const newKeyExercise = keyFromParams({
      startTimestamp: nextProps.startTimestamp,
      endTimestamp: nextProps.endTimestamp,
      id: GRAPH_NAME_EXERCISE,
    });

    if (currentKeyExercise !== newKeyExercise) {
      this.cleanupExercise(this.props);
      this.fetchSeriesExercise(nextProps);
    }
    const currentKeyAverage = keyFromParams({
      startTimestamp: this.props.startTimestamp,
      endTimestamp: this.props.endTimestamp,
      id: this.props.graphTypeNameAverage,
    });

    const newKeyAverage = keyFromParams({
      startTimestamp: nextProps.startTimestamp,
      endTimestamp: nextProps.endTimestamp,
      id: nextProps.graphTypeNameAverage,
    });

    if (currentKeyAverage !== newKeyAverage) {
      this.cleanupAverage(this.props);
      this.fetchAverageAndPercentiles(nextProps);
    }
  }

  componentWillUnmount() {
    this.cleanup(this.props);
    this.cleanupExercise(this.props);
    this.cleanupAverage(this.props);
  }

  cleanup(props) {
    props.actions.cleanUp({
      startTimestamp: props.startTimestamp,
      endTimestamp: props.endTimestamp,
      id: props.graphTypeName,
    });
  }

  cleanupExercise(props) {
    props.actions.cleanUp({
      startTimestamp: props.startTimestamp,
      endTimestamp: props.endTimestamp,
      id: GRAPH_NAME_EXERCISE,
    });
  }

  cleanupAverage(props) {
    props.actions.cleanUp({
      startTimestamp: props.startTimestamp,
      endTimestamp: props.endTimestamp,
      id: props.graphTypeNameAverage,
    });
  }

  fetchSeries(props) {
    props.actions.graphsFetchOverviewThunk({
      startTimestamp: props.startTimestamp,
      endTimestamp: props.endTimestamp,
      timeFrameConfig: props.timeFrameConfig,
      series: sortBy([
        ...bgGraphSeriesName,
        ...cgmGraphSeriesName,
        ...insulinSeriesName,
        ...carbsSeriesName,
        ...insulinTotalsName,
      ]),
      id: props.graphTypeName,
    });
  }

  fetchSeriesExercise(props) {
    props.actions.graphsFetchExerciseOverviewThunk({
      startTimestamp: props.startTimestamp,
      endTimestamp: props.endTimestamp,
      timeFrameConfig: props.timeFrameConfig,
      id: GRAPH_NAME_EXERCISE,
    });
  }

  fetchAverageAndPercentiles(props) {
    props.actions.graphFetchAverageAndPercentilesThunk({
      startTimestamp: props.startTimestamp,
      endTimestamp: props.endTimestamp,
      timeFrameConfig: props.timeFrameConfig,
      id: props.graphTypeNameAverage,
      series: sortBy([
        ...bgGraphAverageName,
        ...bgGraphPercentilesName,
        ...cgmGraphAverageName,
        ...cgmGraphPercentilesName,
      ]),
    });
  }

  render() {
    const { startTimestamp, endTimestamp, ...otherProps } = this.props;

    return (
      <OverviewGraphPresenter
        startTimestamp={moment.utc(startTimestamp).unix()}
        endTimestamp={moment.utc(endTimestamp).unix()}
        {...otherProps}
        inPdf={this.props.inPDF}
      />
    );
  }
}

OverviewGraphContainer.propTypes = {
  startTimestamp: PropTypes.string.isRequired,
  endTimestamp: PropTypes.string.isRequired,
  meterUnits: PropTypes.string.isRequired,
  graphsWindowWidth: PropTypes.number.isRequired,
  oneDayWidth: PropTypes.number.isRequired,
  axisWidth: PropTypes.number.isRequired,
  bgGraphSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  cgmGraphSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  exerciseSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  insulinPumpSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  insulinManualSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  carbsSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  graphTypeName: PropTypes.string.isRequired,
  graphTypeNameAverage: PropTypes.string.isRequired,
  fetchStatus: PropTypes.oneOf(FETCH_STATUSES).isRequired,
  fetchStatusExercise: PropTypes.oneOf(FETCH_STATUSES).isRequired,
  fetchStatusAverage: PropTypes.oneOf(FETCH_STATUSES).isRequired,
  inPDF: PropTypes.bool.isRequired,
  hasInsulinManual: PropTypes.bool,
  hasInsulinPump: PropTypes.bool,
  hasExercise: PropTypes.bool,
  hasSteps: PropTypes.bool,
  legendConfigured: PropTypes.bool.isRequired,
  legendConfig: PropTypes.object,
};

OverviewGraphContainer.defaultProps = {
  meterUnits: Readings.MGDL,
  bgGraphSeries: [],
  cgmGraphSeries: [],
  exerciseSeries: [],
  insulinPumpSeries: [],
  insulinManualSeries: [],
  carbsSeries: [],
  graphTypeName: GRAPH_NAME,
  oneDayWidth: CALENDAR_ONE_DAY_WIDTH_WEB,
  axisWidth: CALENDAR_AXIS_WIDTH_WEB,
  graphTypeNameAverage: GRAPH_NAME_AVERAGE,
  fetchStatus: FETCH_STATUS_NOT_CALLED,
  fetchStatusExercise: FETCH_STATUS_NOT_CALLED,
  fetchStatusAverage: FETCH_STATUS_NOT_CALLED,
  inPDF: false,
  hasInsulinManual: false,
  hasInsulinPump: false,
  hasExercise: true,
  hasSteps: true,
  legendConfigured: false,
};
