import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { shareDataWithInsulet, UPLOAD, SELECT_DEVICE } from '~/redux/modules/omnipod/omnipod';
import detectOs from '~/utils/detectOs';
import ImageStore from '~/utils/ImageStore';
import { Button, Dialog, DialogActions, DialogContent, DialogHeader } from '@glooko/common-ui';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate';
import Style from './../OmnipodUploadContainer.scss';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ shareDataWithInsulet }, dispatch),
});

class ShareDataInsulet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openDialog: true,
    };
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  switchToNextModal() {
    const { switchModal } = this.props;
    if (detectOs.isMac()) {
      switchModal(SELECT_DEVICE);
    } else {
      switchModal(UPLOAD);
    }
  }

  shareDataWithInsulet(consent) {
    this.props.actions.shareDataWithInsulet(consent ? 'agree' : 'disagree');
    this.switchToNextModal();
  }

  handleOk() {
    this.props.actions.shareDataWithInsulet('pending_parental');
    this.switchToNextModal();
  }

  handleCancel() {
    this.setState({
      openDialog: false,
    });
  }

  render() {
    const { t, isMinor, guardianEmail } = this.props;
    const { openDialog } = this.state;

    return (
      <div>
        {
          isMinor &&
          <Dialog onClose={this.handleCancel} open={openDialog}>
            <DialogHeader>
              <h2>{t('sharingDataWithInsulet')}</h2>
            </DialogHeader>
            <DialogContent>
              <p>
                {t('dialoqContent', { guardianEmail })}
              </p>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleOk}>{t('Ok')}</Button>
            </DialogActions>
          </Dialog>
        }
        {!isMinor &&
          <div class={Style.insuletShareData}>
            {this.props.children}
            <div class={Style.omnipodUploaderHeader}>
              {t('InsuletHeader')}
              <img src={ImageStore.xClose} class={Style.btnClose} onClick={this.props.onClose} />
            </div>
            <div class={Style.insuletConsentContent}>
              <p>
                {t('InsuletContent')}
                <a
                  href="https://www.omnipod.com/privacy"
                  class={Style.privacyPolicyLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t('insuletPrivacyPolicy')}
                </a>
                {t('insuletContentRemainingDesc')}
              </p>
              <p>
                {t('youCanChangeSettings')}
              </p>
              <p>
                {t('selectOneOfTheFollowings')}
                <a
                  href="https://www.glooko.com/privacy"
                  class={Style.privacyPolicyLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t('PrivacyPolicy')}
                </a>
              </p>
              <p>
                {t('wouldYouLike')}
              </p>
              <div class={Style.buttonContainerShare}>
                <button
                  class={classNames(Style.omnipodBlueButton, 'insuletAgree')}
                  onClick={() => { this.shareDataWithInsulet(true); }}
                >
                  {t('agree')}
                </button>
                <button
                  class={classNames(Style.cancelButton, 'insuletDisagree')}
                  onClick={() => { this.shareDataWithInsulet(false); }}
                >
                  {t('disagree')}
                </button>
              </div>
            </div>
          </div>}
      </div>
    );
  }
}

ShareDataInsulet.propTypes = {
  onClose: PropTypes.func.isRequired,
  switchModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export const TranslatedShareDataInsulet = translate('ShareDataInsulet')(ShareDataInsulet);
export default connect(null, mapDispatchToProps)(TranslatedShareDataInsulet);
