// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/images/ui-bg_diagonals-thick_20_666666_40x40.png");
exports = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".IdleTimerPopover_blurAll{position:fixed;top:0;width:100%;height:100vh;overflow:hidden;z-index:99996;-webkit-backdrop-filter:blur(6px);backdrop-filter:blur(6px)}.IdleTimerPopover_overlay{position:fixed;top:0;width:100%;height:100vh;overflow:hidden;z-index:99998;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");opacity:.5}.IdleTimerPopover_idleDialog{z-index:99999 !important}.IdleTimerPopover_close{display:-webkit-box;display:-ms-flexbox;display:flex}.IdleTimerPopover_close .IdleTimerPopover_link{-webkit-margin-start:auto;margin-inline-start:auto}.IdleTimerPopover_content{margin:16px 0;font-size:16px}", "",{"version":3,"sources":["IdleTimerPopover.scss"],"names":[],"mappings":"AAEA,0BACE,cAAA,CACA,KAAA,CACA,UAAA,CACA,YAAA,CACA,eAAA,CACA,aAAA,CACA,iCAAA,CAAA,yBAAA,CAEF,0BACE,cAAA,CACA,KAAA,CACA,UAAA,CACA,YAAA,CACA,eAAA,CACA,aAAA,CACA,wDAAA,CACA,UAAA,CAGF,6BACE,wBAAA,CAGF,wBACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,+CACE,yBAAA,CAAA,wBAAA,CAGJ,0BACE,aAAA,CACA,cAAA","file":"IdleTimerPopover.scss","sourcesContent":["$images-path: \"../../../../../assets/images/\";\n\n.blurAll {\n  position: fixed;\n  top: 0;\n  width: 100%;\n  height: 100vh;\n  overflow: hidden;\n  z-index: 99996; \n  backdrop-filter: blur(6px);\n}\n.overlay {\n  position: fixed;\n  top: 0;\n  width: 100%;\n  height: 100vh;\n  overflow: hidden;\n  z-index: 99998; \n  background-image: url(\"#{$images-path}ui-bg_diagonals-thick_20_666666_40x40.png\");\n  opacity: 0.5;\n}\n\n.idleDialog {\n  z-index: 99999 !important;\n  // Dialogs are 1300\n}\n.close {\n  display: flex;\n  .link {\n    margin-inline-start: auto;\n  }\n}\n.content {\n  margin: 16px 0;\n  font-size: 16px;\n}"]}]);
// Exports
exports.locals = {
	"blurAll": "IdleTimerPopover_blurAll",
	"overlay": "IdleTimerPopover_overlay",
	"idleDialog": "IdleTimerPopover_idleDialog",
	"close": "IdleTimerPopover_close",
	"link": "IdleTimerPopover_link",
	"content": "IdleTimerPopover_content"
};
module.exports = exports;
