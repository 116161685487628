import React from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import WithSkipEmpty from '~/bundles/shared/components/WithSkipEmpty/WithSkipEmpty.jsx';
import ImageStore from '~/utils/ImageStore';
import classNames from 'classnames';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import { WhiteOverlay } from '~/bundles/shared/components/Overlay/Overlay.jsx';
import { updatePatientEmailThunk } from '~/redux/thunks/terminalSyncs';
import Style from './AddEmailDialog.scss';

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ updatePatientEmailThunk }, dispatch),
});

export class AddEmailDialog extends React.Component {
  static propTypes = {
    glookoCode: PropTypes.string.isRequired,
    authCode: PropTypes.string.isRequired,
    rowId: PropTypes.string.isRequired,
    onInvite: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      updatePatientEmailThunk: PropTypes.func.isRequired,
    }),
  }

  static defaultProps = {
    glookoCode: '',
    rowId: '',
    actions: {
      updatePatientEmailThunk: () => null,
    },
  }

  constructor(props) {
    super(props);

    this.state = {
      newEmailIsValid: false,
      newEmailInUse: false,
      newEmail: '',
      touched: false,
    };

    this.onEmailInputChange = this.onEmailInputChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onInvite = this.onInvite.bind(this);
  }

  onEmailInputChange(event) {
    this.setState({
      newEmail: event.target.value,
      newEmailIsValid: EMAIL_REGEX.test(event.target.value),
    });
  }

  onBlur(event) {
    this.setState({
      newEmailIsValid: EMAIL_REGEX.test(event.target.value),
      touched: true,
    });
  }

  onFocus(event) {
    this.setState({
      newEmailIsValid: EMAIL_REGEX.test(event.target.value),
      newEmailInUse: false,
    });
  }

  onInvite() {
    if (this.state.newEmailIsValid) {
      this.props.actions.updatePatientEmailThunk(
        this.props.glookoCode,
        {
          email: this.state.newEmail,
        },
        this.props.rowId,
        this.props.onInvite,
      ).catch(() => {
        this.setState({
          newEmailIsValid: false,
          newEmailInUse: true,
        });
      });
    }
  }

  renderEmailExistedErrorMessage(display) {
    const { t, authCode } = this.props;
    const style = display ? { visibility: 'visible' } : null;

    return (
      <div className={Style.emailExistedError} style={style}>
        {t('emailExistedMessage', { proconnectCode: authCode })}
      </div>
    );
  }

  render() {
    const { t, onCancel } = this.props;
    return (
      <div>
        <WhiteOverlay onClick={onCancel} />
        <div className={Style.AddEmailDialog}>
          {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
          <img src={ImageStore.xClose} className={Style.closeIcon} onClick={onCancel} alt="close" />
          {/* eslint-enable jsx-a11y/no-noninteractive-element-interactions */}
          <h1>{t('newEmail')}</h1>
          <p>{t('addEmailAddress')}</p>
          <h2
            className={classNames({
              [Style.emailInputError]: this.state.touched && !this.state.newEmailIsValid,
            })}
          >
            {t('emailAddress')}
          </h2>
          <input
            className={classNames({
              [Style.error]: this.state.touched && !this.state.newEmailIsValid,
            })}
            type="text"
            value={this.state.newEmail}
            onChange={this.onEmailInputChange}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
          />
          {this.renderEmailExistedErrorMessage(this.state.newEmailInUse)}
          <div className={Style.buttons}>
            <button
              id="buttonInvite"
              className={Style.buttonPrimary}
              onClick={this.onInvite}
              disabled={!this.state.newEmailIsValid}
            >
              {t('invite')}
            </button>
            <button
              className={Style.buttonSecondary}
              onClick={onCancel}
            >
              {t('cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
const connectedAddEmailDialog = connect(null, mapDispatchToProps)(AddEmailDialog);
const translatedAddEmailDialog = translate('AddEmailDialog')(connectedAddEmailDialog);
export default WithSkipEmpty(translatedAddEmailDialog, (props) => props.visible);
