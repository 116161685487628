export function trackWebDatePicker() {
  window.eventLogging.trackWebDatePicker();
}

export function trackWebTimeFramePicker() {
  window.eventLogging.trackWebTimeFramePicker();
}

export function trackWebDataTypeToggled() {
  window.eventLogging.trackWebDataTypeToggled();
}

export function trackWebTimeFrameShifted() {
  window.eventLogging.trackWebTimeFrameShifted();
}

export function trackPdfWizardFavoriteSelected() {
  window.eventLogging.trackPdfWizardFavoriteSelected();
}

export function trackManagePdfFavorite() {
  window.eventLogging.trackManagePdfFavorite();
}

export function trackCreatedPdfReport(payload) {
  window.eventLogging.trackCreatedPdfReport(payload);
}

export function trackSavePdfFavorite(payload) {
  window.eventLogging.trackSavePdfFavorite(payload);
}

export function trackSummaryTimeStatisticsClicked(buttonName) {
  window.eventLogging.trackSummaryTimeStatisticsClicked(buttonName);
}

export function trackExportedCsv(payload) {
  window.eventLogging.trackExportedCsv(payload);
}

export function trackGraphsOverviewClicked() {
  window.eventLogging.trackGraphsOverviewClicked();
}

export function trackGraphsOverlayClicked() {
  window.eventLogging.trackGraphsOverlayClicked();
}

export function trackGraphsWeekViewClicked() {
  window.eventLogging.trackGraphsWeekViewClicked();
}

export function trackGraphsCalendarClicked() {
  window.eventLogging.trackGraphsCalendarClicked();
}

export function trackGraphsOverviewTimeFrameClicked() {
  window.eventLogging.trackGraphsOverviewTimeFrameClicked();
}

export function trackGraphsOverviewPercentilesClicked(state) {
  window.eventLogging.trackGraphsOverviewPercentilesClicked(state);
}

export function trackGraphsOverviewAverageClicked(state) {
  window.eventLogging.trackGraphsOverviewAverageClicked(state);
}

export function trackGraphsOverlayDayOfWeekClicked() {
  window.eventLogging.trackGraphsOverlayDayOfWeekClicked();
}

export function trackGraphsOverlayScatterClicked() {
  window.eventLogging.trackGraphsOverlayScatterClicked();
}

export function trackGraphsOverlayScatterConnectDaysClicked(state) {
  window.eventLogging.trackGraphsOverlayScatterConnectDaysClicked(state);
}

export function trackGraphsOverlayScatterAverageClicked(state) {
  window.eventLogging.trackGraphsOverlayScatterAverageClicked(state);
}

export function trackGraphsOverlayScatterBgReadingClicked() {
  window.eventLogging.trackGraphsOverlayScatterBgReadingClicked();
}

export function trackGraphsOverlayBoxPlotClicked() {
  window.eventLogging.trackGraphsOverlayBoxPlotClicked();
}

export function trackGraphsOverlayLowestHighestClicked(state, tab) {
  window.eventLogging.trackGraphsOverlayLowestHighestClicked(state, tab);
}

export function trackGraphsOverlayMedianClicked(state, tab) {
  window.eventLogging.trackGraphsOverlayMedianClicked(state, tab);
}

export function trackGraphsOverlaySpaghettiClicked() {
  window.eventLogging.trackGraphsOverlaySpaghettiClicked();
}

export function trackGraphsOverlaySpaghettiTracingClicked() {
  window.eventLogging.trackGraphsOverlaySpaghettiTracingClicked();
}

export function trackGraphsOverlayAgpClicked() {
  window.eventLogging.trackGraphsOverlayAgpClicked();
}

export function trackGraphsCalendarDayClicked() {
  window.eventLogging.trackGraphsCalendarDayClicked();
}

export function trackGraphsWeekViewDayClicked() {
  window.eventLogging.trackGraphsWeekViewDayClicked();
}

export function trackInsightsSetSiteChangeClicked() {
  window.eventLogging.trackInsightsSetSiteChangeClicked();
}

export function trackInsightsTempBasalDecreaseSuspendClicked() {
  window.eventLogging.trackInsightsTempBasalDecreaseSuspendClicked();
}

export function trackInsightsTempBasalIncreaseClicked() {
  window.eventLogging.trackInsightsTempBasalIncreaseClicked();
}

export function trackSelectedDevice(payload) {
  window.eventLogging.trackSelectedDevice(payload);
}

export function trackAssignDevicesTabClicked() {
  window.eventLogging.trackAssignDevicesTabClicked();
}

export function trackFilteredTypeSelected(source, selections) {
  window.eventLogging.trackFilteredTypeSelected(source, selections);
}

export function trackAssignDevicesRefreshClicked() {
  window.eventLogging.trackAssignDevicesRefreshClicked();
}

export function trackAssignToPatientClicked(terminalSync) {
  window.eventLogging.trackAssignToPatientClicked(terminalSync);
}

export function trackConfirmDOBModalButtonClicked(terminalSync, glookoCode, selection, success) {
  window.eventLogging.trackConfirmDOBModalButtonClicked(terminalSync, glookoCode, selection, success);
}

export function trackAddEmailBannerButtonClicked(terminalSync, glookoCode, selection) {
  window.eventLogging.trackAddEmailBannerButtonClicked(terminalSync, glookoCode, selection);
}

export function trackFindModalButtonClicked(terminalSync, selection, mrnFilled) {
  window.eventLogging.trackFindModalButtonClicked(terminalSync, selection, mrnFilled);
}

export function trackCreateModalButtonClicked(terminalSync, selection, todFilled, emailFilled, phoneFilled) {
  window.eventLogging.trackCreateModalButtonClicked(terminalSync, selection, todFilled, emailFilled, phoneFilled);
}

export function trackPatientClicked(device) {
  window.eventLogging.trackPatientClicked(device);
}

export function trackCreateReportClicked(device) {
  window.eventLogging.trackCreateReportClicked(device);
}

export function trackUnassignClicked(device) {
  window.eventLogging.trackUnassignClicked(device);
}
export function trackAssignToOtherClicked(device, glookoCode) {
  window.eventLogging.trackAssignToOtherClicked(device, glookoCode);
}
export function trackSelectPatientModalButtonClicked(terminalSync, glookoCode, selection, matchCount) {
  window.eventLogging.trackSelectPatientModalButtonClicked(terminalSync, glookoCode, selection, matchCount);
}

// Patient Devices
export function trackMyCloudConnectionViewed() {
  window.eventLogging.trackMyCloudConnectionViewed();
}

export function trackMyDeviceViewed() {
  window.eventLogging.trackMyDeviceViewed();
}

export function trackMyDeviceUploadedDateChanged() {
  window.eventLogging.trackMyDeviceUploadedDateChanged();
}

// Search
export function trackSearchTypedText(searchBy) {
  window.eventLogging.trackSearchTypedText(searchBy);
}
export function trackSearchClickedPatient(category, patientId, patientGlookoCode) {
  window.eventLogging.trackSearchClickedPatient(category, patientId, patientGlookoCode);
}
export function trackSearchClickedPdf(category, patientId, patientGlookoCode) {
  window.eventLogging.trackSearchClickedPdf(category, patientId, patientGlookoCode);
}
export function trackSearchViewedAllMatches(totalMatch, callback) {
  window.eventLogging.trackSearchViewedAllMatches(totalMatch, callback);
}
export function trackEditDuplicated(editedFields, editedCount) {
  window.eventLogging.trackEditDuplicated(editedFields, editedCount);
}
export function trackRemoveDuplicated(removeCount) {
  window.eventLogging.trackRemoveDuplicated(removeCount);
}
export function trackSelectFixDuplicated() {
  window.eventLogging.trackSelectFixDuplicated();
}
export function trackFilterDuplicatedAccounts(category) {
  window.eventLogging.trackFilterDuplicatedAccounts(category);
}
export function trackFilterApplied(
    selection, providerTagCount, customTagCount,
    patientAccountCount, careProgramsCount, flagsCount,
  ) {
  window.eventLogging.trackFilterApplied(
    selection, providerTagCount, customTagCount,
    patientAccountCount, careProgramsCount, flagsCount);
}
export function trackPopTrackerColumnSort(sortBy, direction) {
  window.eventLogging.trackPopTrackerColumnSort(sortBy, direction);
}
export function trackPatientTagAdded(category) {
  window.eventLogging.trackPatientTagAdded(category);
}
export function trackPatientTagRemoved(category) {
  window.eventLogging.trackPatientTagRemoved(category);
}
export function trackViewCarePrograms(cpName) {
  window.eventLogging.trackViewCarePrograms(cpName);
}
export function trackAssignCareProgram(category, cpName) {
  window.eventLogging.trackAssignCareProgram(category, cpName);
}
export function trackUnassignCareProgram(category, cpName) {
  window.eventLogging.trackUnassignCareProgram(category, cpName);
}

export function trackWebPageLoaded(dataSource) {
  window.eventLogging.trackWebPageLoaded(dataSource);
}
export function trackChangeEmailClicked() {
  window.eventLogging.trackChangeEmailClicked();
}
export function trackChangePasswordClicked() {
  window.eventLogging.trackChangePasswordClicked();
}
export function trackChangeLanguageClicked() {
  window.eventLogging.trackChangeLanguageClicked();
}
export function trackUpdatedDeviceDataSharing(partner, choice) {
  window.eventLogging.trackUpdatedDeviceDataSharing(partner, choice);
}
export function trackSelectedAppsDevices(selectedDevice) {
  window.eventLogging.trackSelectedAppsDevices(selectedDevice);
}
export function trackDeviceConsentSelection(choice) {
  window.eventLogging.trackDeviceConsentSelection(choice);
}
export function trackResearchNetworkConsent(choice) {
  window.eventLogging.trackResearchNetworkConsent(choice);
}
export function trackMarketingConsent(choice) {
  window.eventLogging.trackMarketingConsent(choice);
}
export function trackSmsConsent(choice) {
  window.eventLogging.trackSmsConsent(choice);
}
export function trackCopyKeyStats() {
  window.eventLogging.trackCopyKeyStats();
}
export function trackValidicPageLoaded() {
  window.eventLogging.trackValidicPageLoaded();
}
export function trackAuditEventsSearch(searchParams) {
  window.eventLogging.trackAuditEventsSearch(searchParams);
}
export function trackAuditEventDetails(eventName) {
  window.eventLogging.trackAuditEventDetails(eventName);
}
