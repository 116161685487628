import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Images from 'utils/ImageStore';
import { usePapaParse } from 'react-papaparse';
import { trackSelectedAppsDevices } from 'services/eventLogging';
import { useReferenceDeviceList } from 'bundles/shared/hooks/useReferenceDeviceList';
import UserHelper from 'redux/modules/users/UserHelper';
import FileUploaderPopup from 'bundles/shared/components/FileUploaderPopup/FileUploaderPopup';
import { FileInfo } from '../../../../shared/components/FileUploaderPopup/FileUploaderPopupConfirm';

import Style from '../PartnerIntegrationsCard.scss';

const mapStateToProps = (state: State) => {
  const user = UserHelper.displayUser(state);
  const countryOfResidence = user.countryOfResidence;
  return { countryOfResidence };
};

export const useFileParser = async (file: File, setData: (data: FileInfo) => void) => {
  const { readString } = usePapaParse();

  readString<{[key: string]: string}>(await file.text(), {
    header: true,
    complete: (result) => {
      const data = result.data[0];
      const name = data?.['First Name'] && data?.['Last Name'] ? `${data['First Name']} ${data['Last Name']}` : undefined;
      setData({
        name,
        startDate: data?.['Start Date'],
        endDate: data?.['End Date'],
      });
    },
  });
};

export interface MedtronicCsvButtonProps {
  children?: never;
}

const MedtronicCsvButton = (props: MedtronicCsvButtonProps & ReturnType<typeof mapStateToProps>) => {
  const { countryOfResidence } = props;

  const [showPopup, setShowPopup] = useState(false);

  const { t } = useTranslation('MedtronicCsvButton');

  const { data, error } = useReferenceDeviceList();

  const openPopup = () => {
    trackSelectedAppsDevices('Medtronic');
    setShowPopup(true);
  };

  const closePopup = () => setShowPopup(false);

  const device = data?.devices.find((device) => device.id === 'MEDTRONIC_CSV');
  const showButton = device?.supportedCountries.includes(countryOfResidence);

  if (!showButton || error) {
    return null;
  }

  return (
    <>
      {showPopup ? (
        <FileUploaderPopup
          acceptedExtensions={['.csv']}
          closePopup={closePopup}
          extractorName="MedtronicCSV"
          fileParser={useFileParser}
          providerName="Medtronic CareLink"
          supportArticleId="27404421584147"
        />
      ) : null}

      <button
        className={Style.button}
        onClick={openPopup}
        type="button"
      >
        <div className={Style.buttonContent}>
          <img alt="integration" className={Style.buttonImage} src={Images.integrationLogoMedtronic} />

          <div className={Style.buttonName}>
            <span>{t('uploadMedtronicDataButton')}</span>
          </div>
        </div>
      </button>
    </>
  );
};

export default connect<ReturnType<typeof mapStateToProps>, unknown, MedtronicCsvButtonProps, State>(mapStateToProps)(MedtronicCsvButton);
