import React from 'react';
import { connect } from 'react-redux';
import { get, orderBy, isEmpty, isEqual, each, filter } from 'lodash';
import { bindActionCreators } from '@reduxjs/toolkit';
import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Dialog, DialogActions, DialogContent, DialogHeader } from '@glooko/common-ui';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import { WhiteOverlay } from '~/bundles/shared/components/Overlay/Overlay.jsx';
import { assignToPatientThunk } from '~/redux/thunks/terminalSyncs';
import { assignDeviceToPatient } from
  '~/bundles/recentTerminalSyncs/RecentTerminalSyncs/RecentTerminalSyncs.jsx';
import {
  updatePatientICPADetailsThunk,
  resetPatientICPADetailsStatusInStore,
  getRecentlySyncedUserICPAData,
} from '~/redux/thunks/patientICPADetails';
import { TIME_FORMATS } from '~/utils/i18nFormats';
import {
  SEARCH_PATIENT_SUCCESS,
  SEARCH_PATIENT_NO_RESULTS,
  SEARCH_PATIENT_SEARCHING,
  fetchPatientsThunk,
  createPatientThunk,
  CREATE_PATIENT_SUCCESS,
  CREATE_PATIENT_FAIL,
} from '~/redux/thunks/providerGroupSite/providerGroupSite';
import {
  updateCreateNewPatientModalVisibility,
  updatePatientICPADetailsModalVisibility,
} from '~/redux/modules/page/page';
import {
  patientSearchResults,
  UPDATE_PATIENT_ICPA_DATA_IN_PROGRESS,
  UPDATE_PATIENT_ICPA_DATA_SUCCESS,
} from '~/redux/modules/providerGroupSite/providerGroupSite';
import CreateNewPatientModal from '~/bundles/shared/components/CreateNewPatientModal/CreateNewPatientModal.jsx';
import TimeUtils from '~/utils/TimeUtils/TimeUtils';
import {
  trackFindModalButtonClicked,
  trackCreateModalButtonClicked,
  trackSelectPatientModalButtonClicked,
} from '~/services/eventLogging';
import { EMAIL_REGEX } from '~/bundles/shared/constants/regex';
import {
  patientHasICPARequiredData,
  returnNotDisplayedKeyFromParams,
  isEmailFake,
  isKeySetToNotDisplayed,
} from '~/utils/ICPAConfig';
import Spinner from '~/bundles/shared/components/Spinner/Spinner';

import SearchPatientInput from './SearchPatientInput/SearchPatientInput';
import PatientBlocks from './PatientBlocks/PatientBlocks';
import FindPatientHeaderPresenter from './FindPatientHeaderPresenter/FindPatientHeaderPresenter';
import FindPatientFooterPresenter from './FindPatientFooterPresenter/FindPatientFooterPresenter';
import Style from './FindPatientModal.scss';

const mapStateToProps = (state) => ({
  assignStatus: state.page.assignStatus,
  patientSearch: state.providerGroupSite.patientSearch,
  createNewPatientModalVisible: state.page.createNewPatientModalDisplayed,
  patientICPADataUpdateFormVisible: state.page.patientICPADataUpdateFormVisible,
  rowData: state.page.rowData,
  pgs: state.providerGroupSite,
  icpaConfig: state.providerGroupSite.icpaConfig,
  selectedPatientICPADataUpdateStatus: state.providerGroupSite.selectedPatientICPADataUpdateStatus,
  isReasonForChange: state.providerGroupSite.reasonForChange &&
    state.providerGroupSite.icpaConfig.reasonForChange !== 'not_displayed',
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    assignToPatientThunk,
    fetchPatientsThunk,
    patientSearchResults,
    getRecentlySyncedUserICPAData,
    updateCreateNewPatientModalVisibility,
    updatePatientICPADetailsModalVisibility,
    updatePatientICPADetailsThunk,
    trackFindModalButtonClicked,
    trackCreateModalButtonClicked,
    resetPatientICPADetailsStatusInStore,
    createPatientThunk,
  }, dispatch),
});

export const emptyStateObject = {
  firstName: '',
  lastName: '',
  dob: '',
  fixOverflow: false,
  genderOptions: false,
  searchResultsOverflow: false,
  mrn: '',
  participantId: '',
  selectedBlock: {},
  createFormInvalid: false,
  reasonForEmailOptOut: '',
  optOutEmailInfo: false,
  reasonForPhoneOptOut: '',
  optOutPhoneInfo: false,
};

export const emptyStateErrorObject = {
  error: '',
  mrnExisted: false,
  mrnFormatValidationFailed: false,
  emailExisted: false,
  participantIdExisted: false,
  t1dExchangeIdentifierExisted: false,
};

export class FindPatientModal extends React.Component {
  static propTypes = {
    actions: PropTypes.shape({
      assignToPatientThunk: PropTypes.func,
      fetchPatientsThunk: PropTypes.func,
      patientSearchResults: PropTypes.func,
      updateCreateNewPatientModalVisibility: PropTypes.func,
      updatePatientICPADetailsModalVisibility: PropTypes.func,
      resetPatientICPADetailsStatusInStore: PropTypes.func,
      updatePatientICPADetailsThunk: PropTypes.func,
      trackFindModalButtonClicked: PropTypes.func,
      trackCreateModalButtonClicked: PropTypes.func,
      getRecentlySyncedUserICPAData: PropTypes.func,
      createPatientThunk: PropTypes.func,
    }),
    visible: PropTypes.bool.isRequired,
    visibilityCallback: PropTypes.func.isRequired,
    updateSerialNumberCache: PropTypes.func,
    t: PropTypes.func.isRequired,
    assignStatus: PropTypes.shape({}),
    patientSearch: PropTypes.shape({}).isRequired,
    createNewPatientModalVisible: PropTypes.bool.isRequired,
    patientICPADataUpdateFormVisible: PropTypes.bool.isRequired,
    rowData: PropTypes.shape({}).isRequired,
    pgs: PropTypes.shape({
      authCode: PropTypes.string.isRequired,
      countryOfJurisdiction: PropTypes.string.isRequired,
      language: PropTypes.string.isRequired,
      devices: PropTypes.shape({ terminalSyncs: PropTypes.array }),
    }).isRequired,
    patientView: PropTypes.bool,
    updatePatientDataView: PropTypes.bool,
    icpaConfig: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      dateOfBirth: PropTypes.string.isRequired,
      participantId: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      medicalRecordNumber: PropTypes.string,
      phoneNumber: PropTypes.string.isRequired,
      zipCode: PropTypes.string.isRequired,
      typeOfDiabetes: PropTypes.string.isRequired,
      gender: PropTypes.string.isRequired,
      t1dExchangeIdentifier: PropTypes.string.isRequired,
    }).isRequired,
    selectedPatientICPADataUpdateStatus: PropTypes.string.isRequired,
    existingUserDetails: PropTypes.object,
    savePatientData: PropTypes.func,
    isUpdate: PropTypes.bool,
    isReasonForChange: PropTypes.bool,
    setMedicationOverrideList: PropTypes.func,// eslint-disable-line
    medicationOverrideList: PropTypes.object,
    setPayloadForMedicationSelector: PropTypes.func,
    setDisplayInsulinSelector: PropTypes.func,
    ageOfMajority: PropTypes.number,
    createPatientData: PropTypes.object.isRequired,
    pgsCarePrograms: PropTypes.array,
  };

  static defaultProps = {
    actions: {
      assignToPatientThunk: () => { },
      fetchPatientsThunk: () => { },
      patientSearchResults: () => { },
      updateCreateNewPatientModalVisibility: () => { },
      updatePatientICPADetailsModalVisibility: () => { },
      resetPatientICPADetailsStatusInStore: () => { },
      updatePatientICPADetailsThunk: () => { },
      trackFindModalButtonClicked: () => { },
      trackCreateModalButtonClicked: () => { },
      getRecentlySyncedUserICPAData: () => { },
      createPatientThunk: () => { },
    },
    t: () => { },
    assignStatus: {},
    patientSearch: {},
    updateSerialNumberCache: () => { },
    createNewPatientModalVisible: false,
    patientICPADataUpdateFormVisible: false,
    rowData: {},
    patientView: false,
    updatePatientDataView: false,
    existingUserDetails: {},
    savePatientData: () => { },
    isUpdate: false,
    isReasonForChange: false,
    setMedicationOverrideList: () => { },
    medicationOverrideList: null,
    setPayloadForMedicationSelector: () => { },
    setDisplayInsulinSelector: () => { },
    ageOfMajority: undefined,
    createPatientData: {},
    pgsCarePrograms: [],
  };

  static createAndAssignPayload(rowData, pgs, data) {
    const body = {
      newPatient: {
        firstName: data.firstName,
        lastName: data.lastName,
        dateOfBirth: moment.utc(data.dob).toISOString(),
        mrn: data.mrn,
        diabetesType: data.tod.value,
        phone: data.phone,
        address: {
          zipcode: data.zipCode,
        },
        t1dExchangeIdentifier: data.t1dExchangeIdentifier,
        gender: data.gender.value || null,
        password: `${window.generate_uuid()}#Az1!`,
        countryOfResidence: pgs.countryOfJurisdiction,
        optOutEmailInfo: data.optOutEmailInfo,
        optOutPhoneInfo: data.optOutPhoneInfo,
        reasonForPhoneOptOut: data.reasonForPhoneOptOut,
        reasonForEmailOptOut: data.reasonForEmailOptOut,
      },
      deviceInformation: {
        applicationType: 'kiosk',
        applicationVersion: window.application_version,
        device: navigator.userAgent,
        os: 'browser',
      },
      sync: rowData.guid,
    };

    if (data.email) {
      body.newPatient.email = data.email;
    } else {
      body.fakeEmail = true;
    }
    if (data.participantId) {
      body.newPatient.participantId = data.participantId;
    }
    if (!body.newPatient.optOutEmailInfo) {
      delete body.newPatient.optOutEmailInfo;
    }
    if (!body.newPatient.optOutPhoneInfo) {
      delete body.newPatient.optOutPhoneInfo;
    }
    Object.keys(body.newPatient).forEach((paramKey) => {
      const notDisplayedKey = returnNotDisplayedKeyFromParams(
        pgs.icpaConfig,
        paramKey,
        body.user,
      );
      if (notDisplayedKey) {
        delete body.newPatient[notDisplayedKey];
      }
    });
    return body;
  }

  static createPayload(pgs, data, icpaConfig) {
    const patientEmail = !data.email && !data.optOutEmailInfo ?
      `${window.generate_uuid()}@kiosk.fake` :
      data.email;
    const requestJSON = {
      user: {
        firstName: data.firstName,
        lastName: data.lastName,
        dateOfBirth: moment.utc(data.dob).toISOString(),
        email: patientEmail,
        mrn: data.mrn,
        participantId: data.participantId,
        diabetesType: data.tod.value,
        phone: data.phone,
        address: {
          zipcode: data.zipCode,
        },
        t1dExchangeIdentifier: data.t1dExchangeIdentifier,
        gender: data.gender.value || null,
        password: `${window.generate_uuid()}#Az1!`,
        newsLetter: false,
        countryOfResidence: pgs.countryOfJurisdiction,
        language: pgs.language,
        reasonForEmailOptOut: data.reasonForEmailOptOut,
        optOutEmailInfo: data.optOutEmailInfo,
        reasonForPhoneOptOut: data.reasonForPhoneOptOut,
        optOutPhoneInfo: data.optOutPhoneInfo,
        careProgramId: data.careProgram,
      },
      proConnect: {
        code: pgs.authCode,
      },
      deviceInformation: {
        applicationType: 'kiosk',
        applicationVersion: window.application_version,
        device: navigator.userAgent,
        os: 'browser',
      },
      consents: data.consent ? ['privacy', 'terms_of_use'] : [],
    };

    if (!requestJSON.user.diabetesType) {
      delete requestJSON.user.diabetesType;
    }
    if (!requestJSON.user.countryOfResidence) {
      delete requestJSON.user.countryOfResidence;
    }
    if (!requestJSON.user.language) {
      delete requestJSON.user.language;
    }
    if (!requestJSON.consents) {
      delete requestJSON.consents;
    }
    if (!requestJSON.user.reasonForEmailOptOut) {
      delete requestJSON.user.reasonForEmailOptOut;
    }
    if (!requestJSON.user.optOutEmailInfo) {
      delete requestJSON.user.optOutEmailInfo;
    }
    if (!requestJSON.user.reasonForPhoneOptOut) {
      delete requestJSON.user.reasonForPhoneOptOut;
    }
    if (!requestJSON.user.optOutPhoneInfo) {
      delete requestJSON.user.optOutPhoneInfo;
    }
    if (!requestJSON.user.careProgramId) {
      delete requestJSON.user.careProgramId;
    }

    Object.keys(requestJSON.user).forEach((paramKey) => {
      const notDisplayedKey = returnNotDisplayedKeyFromParams(
        icpaConfig,
        paramKey,
        requestJSON.user,
      );
      if (notDisplayedKey) {
        delete requestJSON.user[notDisplayedKey];
      }
    });
    return requestJSON;
  }

  static assignSelectedPatientPayload(data, rowData) {
    return {
      patient: data.glookoCode,
      dateOfBirth: data.dateOfBirth,
      sync: rowData.guid,
    };
  }

  static searchForPatientsPayload(firstName, lastName, dob, mrn) {
    return {
      firstName,
      lastName,
      dateOfBirth: dob,
      mrn,
      searchProconnectedUsers: true,
    };
  }

  static handleLastSyncTimestamp(timestamp) {
    return TimeUtils.formatDate(
      get(timestamp, 'lastSyncTimestamp[1]', ''),
      TIME_FORMATS.MMM_DD_YYYY,
    );
  }

  static isFormDataValid(firstName, lastName, dob) {
    if (
      firstName !== '' &&
      lastName !== '' &&
      TimeUtils.isDateValid(dob, TIME_FORMATS.YYYY_MM_DD)
    ) {
      return true;
    }
    return false;
  }

  static formatDobIfValid(dob) {
    return TimeUtils.isDateValid(dob, TIME_FORMATS.YYYY_MM_DD) ?
      TimeUtils.formatDate(dob, TIME_FORMATS.MM_DD_YYYY) :
      '';
  }

  static handleBlockSelection(selectedItem, currentItem) {
    if (!selectedItem || selectedItem !== currentItem) {
      return currentItem;
    } else if (selectedItem === currentItem) {
      return '';
    }
    return null;
  }

  static searchTerminalSync(rowData, terminalSyncs) {
    const { guid } = rowData;

    return terminalSyncs && terminalSyncs.find((terminalSync) => terminalSync.guid === guid);
  }

  constructor(props) {
    super(props);
    this.state = {
      ...emptyStateObject,
      ...emptyStateErrorObject,
      formData: {},
      invalidFields: {
        firstName: false,
        lastName: false,
        dob: false,
        tod: false,
        phone: false,
        email: false,
        mrn: false,
        consent: false,
        participantId: false,
        zipCode: false,
        gender: false,
        t1dExchangeIdentifier: false,
        reasonForEmailOptOut: false,
        reasonForPhoneOptOut: false,
      },
      invalidFieldsMessages: {},
      isReasonModalOpen: false,
      modifiedDataKeys: [],
      reasonForUpdate: '',
    };
    this.setNewPatientData = this.setNewPatientData.bind(this);
    this.setDobDatepickerData = this.setDobDatepickerData.bind(this);
    this.updateFormData = this.updateFormData.bind(this);
    this.showCreateNewPatientModal = this.showCreateNewPatientModal.bind(this);
    this.showUpdatePatientICPADetailsModal = this.showUpdatePatientICPADetailsModal.bind(
      this,
    );
    this.hideCreateNewPatientModal = this.hideCreateNewPatientModal.bind(this);
    this.hideUpdatePatientICPADetailsModal = this.hideUpdatePatientICPADetailsModal.bind(
      this,
    );
    this.handleContinueButton = this.handleContinueButton.bind(this);
    this.goBack = this.goBack.bind(this);
    this.selectBlock = this.selectBlock.bind(this);
    this.emptyStateAndCloseModal = this.emptyStateAndCloseModal.bind(this);
    this.hideCreateModalOnPatientView = this.hideCreateModalOnPatientView.bind(
      this,
    );
    this.detectedExisting = this.detectedExisting.bind(this);
    this.updateSerialNumberCache = this.updateSerialNumberCache.bind(this);
    this.assignDeviceAfterPatientDataUpdate = this.assignDeviceAfterPatientDataUpdate.bind(this);
    this.updatePatientICPAData = this.updatePatientICPAData.bind(this);
    this.isPatientDataUpdated = this.isPatientDataUpdated.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { patientSearch, createPatientData } = this.props;
    if (patientSearch !== prevProps.patientSearch) {
      this.checkOverflow();
      if (patientSearch.status === SEARCH_PATIENT_NO_RESULTS) {
        this.showCreateNewPatientModal();
        this.fixOverlayState(true);
      }
    } else if (this.isPatientDataUpdated()) {
      this.assignDeviceAfterPatientDataUpdate();
    }

    if (createPatientData !== prevProps.createPatientData) {
      if (createPatientData.status === CREATE_PATIENT_SUCCESS) {
        this.emptyStateAndCloseModal(true);
      } else if (createPatientData.status === CREATE_PATIENT_FAIL) {
        this.setState({ error: createPatientData.error });
      }
    }
  }

  setSearchStatus(status) {
    this.props.actions.patientSearchResults({}, status);
  }

  setNewPatientData(formData) {
    this.setState({
      ...this.state,
      formData,
    });
  }

  setDobDatepickerData(dob) {
    const formattedDob = moment.utc(dob).format('YYYY-MM-DD');
    this.setState({ dob: formattedDob });
  }

  setFormInvalidState(formState) {
    this.setState(formState);
  }

  getElementsTitle = (id) => {
    const { t } = this.props;
    const elementsList = [
      { id: 'firstName', label: t('firstName') },
      { id: 'lastName', label: t('lastName') },
      { id: 'participantId', label: t('participantId') },
      { id: 'dateOfBirth', label: t('dateOfBirth') },
      { id: 'email', label: t('email') },
      { id: 'mrn', label: t('mrn') },
      { id: 't1dExchangeIdentifier', label: t('t1dExchangeIdentifier') },
      { id: 'phone', label: t('phone') },
      { id: 'address', label: t('address') },
      { id: 'diabetesType', label: t('diabetesType') },
      { id: 'gender', label: t('gender') },
    ];
    const elements = filter(elementsList, (item) => item.id === id);
    return elements.length > 0 && elements[0].label;
  }

  prepareMedicationSelector = (params, rowId) => {
    this.props.setPayloadForMedicationSelector(params, rowId);
    this.props.setDisplayInsulinSelector(true);
    this.emptyStateAndCloseModal(true);
  }

  assignDeviceAfterPatientDataUpdate() {
    const {
      rowData,
      updatePatientDataView,
    } = this.props;
    const { selectedBlock } = this.state;
    if (!updatePatientDataView && !isEmpty(selectedBlock)) {
      this.overrideMedicationAndAssignDevice(
        FindPatientModal.assignSelectedPatientPayload(selectedBlock, rowData),
        rowData.rowId,
        true,
      );
    }
    this.emptyStateAndCloseModal(true);
  }

  updateFormData(e) {
    const item = e.target;
    this.setState({
      ...this.state,
      [item.name]: item.value,
    });
  }

  updateSerialNumberCache(modalClosed) {
    if (!modalClosed) {
      this.emptyStateAndCloseModal(true);
    }
    return this.props.updateSerialNumberCache(false);
  }

  overrideMedicationAndAssignDevice = (params, rowId, modalClosed = false) => {
    if (this.props.medicationOverrideList) {
      const validateOnly = true;
      const updatedParams = { ...params, validateOnly };

      assignDeviceToPatient(this.props.actions.assignToPatientThunk, updatedParams, rowId,
        this.prepareMedicationSelector, this.detectedExisting, modalClosed);
    } else {
      assignDeviceToPatient(this.props.actions.assignToPatientThunk, params, rowId,
        this.updateSerialNumberCache, this.detectedExisting, modalClosed);
    }
  }

  detectedExisting(error) {
    if (error === 'mrn_already_taken') {
      this.setState({
        ...this.state,
        mrnExisted: true,
        mrnFormatValidationFailed: false,
        emailExisted: false,
        participantIdExisted: false,
        t1dExchangeIdentifierExisted: false,
      });
    } else if (error === 'mrn_format_validation_failed') {
      this.setState({
        ...this.state,
        mrnExisted: false,
        mrnFormatValidationFailed: true,
        emailExisted: false,
        participantIdExisted: false,
        t1dExchangeIdentifierExisted: false,
      });
    } else if (error === 'participant_id_already_taken') {
      this.setState({
        ...this.state,
        participantIdExisted: true,
        mrnExisted: false,
        mrnFormatValidationFailed: false,
        emailExisted: false,
        t1dExchangeIdentifierExisted: false,
      });
    } else if (error === 't1d_exchange_identifier_already_taken') {
      this.setState({
        ...this.state,
        t1dExchangeIdentifierExisted: true,
        participantIdExisted: false,
        mrnExisted: false,
        mrnFormatValidationFailed: false,
        emailExisted: false,
      });
    } else if (error === 'email_already_taken') {
      this.setState({
        ...this.state,
        t1dExchangeIdentifierExisted: false,
        participantIdExisted: false,
        mrnExisted: false,
        mrnFormatValidationFailed: false,
        emailExisted: true,
      });
    } else {
      this.setState({
        ...this.state,
        emailExisted: false,
        mrnExisted: false,
        mrnFormatValidationFailed: false,
        participantIdExisted: false,
      });
    }
  }

  hideCreateModalOnPatientView() {
    this.props.visibilityCallback(true);
    this.emptyStateAndCloseModal(true);
  }

  createPatient(payload) {
    this.setState({ ...emptyStateErrorObject });
    this.props.actions.createPatientThunk(payload, this.detectedExisting);
  }

  fixOverlayState(overflown) {
    this.setState({ fixOverflow: overflown });
  }

  searchResultsOverflowState(overflown) {
    this.setState({ searchResultsOverflow: overflown });
  }

  emptyFormData() {
    this.setState({ formData: {} });
  }

  showCreateNewPatientModal() {
    this.props.actions.updateCreateNewPatientModalVisibility(true);

    if (this.refBodyDiv) {
      this.refBodyDiv.scrollTop = 0;
    }

    this.fixOverlayState(true);
  }

  showUpdatePatientICPADetailsModal() {
    this.props.actions.updatePatientICPADetailsModalVisibility(true);
    this.fixOverlayState(true);
  }

  hideCreateNewPatientModal() {
    this.props.actions.updateCreateNewPatientModalVisibility(false);
  }

  hideUpdatePatientICPADetailsModal() {
    if (this.props.updatePatientDataView) {
      this.props.visibilityCallback();
      this.emptyStateAndCloseModal(true);
    }
  }

  emptyState() {
    this.setState({ ...emptyStateObject, ...emptyStateErrorObject });
  }

  hasPatients() {
    const { patientSearch } = this.props;
    return patientSearch.status === SEARCH_PATIENT_SUCCESS;
  }

  createFormNewPatient() {
    const { patientSearch, createNewPatientModalVisible } = this.props;
    if (
      createNewPatientModalVisible &&
      patientSearch.status === SEARCH_PATIENT_NO_RESULTS
    ) {
      return true;
    }
    return false;
  }

  checkInputIsRequired(input) {
    return this.props.icpaConfig[input] === 'required';
  }

  validateDob(formData) {
    const {
      ageOfMajority, updatePatientDataView, existingUserDetails, t,
    } = this.props;
    const isDobValid = moment
      .utc(formData.dob, TIME_FORMATS.YYYY_MM_DD, true)
      .isValid();

    if (updatePatientDataView && ageOfMajority &&
      existingUserDetails.dateOfBirth && isDobValid) {
      const dobNew = formData.dob;
      const dobOrig = existingUserDetails.dateOfBirth.split('T')[0];

      if (dobNew !== dobOrig) {
        const nowDate = moment().format().split('T')[0];
        const nowMinor = moment(nowDate).diff(dobNew, 'years', false) < ageOfMajority;
        const wasMajor = moment(nowDate).diff(dobOrig, 'years', false) >= ageOfMajority;

        if (wasMajor && nowMinor) {
          return { dobValid: false, dobMessage: t('cantChangeDobToMinor') };
        }
      }
    }

    if (this.checkInputIsRequired('dateOfBirth')) {
      return { dobValid: isDobValid };
    } else if (formData.dob) {
      return { dobValid: isDobValid };
    }
    return { dobValid: true };
  }

  validCreateForm() {
    const { pgs, icpaConfig } = this.props;
    const { formData } = this.state;
    const firstNameValid =
      !this.checkInputIsRequired('firstName') || formData.firstName;
    const lastNameValid =
      !this.checkInputIsRequired('lastName') || formData.lastName;
    const participantIdValid =
      !this.checkInputIsRequired('participantId') || formData.participantId;
    const { dobValid, dobMessage } = this.validateDob(formData);
    const mrnValid =
      !this.checkInputIsRequired('medicalRecordNumber') || formData.mrn;
    const todValid =
      !this.checkInputIsRequired('typeOfDiabetes') || formData.tod;
    let phoneValid = this.checkInputIsRequired('phoneNumber') && !formData.optOutPhoneInfo ?
      formData.phone?.length > 0 :
      true;
    phoneValid = isKeySetToNotDisplayed(icpaConfig, 'phoneNumber') ? true : phoneValid;
    const emailValid = this.checkInputIsRequired('email') && !formData.optOutEmailInfo ?
      EMAIL_REGEX.test(formData.email) :
      !formData.email || EMAIL_REGEX.test(formData.email);
    const reasonForEmailOptOutValid = formData.reasonForEmailOptOut || (!formData.optOutEmailInfo && emailValid);
    const reasonForPhoneOptOutValid = formData.reasonForPhoneOptOut || (!formData.optOutPhoneInfo && phoneValid);
    const consentValid =
      pgs.countryOfJurisdiction === 'can' && pgs.countryOfJurisdiction !== null ?
        formData.consent === true :
        true;
    const zipCodeInvalid =
      !this.checkInputIsRequired('zipCode') || formData.zipCode;
    const t1dExchangeIdentifierInvalid =
      !this.checkInputIsRequired('t1dExchangeIdentifier') ||
      formData.t1dExchangeIdentifier;
    const genderInvalid =
      !this.checkInputIsRequired('gender') || formData.gender;
    this.setState({
      invalidFields: {
        firstName: !firstNameValid,
        lastName: !lastNameValid,
        participantId: !participantIdValid,
        dob: !dobValid,
        mrn: !mrnValid,
        phone: !phoneValid,
        email: !emailValid,
        tod: !todValid,
        consent: !consentValid,
        zipCode: !zipCodeInvalid,
        t1dExchangeIdentifier: !t1dExchangeIdentifierInvalid,
        gender: !genderInvalid,
        reasonForEmailOptOut: !reasonForEmailOptOutValid,
        reasonForPhoneOptOut: !reasonForPhoneOptOutValid,
      },
    });
    this.setState({
      invalidFieldsMessages: {
        dob: dobMessage,
      },
    });

    return (
      firstNameValid &&
      lastNameValid &&
      participantIdValid &&
      dobValid &&
      mrnValid &&
      phoneValid &&
      emailValid &&
      todValid &&
      consentValid &&
      zipCodeInvalid &&
      t1dExchangeIdentifierInvalid &&
      genderInvalid &&
      reasonForEmailOptOutValid &&
      reasonForPhoneOptOutValid
    );
  }

  updatePatientICPAData(data) {
    const {
      savePatientData,
    } = this.props;
    this.props.actions.updatePatientICPADetailsThunk(
      data,
      this.detectedExisting,
      savePatientData,
    );

    this.props.actions.getRecentlySyncedUserICPAData(data.patient);
  }

  handleCloseReasonModal = () => {
    this.setState({ isReasonModalOpen: false });
  }

  handleReasonChange = (event) => {
    this.setState({ reasonForUpdate: event.target.value });
  }

  updatePatientDataWithReason = () => {
    const { formData, reasonForUpdate } = this.state;
    const { isReasonForChange, icpaConfig } = this.props;
    const userData = this.mapUpdatedUserData(formData);
    const userDataWithReason = {
      ...userData,
      user: {
        ...userData.user,
        ...(isReasonForChange && { reasonForChange: reasonForUpdate }),
      },
    };

    if (icpaConfig.reasonForChange === 'required' && !reasonForUpdate) {
      return;
    }

    this.updatePatientICPAData(userDataWithReason);
    this.setState({ isReasonForChange: false, isReasonModalOpen: false, reasonForUpdate: '' });
  }

  mapUpdatedUserData = (data) => {
    const {
      updatePatientDataView,
      existingUserDetails,
      icpaConfig,
    } = this.props;
    const { selectedBlock } = this.state;
    const requestJSON = {
      patient: updatePatientDataView ?
        existingUserDetails.id :
        selectedBlock.patientICPAData.id,
      user: {
        firstName: data.firstName,
        lastName: data.lastName,
        dateOfBirth: moment.utc(data.dob).toISOString(),
        email: data.email,
        mrn: data.mrn,
        participantId: data.participantId,
        diabetesType: data.tod ? data.tod.value : null,
        phone: data.phone,
        address: {
          zipcode: data.zipCode,
        },
        t1dExchangeIdentifier: data.t1dExchangeIdentifier,
        gender: data.gender ? data.gender.value : '',
        optOutEmailInfo: data.optOutEmailInfo,
        optOutPhoneInfo: data.optOutPhoneInfo,
        reasonForEmailOptOut: data.reasonForEmailOptOut,
        reasonForPhoneOptOut: data.reasonForPhoneOptOut,
      },
    };
    if (existingUserDetails.dobConfirmation || selectedBlock.dateOfBirth) {
      requestJSON.dobConfirmation = moment
        .utc(existingUserDetails.dobConfirmation || selectedBlock.dateOfBirth)
        .toISOString();
    }
    if (!requestJSON.user.optOutEmailInfo) {
      delete requestJSON.user.optOutEmailInfo;
    }
    if (!requestJSON.user.optOutPhoneInfo) {
      delete requestJSON.user.optOutPhoneInfo;
    }
    Object.keys(requestJSON.user).forEach((paramKey) => {
      const notDisplayedKey = returnNotDisplayedKeyFromParams(
        icpaConfig,
        paramKey,
        requestJSON.user,
      );
      if (notDisplayedKey) {
        delete requestJSON.user[notDisplayedKey];
      }
    });
    return requestJSON;
  }

  convertunDefinedToNull = (data) => {
    const keys = Object.keys(data);
    each(keys, (item) => {
      if (data[item] === undefined) {
        data[item] = null;
      }
    });
  }

  compareNewDataWithOldData = (newData) => {
    const { existingUserDetails, updatePatientDataView } = this.props;
    const { selectedBlock } = this.state;
    const modifiedData = [];
    this.convertunDefinedToNull(newData);
    const keys = Object.keys(newData);
    const oldData = updatePatientDataView ? existingUserDetails :
      selectedBlock.patientICPAData;
    each(keys, (item) => {
      let cleanExistingData = oldData[item] ||
        isEqual(oldData[item], newData[item]) ?
        oldData[item] : '';
      if (item === 'email') {
        cleanExistingData = isEmailFake(cleanExistingData) ?
          '' : cleanExistingData;
      }
      if (item === 'dateOfBirth') {
        cleanExistingData = newData.dateOfBirth &&
          newData.dateOfBirth.includes(cleanExistingData) ?
          newData.dateOfBirth : cleanExistingData;
      }
      if (!isEqual(cleanExistingData, newData[item])) {
        modifiedData.push(item);
      }
    });
    return modifiedData;
  }

  handleContinueButton() {
    const {
      createNewPatientModalVisible,
      patientICPADataUpdateFormVisible,
      rowData,
      pgs,
      patientView,
      icpaConfig,
      updatePatientDataView,
      isReasonForChange,
    } = this.props;
    const {
      firstName,
      lastName,
      dob,
      mrn,
      selectedBlock,
      matchCount,
      formData,
    } = this.state;
    const { terminalSyncs } = pgs.devices;
    const terminalSync = patientView ?
      null :
      FindPatientModal.searchTerminalSync(rowData, terminalSyncs);

    if (
      createNewPatientModalVisible ||
      patientView ||
      patientICPADataUpdateFormVisible ||
      updatePatientDataView
    ) {
      if (!this.validCreateForm()) {
        this.setFormInvalidState({ createFormInvalid: true });
      } else if (patientView) {
        this.createPatient(
          FindPatientModal.createPayload(pgs, formData, icpaConfig),
        );
      } else if (updatePatientDataView || patientICPADataUpdateFormVisible) {
        const userData = this.mapUpdatedUserData(formData);
        if (isReasonForChange) {
          const modifiedData = this.compareNewDataWithOldData(userData.user);
          if (modifiedData.length > 0) {
            this.setState({ isReasonModalOpen: true, modifiedDataKeys: modifiedData });
          } else {
            this.updatePatientICPAData(userData);
          }
        } else {
          this.updatePatientICPAData(userData);
        }
      } else {
        const { email, tod, phone } = formData;

        trackCreateModalButtonClicked(
          terminalSync,
          'assign',
          !!tod,
          !!email,
          !!phone,
        );
        this.overrideMedicationAndAssignDevice(
          FindPatientModal.createAndAssignPayload(rowData, pgs, formData),
          rowData.rowId,
        );
      }
    } else if (this.hasPatients()) {
      trackSelectPatientModalButtonClicked(
        terminalSync,
        selectedBlock.glookoCode,
        'continue',
        matchCount,
      );
      if (selectedBlock.id === 'create') {
        this.showCreateNewPatientModal();
      } else if (!patientHasICPARequiredData(icpaConfig, selectedBlock.patientICPAData)) {
        this.showUpdatePatientICPADetailsModal();
      } else {
        this.overrideMedicationAndAssignDevice(
          FindPatientModal.assignSelectedPatientPayload(selectedBlock, rowData),
          rowData.rowId,
          true,
        );
        this.emptyStateAndCloseModal(true);
      }
    } else if (!FindPatientModal.isFormDataValid(firstName, lastName, dob)) {
      this.setFormInvalidState({ searchFormInvalid: true });
    } else {
      trackFindModalButtonClicked(terminalSync, 'continue', !!mrn);
      this.searchForPatients(
        FindPatientModal.searchForPatientsPayload(firstName, lastName, dob, mrn),
      );
    }
  }

  checkOverflow() {
    const bodyRef = this.refBodyDiv;
    if (bodyRef) {
      const bodyOverflows = bodyRef.scrollHeight !== bodyRef.clientHeight;
      if (this.searchResultsDisplayed()) {
        this.searchResultsOverflowState(bodyOverflows);
      }
      this.fixOverlayState(bodyOverflows);
    }
  }

  emptyDataAndHideCreateSection() {
    this.emptyFormData();
    this.hideCreateNewPatientModal();
    this.setFormInvalidState({ createFormInvalid: false });
  }

  emptyStateAndSearchStatus() {
    this.emptyState();
    this.setFormInvalidState({ searchFormInvalid: false });
    this.setSearchStatus('');
  }

  goBack() {
    const {
      createNewPatientModalVisible,
      patientICPADataUpdateFormVisible,
      rowData,
    } = this.props;
    const { selectedBlock, matchCount } = this.state;
    const { terminalSyncs } = this.props.pgs.devices;
    const terminalSync = FindPatientModal.searchTerminalSync(
      rowData,
      terminalSyncs,
    );
    if (createNewPatientModalVisible) {
      const { email, tod, phone } = this.state.formData;

      trackCreateModalButtonClicked(
        terminalSync,
        'back',
        !!tod,
        !!email,
        !!phone,
      );
      if (this.createFormNewPatient()) {
        this.emptyDataAndHideCreateSection();
        this.emptyStateAndSearchStatus();
        this.fixOverlayState(false);
      } else {
        this.emptyDataAndHideCreateSection();
        this.fixOverlayState(this.state.searchResultsOverflow);
      }
    } else if (patientICPADataUpdateFormVisible) {
      this.props.actions.updatePatientICPADetailsModalVisibility(false);
      this.hideUpdatePatientICPADetailsModal();
    } else {
      trackSelectPatientModalButtonClicked(
        terminalSync,
        selectedBlock.glookoCode,
        'back',
        matchCount,
      );
      this.emptyStateAndSearchStatus();
    }
  }

  selectBlock(event, count) {
    const data = event.target.dataset;
    const { selectedBlock } = this.state;
    const patientICPAData =
      data.id === 'create' ? undefined : JSON.parse(data.icpadetails);
    this.setState({
      ...this.state,
      selectedBlock: {
        id: FindPatientModal.handleBlockSelection(selectedBlock.id, data.id),
        glookoCode: FindPatientModal.handleBlockSelection(
          selectedBlock.glookoCode,
          data.glookocode,
        ),
        dateOfBirth: data.dateofbirth,
        patientICPAData,
      },
      matchCount: count,
    });
  }

  isContinueActive() {
    const { selectedBlock, firstName, lastName, dob } = this.state;
    const { createNewPatientModalVisible } = this.props;

    if (createNewPatientModalVisible) return true;

    if (this.hasPatients()) {
      if (selectedBlock.id) {
        return true;
      }
      return false;
    }
    return FindPatientModal.isFormDataValid(firstName, lastName, dob);
  }

  searchForPatients(params) {
    this.props.actions.fetchPatientsThunk(params);
  }

  emptyStateAndCloseModal(assigned, e) {
    const { rowData, patientView, updatePatientDataView } = this.props;
    const { glookoCode } = this.state.selectedBlock;
    const { email, tod, phone } = this.state.formData;
    const { terminalSyncs } = this.props.pgs.devices;
    const terminalSync = patientView ?
      null :
      FindPatientModal.searchTerminalSync(rowData, terminalSyncs);

    if (!assigned && !this.props.patientView && !updatePatientDataView) {
      if (!this.hasPatients() && !this.createFormNewPatient()) {
        trackFindModalButtonClicked(terminalSync, 'cancel', !!this.state.mrn);
      } else if (this.createFormNewPatient()) {
        trackCreateModalButtonClicked(
          terminalSync,
          'cancel',
          !!tod,
          !!email,
          !!phone,
        );
      } else {
        trackSelectPatientModalButtonClicked(
          terminalSync,
          glookoCode,
          'cancel',
          this.state.matchCount,
        );
      }
    }
    if (!updatePatientDataView) {
      this.hideCreateNewPatientModal();
      this.props.actions.updatePatientICPADetailsModalVisibility(false);
      this.props.actions.resetPatientICPADetailsStatusInStore();
    }
    this.emptyState();
    this.setFormInvalidState({ searchFormInvalid: false });
    this.setSearchStatus('');
    this.props.visibilityCallback(e);
  }

  searchResultsDisplayed() {
    const { createNewPatientModalVisible } = this.props;
    return this.hasPatients() && !createNewPatientModalVisible;
  }

  sortByLastSyncTimestamp() {
    const { patientSearch } = this.props;
    const withTimestamp = [];
    const withoutTimestamp = [];
    const patients = patientSearch.patients.users;

    patients.filter((pat) => {
      if (get(pat, 'lastSyncTimestamp[1]', '')) {
        withTimestamp.push(pat);
      } else {
        withoutTimestamp.push(pat);
      }
      return null;
    });

    return [
      ...orderBy(withTimestamp, ['lastSyncTimestamp[1]'], ['desc']),
      ...withoutTimestamp,
    ];
  }

  handlePatientStatus(status) {
    const { t } = this.props;
    return status ? t('activated') : '';
  }

  returnEmailValue(email) {
    if (email) {
      return isEmailFake(email) ? '' : email;
    }
    return '';
  }

  isPatientDataUpdated() {
    const { selectedPatientICPADataUpdateStatus } = this.props;
    return (
      selectedPatientICPADataUpdateStatus === UPDATE_PATIENT_ICPA_DATA_SUCCESS
    );
  }

  isOfAge(dob, majorityAge) {
    const minAge = majorityAge || 16;
    const dobDate = dob.format().split('T')[0];
    const nowDate = moment().format().split('T')[0];
    return moment(nowDate).diff(dobDate, 'years', false) >= minAge;
  }

  renderSpinnerOverlay() {
    const {
      patientSearch,
      assignStatus,
      rowData,
      selectedPatientICPADataUpdateStatus,
    } = this.props;
    const currentSyncIsAssigning =
      assignStatus.status === 'LOADING' && rowData.rowId === assignStatus.rowId;
    const patientICPADataUpdateInProgress =
      selectedPatientICPADataUpdateStatus === UPDATE_PATIENT_ICPA_DATA_IN_PROGRESS;
    if (
      patientSearch.status === SEARCH_PATIENT_SEARCHING ||
      currentSyncIsAssigning ||
      patientICPADataUpdateInProgress
    ) {
      return (
        <div class={Style.spinnerContainer}>
          <Spinner style={{ backgroundSize: '60px', zIndex: 1010 }} dataAttributes={{ testid: 'find-patient' }} />
        </div>
      );
    }
    return null;
  }

  renderInputOrSearchResults() {
    const {
      patientSearch,
      createNewPatientModalVisible,
      patientICPADataUpdateFormVisible,
      patientView,
      updatePatientDataView,
      existingUserDetails,
      pgsCarePrograms,
    } = this.props;
    const { selectedBlock } = this.state;
    const searchStatus = patientSearch.status;
    if (updatePatientDataView || patientICPADataUpdateFormVisible) {
      const {
        activated,
        firstName,
        lastName,
        mrn,
        participantId,
        dateOfBirth,
        diabetesType,
        email,
        gender,
        phone,
        t1dExchangeIdentifier,
        address,
        reasonForPhoneOptOut,
        reasonForEmailOptOut,
      } = updatePatientDataView ?
          existingUserDetails :
          selectedBlock.patientICPAData;
      const dob = moment.utc(dateOfBirth).format('YYYY-MM-DD');
      return (
        <CreateNewPatientModal
          visible
          isEmailEditable={activated}
          visibilityCallback={this.hideUpdatePatientICPADetailsModal}
          returnPatientData={this.setNewPatientData}
          firstName={firstName || ''}
          lastName={lastName || ''}
          mrn={mrn || ''}
          participantId={participantId || ''}
          dateOfBirth={dob || ''}
          tod={diabetesType || ''}
          email={this.returnEmailValue(email)}
          reasonForEmailOptOut={reasonForEmailOptOut || ''}
          phone={phone || ''}
          reasonForPhoneOptOut={reasonForPhoneOptOut || ''}
          t1dExchangeIdentifier={t1dExchangeIdentifier || ''}
          zipCode={address ? address.zipcode : ''}
          gender={gender === undefined ? '' : gender}
          createFormInvalid={this.state.createFormInvalid}
          patientView={patientView}
          emailExisted={this.state.emailExisted}
          mrnExisted={this.state.mrnExisted}
          mrnFormatValidationFailed={this.state.mrnFormatValidationFailed}
          participantIdExisted={this.state.participantIdExisted}
          t1dExchangeIdentifierExisted={this.state.t1dExchangeIdentifierExisted}
          invalidFields={this.state.invalidFields}
          invalidFieldsMessages={this.state.invalidFieldsMessages}
          pgsCarePrograms={pgsCarePrograms}
        />
      );
    }
    if (patientView) {
      return (
        <CreateNewPatientModal
          visible
          isEmailEditable={false}
          visibilityCallback={this.hideCreateNewPatientModal}
          returnPatientData={this.setNewPatientData}
          firstName=""
          lastName=""
          dateOfBirth=""
          mrn=""
          participantId=""
          tod=""
          email=""
          phone=""
          t1dExchangeIdentifier=""
          zipCode=""
          gender=""
          createFormInvalid={this.state.createFormInvalid}
          patientView={patientView}
          emailExisted={this.state.emailExisted}
          participantIdExisted={this.state.participantIdExisted}
          t1dExchangeIdentifierExisted={this.state.t1dExchangeIdentifierExisted}
          mrnExisted={this.state.mrnExisted}
          mrnFormatValidationFailed={this.state.mrnFormatValidationFailed}
          invalidFields={this.state.invalidFields}
          invalidFieldsMessages={this.state.invalidFieldsMessages}
          pgsCarePrograms={pgsCarePrograms}
        />
      );
    }
    if (!searchStatus || searchStatus === SEARCH_PATIENT_SEARCHING) {
      return (
        <SearchPatientInput
          setDob={this.setDobDatepickerData}
          updateFormData={this.updateFormData}
        />
      );
    }
    if (this.searchResultsDisplayed()) {
      const sortedPatients = this.sortByLastSyncTimestamp();
      const { firstName, lastName, dob } = this.state;

      return (
        <PatientBlocks
          setDob={this.setDobDatepickerData}
          updateFormData={this.updateFormData}
          sortedPatients={sortedPatients}
          selectedBlock={selectedBlock}
          selectBlock={this.selectBlock}
          showCreateNewPatientModal={this.showCreateNewPatientModal}
          firstName={firstName}
          lastName={lastName}
          dob={dob}
        />
      );
    }
    return (
      <CreateNewPatientModal
        visible={createNewPatientModalVisible}
        isEmailEditable={false}
        visibilityCallback={this.hideCreateNewPatientModal}
        returnPatientData={this.setNewPatientData}
        firstName={this.state.firstName}
        lastName={this.state.lastName}
        mrn={this.state.mrn}
        participantId={this.state.participantId}
        dateOfBirth={this.state.dob}
        tod=""
        email=""
        phone=""
        t1dExchangeIdentifier=""
        zipCode=""
        gender=""
        createFormInvalid={this.state.createFormInvalid}
        patientView={patientView}
        emailExisted={this.state.emailExisted}
        mrnExisted={this.state.mrnExisted}
        mrnFormatValidationFailed={this.state.mrnFormatValidationFailed}
        participantIdExisted={this.state.participantIdExisted}
        t1dExchangeIdentifierExisted={this.state.t1dExchangeIdentifierExisted}
        invalidFields={this.state.invalidFields}
        pgsCarePrograms={pgsCarePrograms}
      />
    );
  }

  renderModalBody() {
    const {
      createNewPatientModalVisible,
      updatePatientDataView,
      patientView,
    } = this.props;
    const newPatient = this.createFormNewPatient();
    return (
      <div
        class={classNames({
          [Style.body]: true,
          [Style.extendHeight]:
            this.hasPatients() || newPatient || updatePatientDataView,
          [Style.fixOverflow]:
            this.state.fixOverflow ||
            createNewPatientModalVisible ||
            patientView ||
            updatePatientDataView,
          [Style.reduceHeight]: createNewPatientModalVisible || patientView,
        })}
        ref={(refBody) => {
          this.refBodyDiv = refBody;
        }}
      >
        {this.renderSpinnerOverlay()}
        {this.renderInputOrSearchResults()}
      </div>
    );
  }

  renderReasonForChangeModal = () => {
    const { isReasonModalOpen, modifiedDataKeys, reasonForUpdate } = this.state;
    const { t, isReasonForChange, icpaConfig } = this.props;
    const reasonForChangeRequired = icpaConfig.reasonForChange === 'required';
    const reasonForChangeLabel = reasonForChangeRequired ? t('reasonForChangeRequired') : t('reasonForChange');
    if (isReasonForChange) {
      return (
        <div>
          <Dialog
            size="medium"
            open={isReasonModalOpen}
            onClose={this.handleCloseReasonModal}
            className={Style.reasonForUpdateModalOverlay}
            dataAttributes={{ testid: 'find-patient' }}
          >
            <DialogHeader dataAttributes={{ testid: 'find-patient' }}><h2>{t('reasonModalTitle')}</h2></DialogHeader>
            <DialogContent dataAttributes={{ testid: 'find-patient' }}>
              {this.renderSpinnerOverlay()}
              <p className={Style.reasonForUpdateModalContext}>
                {t('reasonModalDescription')}
              </p>
              <ul className={Style.changeItems}>
                {modifiedDataKeys.map((item) => (<li key={item}>{this.getElementsTitle(item)}</li>))}
              </ul>
              <div>
                <div className={Style.reasonForChangeTextLabel}>{reasonForChangeLabel}</div>
                <textarea
                  placeholder={t('reasonForChangePlaceholder')}
                  onChange={this.handleReasonChange}
                  className={Style.reasonForChangeTextControl}
                />
              </div>
            </DialogContent>
            <DialogActions dataAttributes={{ testid: 'find-patient' }}>
              <Button
                className={Style.footerButton}
                variation='secondary'
                onClick={this.handleCloseReasonModal}
                dataAttributes={{ testid: 'find-patient-cancel' }}
              >
                {t('cancel')}
              </Button>
              <Button
                className={Style.footerButton}
                disabled={reasonForChangeRequired && !reasonForUpdate}
                onClick={this.updatePatientDataWithReason}
                dataAttributes={{ testid: 'find-patient-save' }}
              >
                {t('save')}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
    return null;
  }

  render() {
    if (!this.props.visible) {
      return null;
    }
    const {
      t,
      createNewPatientModalVisible,
      patientICPADataUpdateFormVisible,
      updatePatientDataView,
      patientView,
      isUpdate,
    } = this.props;
    return (
      <div className={Style.patientModalPage}>
        <WhiteOverlay />
        <div
          class={classNames({
            [Style.findPatientModal]: true,
            [Style.extendHeight]:
              this.hasPatients() ||
              this.createFormNewPatient() ||
              patientView ||
              updatePatientDataView,
          })}
        >
          <FindPatientHeaderPresenter
            createNewPatientModalVisible={
              createNewPatientModalVisible || patientView
            }
            updatePatientDataView={
              patientICPADataUpdateFormVisible || updatePatientDataView
            }
            createFormNewPatient={this.createFormNewPatient() || patientView}
            emptyStateAndCloseModal={(e) => this.emptyStateAndCloseModal(false, e)}
            hasPatients={this.hasPatients()}
            t={t}
            patientView={patientView}
            error={this.state.error}
          />
          {this.renderModalBody()}
          <FindPatientFooterPresenter
            createFormNewPatient={this.createFormNewPatient() || patientView}
            fixOverflow={this.state.fixOverflow}
            emptyStateAndCloseModal={(e) => this.emptyStateAndCloseModal(false, e)}
            goBack={this.goBack}
            handleContinueButton={this.handleContinueButton}
            hasPatients={this.hasPatients()}
            isContinueActive={
              this.isContinueActive() || patientView || updatePatientDataView
            }
            t={t}
            patientView={patientView}
            isUpdate={isUpdate}
          />
        </div>
        {this.renderReasonForChangeModal()}
      </div>
    );
  }
}

export const TranslatedFindPatientModal = translate('FindPatientModal')(FindPatientModal);
export default connect(mapStateToProps, mapDispatchToProps)(TranslatedFindPatientModal);
