import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import UserHelper from '../../../../../redux/modules/users/UserHelper';
import { trackSelectedAppsDevices } from '../../../../../services/eventLogging';

import image from './glooko-uploader.png';
import Style from '../PartnerIntegrationsCard.scss';

const mapStateToProps = (state: State) => ({
  isPatient: UserHelper.currentUserIsPatient(state),
  isGlookoResearchSite: UserHelper.currentUser(state).glookoResearchSite,
});

const GlookoUploaderButton = (props: ReturnType<typeof mapStateToProps>) => {
  const { isPatient, isGlookoResearchSite } = props;
  const { t } = useTranslation('GlookoUploaderButton');

  if (!isPatient) {
    return null;
  }

  const onClick = () => {
    trackSelectedAppsDevices(isGlookoResearchSite ? 'Glooko Research Uploader' : 'Glooko Uploader');
    window.open(isGlookoResearchSite ? '/research_uploader' : '/uploader');
  };

  return (
    <button
      className={Style.button}
      onClick={onClick}
      type="button"
    >
      <div className={Style.buttonContent}>
        <img alt="integration" className={Style.buttonImage} src={image} />

        <div className={Style.buttonName}>
          {isGlookoResearchSite ? t('getGlookoResearchUploader') : t('getGlookoUploader')}
        </div>
      </div>
    </button>
  );
};

export default connect(mapStateToProps)(GlookoUploaderButton);
