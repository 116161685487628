import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { map } from 'lodash';
import { TIME_FORMATS } from '~/utils/i18nFormats';
import Style from './OverviewAxisAbove.scss';

const hours = () => ([
  moment.utc().startOf('day').add(0, 'hours').format(TIME_FORMATS.H_AA),
  moment.utc().startOf('day').add(3, 'hours').format(TIME_FORMATS.H),
  moment.utc().startOf('day').add(6, 'hours').format(TIME_FORMATS.H),
  moment.utc().startOf('day').add(9, 'hours').format(TIME_FORMATS.H),
  moment.utc().startOf('day').add(12, 'hours').format(TIME_FORMATS.H_AA),
  moment.utc().startOf('day').add(15, 'hours').format(TIME_FORMATS.H),
  moment.utc().startOf('day').add(18, 'hours').format(TIME_FORMATS.H),
  moment.utc().startOf('day').add(21, 'hours').format(TIME_FORMATS.H),
]);
const nextMidnight = (weekView) => (
  weekView ? [moment.utc().startOf('day').add(0, 'hours').format(TIME_FORMATS.H_AA)] : []
);

const OverviewAxisAbove = (props) => {
  const { height, weekView, pdf } = props;

  const weekViewClass = classNames({
    [Style.weekViewDay]: weekView,
    [Style.weekViewDayWeb]: weekView && !pdf,
    [Style.weekViewDayPdf]: weekView && pdf,
  });

  const pxHeight = `${height}px`;
  const dayStyle = { height: pxHeight, lineHeight: pxHeight };

  return (
    <div class={Style.OverviewAxisAbove}>
      {map(hours().concat(nextMidnight(weekView)), (element, index) => (
        <div key={index} style={dayStyle} class={weekView ? weekViewClass : Style.day}>
          {element}
        </div>
      ))}
    </div>
  );
};

OverviewAxisAbove.propTypes = {
  weekView: PropTypes.bool,
  pdf: PropTypes.bool,
  height: PropTypes.number,
};

OverviewAxisAbove.defaultProps = {
  weekView: false,
  pdf: false,
  height: 32,
};

export default OverviewAxisAbove;
