import React from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import moment from 'moment';
import { Button, Dialog, DialogActions, DialogContent, DialogHeader, Select } from '@glooko/common-ui';
import { Tooltip } from 'react-tooltip';
import { FETCH_STATUS_SUCCESSFUL, FETCH_STATUS_IN_PROGRESS } from '~/bundles/shared/constants/graphs';
import FindPatientModal from '~/bundles/poptracker/FindPatientModal/FindPatientModal.jsx';
import { trackViewCarePrograms, trackAssignCareProgram, trackUnassignCareProgram } from '~/services/eventLogging.js';
import { updateUnAssignCareProgram, updateAssignCareProgram } from '~/services/usersApi';
import { truncateText } from '~/utils/textHelper.js';
import { patientHasICPARequiredData } from '~/utils/ICPAConfig.js';
import { TIME_FORMATS } from '~/utils/i18nFormats';
import { translate } from 'bundles/shared/components/WithTranslate/WithTranslate';
import Spinner from '~/bundles/shared/components/Spinner/Spinner';
import Style from './CareProgramsPresenter.scss';

export const ASSIGN = 'ASSIGNED';
export const UNASSIGN = 'UNASSIGNED';
export const NOT_ASSIGN = 'NOT_ASSIGNED';

class CareProgramsPresenter extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    patientProfile: PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired,
    fetchPatientCarePrograms: PropTypes.func.isRequired,
    pgsCarePrograms: PropTypes.array.isRequired,
    patientCarePrograms: PropTypes.object,
    handleCloseModal: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    patientICPAData: PropTypes.object,
    icpaConfigFields: PropTypes.object,
    getPatientICPAData: PropTypes.func.isRequired,
    category: PropTypes.string.isRequired,
  }

  static defaultProps = {
    t: (s) => s,
    patientCarePrograms: {
      carePrograms: [],
      status: '',
    },
    patientICPAData: {
      userDetails: {},
      status: '',
    },
    icpaConfigFields: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      isNotificationBox: false,
      statusAndAction: {},
      selectedCareProgram: {},
      errorMessage: '',
      errorMessageBox: false,
      isChangeState: false,
      isUpdatePatientModalVisible: false,
      unassignReason: null,
    };
  }

  closeNotificationBox = () => {
    this.setState({ isNotificationBox: false, unassignReason: null });
  }

  openNotificationBox = (item, statusAndAction) => {
    const { patientICPAData, icpaConfigFields } = this.props;
    const { userDetails } = patientICPAData;
    const isValidICPAData = patientHasICPARequiredData(icpaConfigFields, userDetails);

    if (!statusAndAction.actionEnabled) {
      return;
    }

    if (statusAndAction && statusAndAction.actionId === ASSIGN && !isValidICPAData) {
      this.setState({
        isUpdatePatientModalVisible: true,
        selectedCareProgram: item,
        statusAndAction,
      });
    } else {
      this.setState({
        isNotificationBox: true,
        selectedCareProgram: item,
        statusAndAction,
      });
    }
  }

  savePatientData = () => {
    const { getPatientICPAData, patientProfile } = this.props;
    getPatientICPAData(patientProfile.id);
    this.setState({
      isNotificationBox: true,
      isUpdatePatientModalVisible: false,
    });
  }

  hideCreatePatientModal = () => {
    const { handleCloseModal } = this.props;
    this.setState({
      isUpdatePatientModalVisible: false,
    });
    handleCloseModal();
  }

  getStatusDate = (date) => {
    const { t } = this.props;
    const assignOrUnAssignDate = moment(date);
    const today = moment().startOf('day');
    const yesterday = moment().utc().subtract(1, 'days').startOf('day');
    let dateStr;
    if (moment(assignOrUnAssignDate).isSame(today, 'd')) {
      dateStr = t('today');
    } else if (moment(assignOrUnAssignDate).isSame(yesterday, 'd')) {
      dateStr = t('yesterday');
    } else {
      dateStr = moment(assignOrUnAssignDate).format(TIME_FORMATS.MM_DD_YYYY);
    }
    return dateStr;
  }

  getFullName = () => {
    const { patientProfile } = this.props;
    return `${patientProfile.firstName} ${patientProfile.lastName}`;
  }

  submitAssignOrUnAssign = () => {
    const { statusAndAction, selectedCareProgram, unassignReason } = this.state;
    const { patientProfile, fetchPatientCarePrograms, category } = this.props;
    const { actionId } = statusAndAction;
    const payload = {
      patient: patientProfile.id,
      careProgramId: selectedCareProgram.id,
    };
    if (actionId === ASSIGN) {
      updateAssignCareProgram(payload).then((res) => {
        if (res.status === 200) {
          fetchPatientCarePrograms({ patient: patientProfile.id });
        }
      }).catch((error) => {
        this.setState({ errorMessageBox: true, errorMessage: error.response.data.error });
      });
      trackAssignCareProgram(category, selectedCareProgram.name);
    } else if (actionId === UNASSIGN) {
      const unassignReasonParam = selectedCareProgram.requireUnassignReason ?
        { unassignReason: unassignReason.label } :
        {};
      const unassignPayload = Object.assign({}, payload, unassignReasonParam);
      updateUnAssignCareProgram(unassignPayload).then((res) => {
        if (res.status === 204) {
          fetchPatientCarePrograms({ patient: patientProfile.id });
        }
      }).catch((error) => {
        this.setState({ errorMessageBox: true, errorMessage: error.response.data.error });
      });
      trackUnassignCareProgram(category, selectedCareProgram.name);
    }
    this.setState({ isChangeState: true });
    this.closeNotificationBox();
  }

  handleCloseErrorModal = () => {
    this.setState({ errorMessageBox: false });
  }

  renderErrorMessage = () => {
    const { t, handleCloseModal } = this.props;
    const { statusAndAction, errorMessageBox, errorMessage } = this.state;
    if (statusAndAction && statusAndAction.actionId) {
      const title = statusAndAction.actionId === ASSIGN ?
        t('assignProgramError') : t('unAssignProgramError');
      return (
        <Dialog
          size='small'
          open={errorMessageBox}
          onClose={() => handleCloseModal(this.handleCloseErrorModal)}
          dataAttributes={{ testid: 'care-programs' }}
        >
          <DialogHeader dataAttributes={{ testid: 'care-programs' }}><h2>{title}</h2></DialogHeader>
          <DialogContent dataAttributes={{ testid: 'care-programs' }}>
            <div className={Style.context}>
              {errorMessage}
            </div>
          </DialogContent>
          <DialogActions dataAttributes={{ testid: 'care-programs' }}>
            <Button
              onClick={this.handleCloseErrorModal}
              dataAttributes={{ testid: 'care-programs-close' }}
            >
              {t('close')}
            </Button>
          </DialogActions>
        </Dialog>

      );
    } return null;
  }

  onUnassignReasonChange = (unassignReason) => {
    this.setState({ unassignReason });
  }

  renderUnassignReasonChoices = () => {
    const { t } = this.props;
    const { selectedCareProgram } = this.state;

    if (!selectedCareProgram.requireUnassignReason) {
      return null;
    }

    const reasons = selectedCareProgram.unassignReasons.map((reason, index) => (
      { label: reason, value: index }
    ));

    return (
      <div className={Style.reasonSelect}>
        <div>{t('reason')}</div>
        <Select
          options={reasons}
          defaultValue={this.state.unassignReason}
          placeholder={t('reasonPlaceholder')}
          onChange={this.onUnassignReasonChange}
        />
      </div>
    );
  }

  getNotificationContext = () => {
    const { t } = this.props;
    const { isNotificationBox, statusAndAction, selectedCareProgram } = this.state;
    if (statusAndAction && statusAndAction.actionId) {
      const { actionId } = statusAndAction;
      let popupTitle = '';
      let okButtonTitle = '';
      let content = '';
      if (actionId === ASSIGN || actionId === NOT_ASSIGN) {
        okButtonTitle = t('assign');
        content = t('assignDesc', { name: this.getFullName(), careProgramName: selectedCareProgram.nameUiDisplay });
        popupTitle = t('assignProgram');
      } else if (actionId === UNASSIGN) {
        okButtonTitle = t('unassign');
        content = (
          <div>
            <div>
              {t('unassignDesc', { name: this.getFullName(), careProgramName: selectedCareProgram.nameUiDisplay })}
            </div>
            {this.renderUnassignReasonChoices()}
          </div>
        );
        popupTitle = t('unassignProgram');
      }

      return (
        <Dialog
          size="medium"
          open={isNotificationBox}
          onClose={this.closeNotificationBox}
          dataAttributes={{ testid: 'care-programs-popup' }}
        >
          <DialogHeader dataAttributes={{ testid: 'care-programs-popup' }}><h2>{popupTitle}</h2></DialogHeader>
          <DialogContent dataAttributes={{ testid: 'care-programs-popup' }}>
            <div className={Style.context}>
              {content}
            </div>
          </DialogContent>
          <DialogActions dataAttributes={{ testid: 'care-programs-popup' }}>
            <Button
              variation='secondary'
              onClick={this.closeNotificationBox}
              dataAttributes={{ testid: 'care-programs-popup-cancel' }}
            >
              {t('cancel')}
            </Button>
            <Button
              onClick={this.submitAssignOrUnAssign}
              disabled={
                actionId === UNASSIGN &&
                selectedCareProgram.requireUnassignReason &&
                !this.state.unassignReason
              }
              dataAttributes={{ testid: 'care-programs-popup-ok' }}
            >
              {okButtonTitle}
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  }

  getStatusAndActionValue = (careId) => {
    const { patientCarePrograms, t } = this.props;
    const existingPatientCarePrograms = filter(patientCarePrograms.carePrograms,
      (item) => item.careProgram && item.careProgram.id === careId);
    const getName = (provider) => (provider ? `${provider.firstName} ${provider.lastName}` : null);
    let status = '';
    let action = '';
    let actionId = '';
    let reason;
    let actionEnabled = true;

    if (existingPatientCarePrograms.length === 0) {
      status = t('notAssigned');
      action = t('assign');
      actionId = ASSIGN;
      actionEnabled = this.canAssignCareProgram(careId);
    } else {
      const existingPatientCareProgram = existingPatientCarePrograms[0];
      if (existingPatientCareProgram.status === 'active') {
        const providerName = getName(existingPatientCareProgram.assigningProvider);
        const assignedStr = providerName ? t('assignedBy', { name: providerName }) : t('assigned');
        status = `${assignedStr}, ${this.getStatusDate(existingPatientCareProgram.startedAt)}`;
        action = t('unassign');
        actionId = UNASSIGN;
      } else if (existingPatientCareProgram.status === 'inactive') {
        const providerName = getName(existingPatientCareProgram.unassigningProvider);
        const unassignedStr = providerName ? t('unassignedBy', { name: providerName }) : t('unassigned');
        status = `${unassignedStr}, ${this.getStatusDate(existingPatientCareProgram.endedAt)}`;
        action = t('assign');
        actionId = ASSIGN;
        reason = existingPatientCareProgram.unassignReason;
        actionEnabled = this.canAssignCareProgram(careId);
      }
    }

    return { status, action, actionId, actionEnabled, reason };
  }

  canAssignCareProgram = (careProgramId) => {
    const { pgsCarePrograms, patientCarePrograms } = this.props;
    const careProgram = pgsCarePrograms.find((program) => program.id === careProgramId);
    const patientActivePrograms = patientCarePrograms.carePrograms.filter(
      (program) => program.status === 'active',
    );
    const patientActiveOnlyPrograms = patientActivePrograms.filter(
      (program) => program.careProgram.onlyProgramActive,
    );

    return !(patientActiveOnlyPrograms.length > 0 ||
      (careProgram.onlyProgramActive && patientActivePrograms.length > 0));
  }

  redirectToCareProgramSite = (url, name) => {
    window.open(url, '_blank');
    trackViewCarePrograms(name);
  }

  renderTableRows = (item) => {
    const statusAndAction = this.getStatusAndActionValue(item.id);
    const careProgramTitleLength = 24;
    return (
      <tr key={item.id}>
        <td
          className={Style.careProgramCol}
          onClick={() => this.redirectToCareProgramSite(item.clinicianProgramPageUrl, item.name)}
        >
          <div
            className={Style.careProgramTitle}
            data-tooltip-variant='light'
            data-tooltip-id={`careProgramName-${item.id}`}
            data-tooltip-content={item.nameUiDisplay}
            data-tooltip-float={true}
          >
            {truncateText(item.nameUiDisplay, careProgramTitleLength)}
          </div>
          <Tooltip
            id={`careProgramName-${item.id}`}
            disable={item.nameUiDisplay.length < careProgramTitleLength}
          />
          <p className={Style.careProgramDesc}>
            {truncateText(item.description, 140)}
          </p>
        </td>
        <td>
          <span>
            {statusAndAction.status}
          </span>
          {statusAndAction.reason &&
            <span>
              <br />
              <span className={Style.reason}>{statusAndAction.reason}</span>
            </span>}
        </td>
        <td>
          <Button
            onClick={() => this.openNotificationBox(item, statusAndAction)}
            className={Style.actionButton}
            disabled={!statusAndAction.actionEnabled}
            variation='link'
            dataAttributes={{ testid: 'care-programs-status' }}
          >
            {statusAndAction.action}
          </Button>
        </td>
      </tr>
    );
  }

  render() {
    const { t, pgsCarePrograms, handleCloseModal, isModalOpen, patientCarePrograms, patientICPAData } = this.props;
    const { status } = patientCarePrograms;
    const { isChangeState, isUpdatePatientModalVisible } = this.state;
    const { userDetails } = patientICPAData;
    return (
      <div>
        <Dialog
          size='large'
          open={isModalOpen}
          onClose={() => handleCloseModal(isChangeState)}
          focusTrap={{ active: false }}
          dataAttributes={{ testid: 'care-programs-fullname' }}
        >
          <DialogHeader dataAttributes={{ testid: 'care-programs-fullname' }}><h2>{t('title', { name: this.getFullName() })}</h2></DialogHeader>
          <DialogContent className={Style.dialogContent} dataAttributes={{ testid: 'care-programs-fullname' }}>
            {status === FETCH_STATUS_SUCCESSFUL &&
              <table className={Style.careProgramsTable}>
                <thead>
                  <tr>
                    <th> {t('careProgram')} </th>
                    <th> {t('status')}</th>
                    <th> {t('actions')}</th>
                  </tr>
                </thead>
                <tbody>
                  {pgsCarePrograms.map((item) => this.renderTableRows(item))}
                </tbody>
              </table>}
            {status === FETCH_STATUS_IN_PROGRESS && <Spinner style={{ position: 'absolute' }} />}
            {this.getNotificationContext()}
            {this.renderErrorMessage()}
          </DialogContent>
          <DialogActions dataAttributes={{ testid: 'care-programs-fullname' }}>
            <Button
              onClick={() => handleCloseModal(isChangeState)}
              dataAttributes={{ testid: 'care-programs-fullname-close' }}
            >
              {t('close')}
            </Button>
          </DialogActions>
        </Dialog>
        {isUpdatePatientModalVisible &&
          patientICPAData.status === 'CURRENT_PATIENT_ICPA_DATA_FETCH_SUCCESS' &&
          <FindPatientModal
            visible
            visibilityCallback={this.hideCreatePatientModal}
            updatePatientDataView={isUpdatePatientModalVisible}
            existingUserDetails={userDetails}
            savePatientData={this.savePatientData}
          />}
      </div>
    );
  }
}

export default translate('CareProgramsPresenter')(CareProgramsPresenter);
