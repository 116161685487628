import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Readings from 'services/Readings';
import ImageStore from 'utils/ImageStore';
import { TYPE_BG, TYPE_CGM } from 'bundles/shared/constants/readings';
import { numberFormatterOneDecimal, TIME_FORMATS } from 'utils/i18nFormats';
import Style from './BloodGlucoseTooltip.module.scss';

export interface BloodGlucoseTooltipProps {
  point: {
    options: {
      value: number;
    };
    mealTag?: 'before' | 'after' | 'none';
    type?: string;
    timestamp: string;
    color: string;
    series: {
      name: string;
    },
  };
  meterUnits: string;
}

const BloodGlucoseTooltip = ({ point, meterUnits }: BloodGlucoseTooltipProps) => {
  const { t } = useTranslation('BloodGlucoseTooltip');

  const formatTimestamp = (timestamp: string) => moment.utc(timestamp).format(TIME_FORMATS.MMMM_DO_YYYY_H_MMA as unknown as string);

  const determineReadingsType = (seriesName: string) => (seriesName.includes('cgm') ? TYPE_CGM : TYPE_BG);

  const getMealTagImage = (mealTag: BloodGlucoseTooltipProps['point']['mealTag']) => {
    if (mealTag === 'before') return ImageStore.BeforeMealApple;
    if (mealTag === 'after') return ImageStore.AfterMealApple;
    return null;
  };

  const getReadingsTypeText = (type: string | undefined) => (type === 'manual' ? ` - ${t('pumpBgEntry')}` : '');

  const timeDisplay = formatTimestamp(point.timestamp);
  const readingsType = determineReadingsType(point.series.name);
  const mealTagImage = readingsType === TYPE_BG ? getMealTagImage(point.mealTag) : null;
  const readingsTypeText = getReadingsTypeText(point.type);

  return (
    <section className={Style.container}>
      <div className={Style.row}>
        <span className={Style.number} style={{ color: point.color }}>
          {numberFormatterOneDecimal(Readings.displayWithHiLo(point.options.value, meterUnits, readingsType))}
        </span>

        {' '}
        <span style={{ color: point.color }}>{Readings.displayMeterUnits(meterUnits)}</span>

        {mealTagImage ? (
          <img alt={point.mealTag} className={Style.mealAppleImg} src={mealTagImage} />
        ) : null}

        <span>{readingsTypeText}</span>
      </div>

      <div className={Style.row}>
        <span className={Style.date}>{timeDisplay}</span>
      </div>
    </section>
  );
};

export default BloodGlucoseTooltip;
