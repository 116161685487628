// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".LinkToDevicesTab_linkToDevicesTab{font-weight:bold}", "",{"version":3,"sources":["LinkToDevicesTab.scss"],"names":[],"mappings":"AAAA,mCACE,gBAAA","file":"LinkToDevicesTab.scss","sourcesContent":[".linkToDevicesTab {\n  font-weight: bold;\n}\n"]}]);
// Exports
exports.locals = {
	"linkToDevicesTab": "LinkToDevicesTab_linkToDevicesTab"
};
module.exports = exports;
