// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".BloodGlucoseTooltip-module_container{padding:16px;font-size:12px}.BloodGlucoseTooltip-module_number{font-size:14px;font-weight:600}.BloodGlucoseTooltip-module_date{color:var(--text-secondary)}.BloodGlucoseTooltip-module_row:not(:last-child){padding-bottom:8px}.BloodGlucoseTooltip-module_mealAppleImg{height:12px;-webkit-padding-start:8px;padding-inline-start:8px}", "",{"version":3,"sources":["BloodGlucoseTooltip.module.scss"],"names":[],"mappings":"AAAA,sCACE,YAAA,CACA,cAAA,CAGF,mCACE,cAAA,CACA,eAAA,CAGF,iCACE,2BAAA,CAGF,iDACE,kBAAA,CAGF,yCACE,WAAA,CACA,yBAAA,CAAA,wBAAA","file":"BloodGlucoseTooltip.module.scss","sourcesContent":[".container {\n  padding: 16px;\n  font-size: 12px;\n}\n\n.number {\n  font-size: 14px;\n  font-weight: 600;\n}\n\n.date {\n  color: var(--text-secondary);\n}\n\n.row:not(:last-child){\n  padding-bottom: 8px;\n}\n\n.mealAppleImg {\n  height: 12px;\n  padding-inline-start: 8px;\n}\n"]}]);
// Exports
exports.locals = {
	"container": "BloodGlucoseTooltip-module_container",
	"number": "BloodGlucoseTooltip-module_number",
	"date": "BloodGlucoseTooltip-module_date",
	"row": "BloodGlucoseTooltip-module_row",
	"mealAppleImg": "BloodGlucoseTooltip-module_mealAppleImg"
};
module.exports = exports;
