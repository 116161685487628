import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from '@reduxjs/toolkit';
import { setUpPage } from '~/redux/thunks/page';
import ConsentsManagerContainer from '~/bundles/consents/components/ConsentsManagerContainer.jsx';
import IpgRestrictedContainer from '~/bundles/insulet/ipg/IpgRestrictedContainer.jsx';
import { connect } from 'react-redux';
import Braze from '../../../../services/braze';
import IdleTimerPopover from '../IdleTimer/IdleTimerPopover';

const mapStateToProps = (state, ownProps) => ({
  startDate: state.page.startDate,
  endDate: state.page.endDate,
  endDateCommon: state.page.endDateCommon,
  page: ownProps.page,
  user: state.users.currentUsers.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setUpPage }, dispatch),
});

export class PageContainer extends Component {
  static propTypes = {
    user: PropTypes.object,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    endDateCommon: PropTypes.string,
    content: PropTypes.func,
    contentProps: PropTypes.object,
    page: PropTypes.string.isRequired,
    includeEndDate: PropTypes.bool.isRequired,
    includeSubscription: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      setUpPage: PropTypes.func.isRequired,
    }),
  };

  static defaultProps = {
    user: null,
    startDate: null,
    endDate: null,
    endDateCommon: null,
    includeEndDate: false,
    includeSubscription: true,
    content: null,
    contentProps: null,
    actions: {
      setUpPage: () => { },
    },
  };

  componentDidMount() {
    const { actions, page, includeEndDate, includeSubscription } = this.props;
    actions.setUpPage(page, includeEndDate, includeSubscription);
    window.appboy = Braze().init();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user.id !== this.props.user.id && this.props.user.id) {
      const { user } = this.props;
      if (window.appboy) {
        window.appboy.changeUser(user.glookoCode);
        window.appboy.registerAppboyPushMessages();
      }
    }
  }

  render() {
    const { contentProps, endDate, endDateCommon, startDate, includeEndDate } = this.props;
    const endDatesFetched = !includeEndDate || (endDateCommon && startDate && endDate);
    const ChildComp = this.props.content;
    if (endDatesFetched && ChildComp) {
      return (
        <div>
          <IpgRestrictedContainer />
          <IdleTimerPopover />
          <ConsentsManagerContainer />
          <ChildComp {...contentProps} />
        </div>
      );
    }
    return (<IdleTimerPopover />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageContainer);
