import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { fetchPatientCareProgramsThunk } from '~/redux/thunks/providerGroupSite/providerGroupSite.js';
import { getCurrentPatientICPADataThunk } from '~/redux/thunks/patientICPADetails.js';
import CareProgramsPresenter from './../CareProgramsPresenter/CareProgramsPresenter';

const mapStateToProps = (state) => ({
  patientCarePrograms: state.providerGroupSite.patientCarePrograms,
  pgsCarePrograms: state.providerGroupSite.carePrograms,
  patientICPAData: state.providerGroupSite.currentPatientICPADetails,
  icpaConfigFields: state.providerGroupSite.icpaConfig,
});
const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    fetchPatientCarePrograms: fetchPatientCareProgramsThunk,
    getPatientICPAData: getCurrentPatientICPADataThunk,
  },
  dispatch,
);

export class CareProgramsContainer extends React.Component {
  static propTypes = {
    patientCarePrograms: PropTypes.object,
    patientProfile: PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired,
    fetchPatientCarePrograms: PropTypes.func.isRequired,
    pgsCarePrograms: PropTypes.array,
    handleCloseModal: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    isCallPatientCarePrograms: PropTypes.bool,
    getPatientICPAData: PropTypes.func.isRequired,
    patientICPAData: PropTypes.object,
    icpaConfigFields: PropTypes.object,
    category: PropTypes.string.isRequired,
  }

  static defaultProps = {
    patientCarePrograms: {
      carePrograms: [],
      status: '',
    },
    pgsCarePrograms: [],
    isCallPatientCarePrograms: true,
    patientICPAData: {
      userDetails: {},
      status: '',
    },
    icpaConfigFields: {},
  }

  UNSAFE_componentWillMount() {
    const { patientProfile, fetchPatientCarePrograms, isCallPatientCarePrograms } = this.props;
    const { id } = patientProfile;
    if (id && isCallPatientCarePrograms) {
      fetchPatientCarePrograms({ patient: id });
    }
    this.getCurrentUserICPAFields(id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { patientProfile, fetchPatientCarePrograms, isCallPatientCarePrograms } = this.props;
    if (nextProps.patientProfile.id !== patientProfile.id && isCallPatientCarePrograms) {
      fetchPatientCarePrograms({ patient: nextProps.patientProfile.id });
      this.getCurrentUserICPAFields(nextProps.patientProfile.id);
    }
  }

  getCurrentUserICPAFields = (id) => {
    const { getPatientICPAData } = this.props;
    if (id) {
      getPatientICPAData(id);
    }
  }

  render() {
    const { pgsCarePrograms } = this.props;
    if (pgsCarePrograms && pgsCarePrograms.length > 0) {
      return (
        <CareProgramsPresenter
          {...this.props}
        />
      );
    }
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CareProgramsContainer);
