import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from '@reduxjs/toolkit';
import { updateDialogVisibility } from 'redux/modules/omnipod/omnipod';
import UserHelper from '../../../../../redux/modules/users/UserHelper';
import { trackSelectedAppsDevices } from '../../../../../services/eventLogging';

import image from './omnipod-icon.png';
import Style from '../PartnerIntegrationsCard.scss';

const mapStateToProps = (state: State) => ({
  isPatient: UserHelper.currentUserIsPatient(state),
  hasSubscription: state.providerGroupSite.subscriptionModel.features.includes('OmniPod Web Upload'),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ updateDialogVisibility }, dispatch),
});

const OmnipodPdmButton = (props: ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>) => {
  const { isPatient, hasSubscription, actions } = props;
  const { t } = useTranslation('OmnipodPdmButton');

  if (!isPatient && !hasSubscription) {
    return null;
  }

  const onClick = () => {
    trackSelectedAppsDevices('Omnipod® PDM');
    actions.updateDialogVisibility(true);
  };

  return (
    <button
      className={Style.button}
      onClick={onClick}
      type="button"
    >
      <div className={Style.buttonContent}>
        <img alt="Omnipod® PDM" className={Style.buttonImage} src={image} />

        <div className={Style.buttonName}>
          {t('uploadOmnipodSystemData')}
        </div>
      </div>
    </button>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OmnipodPdmButton);
