export const UPDATE = 'UPDATE';
export const UPDATE_CLINIC_AUTHORIZATION = 'UPDATE_CLINIC_AUTHORIZATION';
export const UPDATE_CONFIRMED_AND_AUTHORIZE = 'UPDATE_CONFIRMED_AND_AUTHORIZE';
export const UPDATE_MY_CLINIC = 'UPDATE_MY_CLINIC';
export const SHARE_DATA_WITH_INSULET = 'SHARE_DATA_WITH_INSULET';
export const SET_PAGE = 'SET_PAGE';
export const PAGE_CONFIRM_AND_AUTHORIZE = 'PAGE_CONFIRM_AND_AUTHORIZE';
export const PAGE_PGS_NOT_FOUND = 'PAGE_PGS_NOT_FOUND';
export const PAGE_CONNECTED = 'PAGE_CONNECTED';
export const PAGE_SHARE_DATA_WITH_INSULET = 'PAGE_SHARE_DATA_WITH_INSULET';
export const RESET = 'RESET';
export const ALREADY_LINKED_TO_OTHER = 'ALREADY_LINKED_TO_OTHER';
export const CONFIRM_ACCOUNT = 'CONFIRM_ACCOUNT';
export const SET_MINOR = 'SET_MINOR';
export const SET_GUARDIAN_EMAIL = 'SET_GUARDIAN_EMAIL';

export function update(newState) {
  return { type: UPDATE, payload: { newState } };
}

export function reset() {
  return { type: RESET };
}

export function setPage(data) {
  return { type: SET_PAGE, payload: data };
}

export function setConfirmedAndAuthorized(bool) {
  return { type: UPDATE_CONFIRMED_AND_AUTHORIZE, payload: bool };
}

export function setClinicAuthorized(bool) {
  return { type: UPDATE_CLINIC_AUTHORIZATION, payload: bool };
}

export function setMyClinic(bool) {
  return { type: UPDATE_MY_CLINIC, payload: bool };
}

export function setShareDataWithInsulet(bool) {
  return { type: SHARE_DATA_WITH_INSULET, payload: bool };
}

export function setMinor(isMinor) {
  return { type: SET_MINOR, payload: isMinor };
}

export function setGuardianEmail(email) {
  return { type: SET_GUARDIAN_EMAIL, payload: email };
}

const initialState = {
  myClinic: null,
  authorizedClinic: null,
  confirmedAndAuthorized: null,
  page: null,
  cid: null,
  code: null,
  shareDataWithInsulet: false,
  isMinor: false,
  pgs: { },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET:
      return initialState;
    case UPDATE:
      return {
        ...state,
        ...action.payload.newState,
      };
    case UPDATE_CLINIC_AUTHORIZATION:
      return {
        ...state,
        authorizedClinic: action.payload,
      };
    case UPDATE_MY_CLINIC:
      return {
        ...state,
        myClinic: action.payload,
      };
    case UPDATE_CONFIRMED_AND_AUTHORIZE:
      return {
        ...state,
        confirmedAndAuthorized: action.payload,
      };
    case SHARE_DATA_WITH_INSULET:
      return {
        ...state,
        shareDataWithInsulet: action.payload,
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case SET_MINOR:
      return {
        ...state,
        isMinor: action.payload,
      };
    case SET_GUARDIAN_EMAIL:
      return {
        ...state,
        guardianEmail: action.payload,
      };
    default:
      return state;
  }
}
